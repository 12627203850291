/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { concessionaireHierarchyTypes } from '../constants/types';
import { ERROR_ROW_OBJECT } from '../constants/locationPicker';

//Exported for tests only
export const initialState = {
    dataSource: [],
    loading: false,
    loaded: false,
    error: null,
};

export default function concessionaireHierarchyReducer(state = initialState, action) {
    switch (action.type) {
        case concessionaireHierarchyTypes.START:
            return { dataSource: action.data, loading: true, loaded: false, error: null };
        case concessionaireHierarchyTypes.ADD:
            if (state.error) {
                return state;
            }
            return { ...state, dataSource: state.dataSource.concat(action.data) };
        case concessionaireHierarchyTypes.ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error,
                dataSource: [ERROR_ROW_OBJECT],
            };
        case concessionaireHierarchyTypes.END:
            return { ...state, loading: false, loaded: true };
        default:
            return state;
    }
}

export const concessionaireHierarchySelector = (state) => state.concessionaireHierarchy;
