/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { combineReducers } from 'redux';
import {
    ATTESTATION_STATE,
    FETCH_ATTESTATION_FAILURE,
    FETCH_ATTESTATION_START,
    FETCH_ATTESTATION_SUCCESS,
    POST_ATTESTATION_FAILURE,
    POST_ATTESTATION_SUCCESS,
    POST_ATTESTATION_START,
    FETCH_ATTESTATION_RESET,
} from '../constants/types';

const initialState = { loading: false, loaded: false, error: null, data: {} };
const startReducerFn = (state) => ({
    loading: true,
    loaded: false,
    error: null,
    data: state.data,
});
const successReducerFn = (state, action) => ({
    loading: false,
    loaded: true,
    error: null,
    data: action.payload,
});
const failureReducerFn = (state, action) => ({
    loading: false,
    loaded: true,
    error: action.error,
    data: {},
});
const resetReducerFn = () => ({ loading: false, loaded: true, error: null, data: {} });

const fetchAttestation = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ATTESTATION_START:
            return startReducerFn(state);
        case FETCH_ATTESTATION_SUCCESS:
            return successReducerFn(state, action);
        case FETCH_ATTESTATION_FAILURE:
            return failureReducerFn(state, action);
        case FETCH_ATTESTATION_RESET:
            return resetReducerFn();
        default:
            return state;
    }
};

const postAttestation = (state = initialState, action) => {
    switch (action.type) {
        case POST_ATTESTATION_START:
            return startReducerFn(state);
        case POST_ATTESTATION_SUCCESS:
            return successReducerFn(state, action);
        case POST_ATTESTATION_FAILURE:
            return failureReducerFn(state, action);
        default:
            return state;
    }
};

const attestationState = (state = { location: {}, role: '' }, action) => {
    switch (action.type) {
        case ATTESTATION_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default combineReducers({
    state: attestationState,
    fetch: fetchAttestation,
    post: postAttestation,
});

export const attestStateSelector = (state) => state.attestation.state;
export const attestFetchSelector = (state) => state.attestation.fetch.data;
export const attestFetchLoadingSelector = (state) => state.attestation.fetch.loading;

export const attestPostSelector = (state) => state.attestation.post.data;
export const attestPostErrorSelector = (state) => state.attestation.post.error;
export const attestPostLoadingSelector = (state) => state.attestation.post.loading;
