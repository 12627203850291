/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import * as roles from '../utilities/roles';
import { AllHighLvlLocationsArr } from '../utilities/locations';
import { addNoteForExistingUser } from './notes';
import { accountSaved } from './updateUser';
import { doneLoading, startLoading } from './loading';
import * as globals from '../constants/globals';
import { makeAuthorizedRequest } from './login';
import { errorForUpdateUserStatus } from '../utilities/errorMessages';

export const setTabIndex = (index) => {
    return {
        type: types.SET_SELECTED_TAB_INDEX,
        index,
    };
};

export const requireUserListThrottle = (force) => {
    const throttle = typeof force === 'boolean' ? force : true;
    return {
        type: types.THROTTLE_USER_LIST,
        throttle,
    };
};

export function setUpdateUser(user) {
    return (dispatch) => {
        dispatch(accountSaved(user));
    };
}

export function setInitialValue() {
    return {
        type: types.SET_NOTE_ADDED_FOR_EXISTING_USER,
    };
}

export function updateUserStatusFailed(errorMessage) {
    return {
        type: types.UPDATE_USER_STATUS_FAILED,
        errorMessage,
    };
}

export function updateUserStatusSuccessful() {
    return {
        type: types.UPDATE_USER_STATUS_SUCCESSFUL,
    };
}

export function updateUserStatus(user, note) {
    return async (dispatch) => {
        dispatch(startLoading());

        try {
            if (user.locked === false) {
                dispatch(addNoteForExistingUser(note, 'GENERAL'));
            }

            const lockUrl = `${globals.API_URL}/user/${user.user_id}/${user.locked ? 'unlock' : 'lock'}`;
            const responseLock = await makeAuthorizedRequest(lockUrl, 'PUT', dispatch);
            if (!responseLock.success)
                dispatch(updateUserStatusFailed('failed on user lock call'));
            if (responseLock.error) dispatch(updateUserStatusFailed(responseLock.error));

            dispatch(updateUserStatusSuccessful());
        } catch (error) {
            dispatch(updateUserStatusFailed(errorForUpdateUserStatus()));
        }

        dispatch(doneLoading());
    };
}

export function checkUserListThrottle() {
    return async (dispatch, getState) => {
        const state = getState();
        const user = state.login.user;
        const isPmoOrApmOrCsr = user.roles.some(
            (role) =>
                role.role_type === roles.SUPER_USER ||
                role.role_type === roles.PMO ||
                role.role_type === roles.ATR ||
                role.role_type === roles.CSR1
        );

        const isHighLvlUser = user.roles.some((role) =>
            AllHighLvlLocationsArr.includes(
                role.location_type !== ''
                    ? role.location_type
                    : role.location.location_type
            )
        );
        if (isPmoOrApmOrCsr || isHighLvlUser) {
            dispatch(requireUserListThrottle());
        }
    };
}

export const searchFieldChanged = (field) => ({
    type: types.USER_SEARCH_FIELD_CHANGED,
    field,
});

export const searchValueChanged = (value) => ({
    type: types.USER_SEARCH_VALUE_CHANGED,
    value,
});

export const filterChanged = (value) => ({
    type: types.USER_SEARCH_FILTER_CHANGED,
    value,
});
