/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icons } from 'sarsaparilla';

const propTypes = {
    collapsed: PropTypes.bool,
};

export default class RoleCapabilitiesTable extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.rowData = this.getRowData();
    }

    getRowData() {
        return [
            {
                host: true,
                attendant: true,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Check in/out guests',
            },
            {
                host: true,
                attendant: true,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Submit refund on behalf of customer',
            },
            {
                host: false,
                attendant: true,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Make/change/cancel reservations',
            },
            {
                host: false,
                attendant: true,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Make sales',
            },
            {
                host: false,
                attendant: true,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Make tour reservations',
            },
            {
                host: false,
                attendant: true,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Print tickets/permits',
            },
            {
                host: false,
                attendant: true,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Manage Inventory (camping, tickets, permits)',
            },
            {
                host: false,
                attendant: false,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Manage Description',
            },
            {
                host: false,
                attendant: false,
                facilityOperator: true,
                facilityManager: true,
                capability: `Create/edit ${process.env.SITE_NAME} user accounts`,
            },
            {
                host: false,
                attendant: false,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Approve/deny permits',
            },
            {
                host: false,
                attendant: false,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Send facility-wide alerts',
            },
            {
                host: false,
                attendant: false,
                facilityOperator: true,
                facilityManager: true,
                capability: 'Authorize refund',
            },
            {
                host: false,
                attendant: false,
                facilityOperator: false,
                facilityManager: true,
                capability: 'Override facility business rules',
            },
        ];
    }

    tableRow(row) {
        return (
            <tr key={row.capability}>
                <td>{row.capability}</td>
                <td>{this.columnForRole(row.host)}</td>
                <td>{this.columnForRole(row.attendant)}</td>
                <td>{this.columnForRole(row.facilityOperator)}</td>
                <td>{this.columnForRole(row.facilityManager)}</td>
            </tr>
        );
    }

    columnForRole(hasCapability) {
        if (!hasCapability) {
            return null;
        }

        return (
            <div className="role-capabilities-icon">
                <Icons.IconCheckCircle />
            </div>
        );
    }

    tableBody() {
        return <tbody>{this.rowData.map((row) => this.tableRow(row))}</tbody>;
    }

    render() {
        const css = classNames({
            'role-capabilities-wrapper': true,
            'animate-collapsed': this.props.collapsed,
        });

        return (
            <div className={css}>
                <table className="role-capabilities-table">
                    <caption className="rec-sr-only">About Roles</caption>
                    <thead>
                        <tr>
                            <th>What Users Can Do</th>
                            <th>Host</th>
                            <th>Attendant</th>
                            <th>Facility Operator</th>
                            <th>Facility Manager</th>
                        </tr>
                    </thead>
                    {this.tableBody()}
                </table>
            </div>
        );
    }
}
