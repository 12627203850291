/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNil, forEach } from 'lodash';
import { useFlags } from 'sarsaparilla';

import { domainToTileMap, generateTilePath } from '../utilities/launchTiles';
import { AGENCY, REC_AREA, VENUE_RESERVATIONS } from '../utilities/locations';

export default function useDomainsForSelectedLocation() {
    const flags = useFlags();
    const loginDomains = useSelector(
        (state) => state?.login?.account?.domains_by_location_id || {}
    );
    let domainsForLocationId = useSelector(
        (state) => state.fetchChildLocations.selected_domains_by_location_id
    );
    const userID = useSelector((state) => state.login?.user?.user_id);
    const location = useSelector((state) => state.selectedLocationRole.role?.location);
    const isMultiTenantLocation = process.env.IS_MULTI_TENANT;
    domainsForLocationId = domainsForLocationId || loginDomains;

    const interagencySitepassUpdateFieldSales = flags.interagencySitepassUpdateFieldSales;
    const activitypassUpdateFieldSales = flags.activityPassFieldSales;
    const sitepassUpdateFieldSales = flags.sitePassFieldSales;
    const isRecAreaFieldSalesEnabled = flags.isRecAreaFieldSalesEnabled;
    const isVenueFieldSalesEnabled = !!flags?.isVenueFieldSalesEnabled;

    return useMemo(() => {
        if (domainsForLocationId && location) {
            const key = `${location.location_id}_${location.location_type}`;
            const locationType = location?.location_type;
            let domains = domainsForLocationId[key]
                ? domainsForLocationId[key].domains
                : [];
            if (locationType === 'Timed Entry') {
                domains = domains.filter((domain) => domain !== 'DOMAIN_PERMIT_CONTENT');
            }
            if (isNil(domains)) {
                return [];
            }
            //This should really be migrated to the backend when in prod.
            if (location.has_park_pass && interagencySitepassUpdateFieldSales) {
                if (!sitepassUpdateFieldSales) {
                    domains = domains.filter((item) => item !== 'FIELD_SALES');
                }
            }
            if (location.location_type === 'Activity Pass') {
                if (!activitypassUpdateFieldSales) {
                    domains = domains.filter((item) => item !== 'FIELD_SALES');
                }
            }

            //End backend migration request

            //Filter domains by flags here
            if (
                location.location_type === VENUE_RESERVATIONS &&
                !isVenueFieldSalesEnabled
            ) {
                domains = domains.filter((domain) => domain !== 'FIELD_SALES');
            }
            if (location.location_type === REC_AREA && !isRecAreaFieldSalesEnabled) {
                domains = domains.filter(
                    (domain) => domain !== 'FIELD_SALES' && domain !== 'SHIFT_HISTORY'
                );
            }
            //Remember to add the flag to the dependency array bellow.

            //Filter financial field when in multi-tenant environment for non-agency locations
            if (isMultiTenantLocation && location.location_type !== AGENCY) {
                domains = domains.filter(
                    (domain) => domain !== 'FINANCIAL_TRANSACTION_MANAGEMENT'
                );
            }

            if (process.env.POI_VERSION === 'v1') {
                domains = domains.filter((domain) => domain !== 'POI_MANAGEMENT');
            }

            const mappedDomains = [];
            forEach(domains, (domain) => {
                const tile = domainToTileMap[domain];
                if (!isNil(tile)) {
                    const path = generateTilePath(tile, location);

                    if (!isNil(path)) {
                        mappedDomains.push({ domain, tile, path });
                    }
                }
            });
            return mappedDomains;
        }
        return [];
    }, [
        domainsForLocationId,
        location,
        interagencySitepassUpdateFieldSales,
        isRecAreaFieldSalesEnabled,
        userID,
        isVenueFieldSalesEnabled,
    ]);
}
