/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sarsaparilla';

const propTypes = {
    locationNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    onMoreClick: PropTypes.func.isRequired,
};

export default function LocationDisplay(props) {
    const locs = props.locationNames;
    const maxLocations = 3;
    const maxLocationsHover = 5;
    const hoverMoreOffset = maxLocations + maxLocationsHover;

    const showHover = locs.length > maxLocations;
    const showMore = locs.length > hoverMoreOffset;

    const locationsInline = showHover ? locs.slice(0, maxLocations) : locs;
    const locationsHover = showMore
        ? locs.slice(maxLocations, hoverMoreOffset)
        : locs.slice(maxLocations);

    return (
        <div className="user-table-locations-column">
            <div className="user-table-location-column-main-display">
                <span className="user-table-location-name">
                    {locationsInline.join(', ')}
                </span>
                {locs.length > maxLocations ? (
                    <span
                        className="loc-count"
                        data-count={`+${locs.length - maxLocations}`}
                    />
                ) : null}
            </div>
            {locs.length > maxLocations && (
                <div className="hover-locations-view">
                    <ul>
                        {locationsHover.map((location, index) => {
                            return <li key={`hover-location-${index}`}>{location}</li>;
                        })}
                    </ul>
                    {showMore ? (
                        <Button
                            appearance="link"
                            className="hover-locations-more button-as-anchor"
                            onClick={props.onMoreClick}
                        >
                            More...
                        </Button>
                    ) : null}
                </div>
            )}
        </div>
    );
}

LocationDisplay.propTypes = propTypes;
