/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ModalActions, StyledModal } from 'sarsaparilla';

export const defaultDeleteNote = `User no longer works here.`;

const propTypes = {
    isOpen: PropTypes.bool,
    showSystemUserWarn: PropTypes.bool,
    confirm: PropTypes.func,
    cancel: PropTypes.func,
    users: PropTypes.array,
};

export default class DeleteUserWarning extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.state = {
            usingCustomNote: false,
            customNote: '',
        };
    }

    onConfirm() {
        let note = defaultDeleteNote;

        if (this.state.usingCustomNote) {
            note = this.state.customNote;
        }

        this.props.confirm(note);
    }

    modalBody() {
        let userSuffix = '';
        let recordSuffix = 'this user.';

        if (this.props.users.length > 1) {
            userSuffix = 's';
            recordSuffix = 'these users.';
        }

        return (
            <div className="delete-user-warning-modal-body">
                <div>{`Would you like to delete the following ${this.props.users.length} user${userSuffix}?`}</div>
                <ul>{this.userEmails()}</ul>
                {this.props.showSystemUserWarn && (
                    <div>
                        <strong>Note:</strong> One or more selected users have been
                        removed from this list because they are system users and can not
                        be deleted.
                    </div>
                )}
                <div>
                    <div className="delete-user-note-instructions">{`Please record why you are deleting ${recordSuffix}`}</div>
                    <div className="rec-form-check-wrap">
                        <label htmlFor="default-note-radio" className="rec-input-radio">
                            <input
                                id="default-note-radio"
                                className="rec-input-hide"
                                type="radio"
                                checked={!this.state.usingCustomNote}
                                onChange={this.radioOnChange.bind(this)}
                            />
                            <span className="rec-input-radio" />
                            {defaultDeleteNote}
                        </label>
                    </div>
                    <div className="rec-form-check-wrap">
                        <label htmlFor="custom-note-radio" className="rec-input-radio">
                            <input
                                id="custom-note-radio"
                                className="rec-input-hide"
                                type="radio"
                                checked={this.state.usingCustomNote}
                                onChange={this.radioOnChange.bind(this)}
                            />
                            <span className="rec-input-radio" />
                            Custom answer (input below)
                        </label>
                    </div>
                    <textarea
                        ref={(r) => {
                            this.notesInput = r;
                        }}
                        onChange={this.textAreaOnChange.bind(this)}
                        value={this.state.customNote}
                        disabled={!this.state.usingCustomNote}
                    />
                </div>
            </div>
        );
    }

    radioOnChange() {
        const updatedState = { usingCustomNote: !this.state.usingCustomNote };

        this.setState(updatedState, () => {
            if (this.notesInput && updatedState.usingCustomNote) {
                this.notesInput.focus();
            }
        });
    }

    textAreaOnChange(e) {
        this.setState({
            customNote: e.target.value,
        });
    }

    userEmails() {
        return this.props.users.map((user) => {
            if (!user) {
                return null;
            }

            return <li key={user.user_id}>{user.email}</li>;
        });
    }

    render() {
        return (
            <StyledModal
                size="md"
                isOpen={this.props.isOpen}
                onRequestClose={this.props.cancel}
                shouldShowCloseButton={false}
                heading="Delete User"
                shouldActionsStickInMobile
            >
                {this.modalBody()}
                <ModalActions>
                    <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                        <Button appearance="tertiary" onClick={this.props.cancel}>
                            Cancel
                        </Button>
                        <Button
                            disabled={this.props.users.length < 1}
                            onClick={this.onConfirm.bind(this)}
                        >
                            Confirm & Delete
                        </Button>
                    </ButtonGroup>
                </ModalActions>
            </StyledModal>
        );
    }
}
