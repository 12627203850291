/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { startCase, isEqual } from 'lodash';
import { Icons, TextField, Alert } from 'sarsaparilla';
import { isDescendant } from '../utilities/tools';
import { errorForSubmittingPOC } from '../utilities/errorMessages';

const propTypes = {
    forEdit: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    forError: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    editing: PropTypes.bool,
    doClear: PropTypes.func,
    doUpdate: PropTypes.func,
};

export class EditableBlock extends React.Component {
    static propTypes = propTypes;

    static defaultProps = {
        editing: false,
        doClear: () => {},
        doUpdate: () => {},
    };

    constructor(props) {
        super(props);
        this._inputRefs = [];
        this.state = {
            editing: this.props.forError ? this.isErrored() : this.props.editing,
            fields: this.props.forEdit || [],
        };
    }

    componentDidUpdate() {
        if (this.state.editing) {
            document
                .getElementsByTagName('body')[0]
                .addEventListener('mousedown', this.revertComponent.bind(this), {
                    once: true,
                });
        } else if (!isEqual(this.state.fields, this.props.forEdit)) {
            this.setState({
                fields: this.props.forEdit,
            });
        }
    }

    isArray(fields) {
        const check = fields !== undefined ? fields : this.state.fields;
        return Array.isArray(check);
    }

    isErrored(forError) {
        const check = forError !== undefined ? forError : this.props.forError;
        return check && (!this.isArray() || check.length);
    }

    revertComponent(e, force) {
        const revert =
            force || (this._self !== e.target && !isDescendant(this._self, e.target));

        if (revert)
            this._inputRefs.forEach((ref) => {
                if (ref) ref.removeAttribute('style');
            });

        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    editing: !revert,
                    fields: revert ? this.props.forEdit : prevState.fields,
                };
            },
            () => {
                if (revert) this.props.doClear();
            }
        );
    }

    toggleEditing(force) {
        const editing = typeof force === 'boolean' ? force : !this.state.editing;

        this.setState(
            (prevState) => {
                return { ...prevState, editing };
            },
            () => {
                if (this.isArray() && this.state.fields.length === 0 && editing) {
                    this.addArrayItem();
                }
            }
        );
    }

    cancelEditing(e) {
        e.stopPropagation();
        this.revertComponent(e, true);
    }

    submitEdits(e) {
        e.stopPropagation();
        this.revertComponent(e, true);
        this.props.doUpdate(this.state.fields);
    }

    addArrayItem() {
        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    fields: [...prevState.fields, ''],
                };
            },
            () => {
                const toFocus = this._lastInputRef;

                if (toFocus) {
                    toFocus.focus();
                }
            }
        );
    }

    renderKeyValuePair(key) {
        if (this.isArray()) return null;

        return (
            <div
                className="ia-editable-row tr"
                key={`kvp-${key.toLowerCase().replace(/\s/, '')}`}
            >
                <div className="ia-editable-key">{startCase(key.replace('_', ' '))}</div>
                <div className="ia-editable-value">
                    <TextField
                        id={key}
                        label={key}
                        isLabelVisible={false}
                        className="ia-editable-input ia-suppressable"
                        value={this.state.fields[key]}
                        onChange={(e) => {
                            this.setState((prevState) => {
                                return {
                                    ...prevState,
                                    fields: {
                                        ...prevState.fields,
                                        [key]: e.target.value,
                                    },
                                };
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    renderContentRow(index) {
        if (!this.isArray() || this.state.fields[index] === null) return null;

        const errored =
            Array.isArray(this.props.forError) && this.props.forError.indexOf(index) > -1;
        const classes = classNames({
            'ia-editable-content': true,
            'rec-notification-error': errored,
        });

        return (
            <li className="ia-editable-item" key={`content-row-${index}`}>
                <div className={classes}>
                    <textarea
                        className="ia-editable-input ia-suppressable"
                        ref={(ref) => {
                            this._inputRefs[index] = ref;
                        }}
                        value={this.state.fields[index]}
                        onFocus={() => this.toggleEditing(true)}
                        onChange={(e) =>
                            this.setState((prevState) => {
                                return {
                                    ...prevState,
                                    fields: [
                                        ...prevState.fields.slice(0, index),
                                        e.target.value,
                                        ...prevState.fields.slice(
                                            index + 1,
                                            prevState.fields.length
                                        ),
                                    ],
                                };
                            })
                        }
                    />
                </div>
                <div className="ia-editable-control">
                    <button
                        type="button"
                        className="ia-editable-button-delete"
                        onClick={() => {
                            const origFields = [...this.state.fields.slice(0, index)];
                            if (index < this.props.forEdit.length) origFields.push(null);

                            this.setState(
                                (prevState) => {
                                    return {
                                        ...prevState,
                                        fields: origFields.concat([
                                            ...prevState.fields.slice(
                                                index + 1,
                                                prevState.fields.length
                                            ),
                                        ]),
                                    };
                                },
                                () => {
                                    if (
                                        this.state.fields.filter((f) => f !== null)
                                            .length === 0
                                    )
                                        this.addArrayItem();
                                }
                            );
                        }}
                    >
                        <Icons.IconCloseCircleOutline />
                    </button>
                </div>
            </li>
        );
    }

    renderMainContent() {
        if (this.isArray()) {
            const failedDeletions =
                this.isErrored() &&
                this.props.forError.filter((index) => this.state.fields[index] === null)
                    .length;
            return (
                <ul className="ia-editable-main">
                    {this.state.fields.length === 0 && !this.state.editing && (
                        <li className="ia-editable-placeholder">No records.</li>
                    )}
                    {failedDeletions > 0 && (
                        <li className="rec-notification-error">
                            {`Delete failed on ${failedDeletions} record${failedDeletions === 1 ? '.' : 's.'}`}
                        </li>
                    )}
                    {this.state.fields.map((val, i) => this.renderContentRow(i))}
                </ul>
            );
        }

        if (!this.isArray())
            return (
                <div className="ia-editable-main">
                    {this.props.forError && (
                        <Alert shouldFocusOnMount type="error">
                            {errorForSubmittingPOC()}
                        </Alert>
                    )}
                    {Object.keys(this.state.fields).map(
                        this.renderKeyValuePair.bind(this)
                    )}
                </div>
            );

        return null;
    }

    render() {
        if (!this.props.forEdit) return null;

        const classes = classNames({
            'rec-edit-wrap': true,
            'ia-editable': true,
            'ia-editable-array': this.isArray(),
            'ia-editable-object': !this.isArray(),
            'ia-editable-isSuppressed': !this.state.editing,
            'ia-editable-isEditing': this.state.editing,
            'ia-editable-isErrored':
                this.props.forError &&
                (!Array.isArray(this.props.forError) || this.props.forError.length),
        });

        return (
            <div
                className={classes}
                role="button"
                tabIndex="0"
                ref={(ref) => {
                    this._self = ref;
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    this.toggleEditing(true);
                }}
                onKeyDown={(event) => {
                    if (
                        event.code === 'Enter' ||
                        event.which === '32' ||
                        event.which === '13'
                    ) {
                        event.preventDefault();
                        this.toggleEditing(true);
                    }
                }}
            >
                <div className="ia-editable-controls ia-editable-out-top">
                    <button
                        type="button"
                        className="ia-editable-button-edit"
                        aria-label="Click to edit content"
                        onClick={this.toggleEditing.bind(this)}
                    >
                        <Icons.IconEdit size="sm" />
                    </button>
                </div>
                {this.renderMainContent()}
                {this.isArray() && (
                    <div className="ia-editable-foot">
                        <button
                            type="button"
                            className="ia-editable-button-add rec-button-tertiary"
                            onClick={this.addArrayItem.bind(this)}
                        >
                            <Icons.IconAddCircleOutline />
                            Add More
                        </button>
                    </div>
                )}
                <div>
                    <button
                        type="button"
                        className="rec-apply-updates"
                        aria-label="Click to confirm edit"
                        onClick={this.submitEdits.bind(this)}
                    >
                        <Icons.IconCheck />
                    </button>
                    <button
                        type="button"
                        className="rec-cancel-updates"
                        aria-label="Click to cancel edit"
                        onClick={this.cancelEditing.bind(this)}
                    >
                        <Icons.IconClose />
                    </button>
                </div>
            </div>
        );
    }
}
