/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    message: '',
    display: false,
};

const loading = (state = initialState, action) => {
    switch (action.type) {
        case types.START_LOADING:
            return { ...state, message: action.message, display: true };

        case types.DONE_LOADING:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default loading;
