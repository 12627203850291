/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Text } from 'sarsaparilla';

const propTypes = {
    onDownload: PropTypes.func,
    onClose: PropTypes.func,
};

type MfaEnrollmentBackupCodesProps = {
    onDownload: () => void;
    onClose: () => void;
};

function MfaEnrollmentBackupCodes({
    onDownload,
    onClose,
}: MfaEnrollmentBackupCodesProps) {
    return (
        <>
            <p className="mt-3 ml-8 mb-4 mr-8">
                <Heading headingLevel={4} appearance="h4">
                    Download Backup Codes
                </Heading>
            </p>
            <p className="ml-3 mr-3 mb-3">
                <Text size="sm">
                    If you lose access to your agency-mandated multi-factor authenticator
                    application, you can still gain access to your account with
                    pre-generated backup codes. Save these somewhere safe to reduce the
                    risk of losing access to your account. <b>Note</b>, your backup codes
                    will be downloaded as a .txt file.
                </Text>
            </p>
            <p className="mb-3">
                <Button appearance="tertiary" onClick={onDownload}>
                    Download Backup Codes
                </Button>
            </p>
            <p className="mb-4">
                <Button onClick={onClose}>Continue</Button>
                <Text size="sm" className="mt-2">
                    You may continue to your account sign in by clicking the above button
                    if you do not wish to download your codes at this time.
                </Text>
            </p>
        </>
    );
}

MfaEnrollmentBackupCodes.propTypes = propTypes;

export default MfaEnrollmentBackupCodes;
