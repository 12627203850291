/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
    current: PropTypes.string,
    showUserDetails: PropTypes.bool,
    type: PropTypes.string,
};

export default class EditUserProgress extends React.Component {
    static propTypes = propTypes;

    static defaultProps = { current: 1, showUserDetails: false };

    progressNode(position, label) {
        const css = classnames({
            'rec-progress-item': true,
            'rec-current': this.props.current === position.toString(),
            'rec-complete': this.props.current > position.toString(),
        });
        return (
            <li>
                <div className={css}>
                    <div className="rec-bubble" />
                    <div className="h6">{label}</div>
                </div>
            </li>
        );
    }

    render() {
        const doConcessionaire = this.props.type === 'concessionaire';
        const classes = classnames({
            'progress-bar-wrapper': true,
            'user-details-display': this.props.showUserDetails,
        });
        return (
            <div className={classes}>
                <ol className="rec-progress-bar">
                    {this.props.showUserDetails
                        ? this.progressNode(0, 'User Details')
                        : ''}
                    {this.progressNode(
                        1,
                        doConcessionaire ? 'Concessionaires' : 'Locations'
                    )}
                    {!doConcessionaire && this.progressNode(2, 'Roles')}
                </ol>
            </div>
        );
    }
}
