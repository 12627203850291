/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { HelmetWrapperInternal } from 'sarsaparilla';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import PasswordForm from '../components/PasswordForm';
import { errorForConfirmEmail } from '../utilities/errorMessages';
import { logOut, resetSession } from '../actions/login';
import { confirmEmail } from '../actions/confirmEmail';
import { resetPassword } from '../actions/resetPassword';

type ConfirmEmailActions = {
    confirmEmailAction: (token: string | undefined, history: any) => void;
    resetAction: (
        token: string,
        password: string,
        confirmPassword: string,
        history: any
    ) => void;
    logOutAction: () => void;
    resetSessionAction: () => void;
};

type User = {
    must_set_password: boolean;
};

type ConfirmEmailPageState = {
    confirmEmail: {
        token: string;
        user: User;
        error: object;
    };
};

type ConfirmEmailPageProps = ConfirmEmailActions & ConfirmEmailPageState;

function ConfirmEmailPage({
    confirmEmail: confirmEmailProp,
    confirmEmailAction,
    resetAction,
    logOutAction,
    resetSessionAction,
}: ConfirmEmailPageProps) {
    const history = useHistory();

    useEffect(() => {
        const queryParams = qs.parse(history.location.search);
        const token = queryParams.token;
        resetSessionAction();
        confirmEmailAction(token, history);
    }, [confirmEmailAction, resetSessionAction, history]);

    const doLogOut = () => {
        logOutAction();
        history.push('/internal/account/login');
    };

    const error = () => {
        if (confirmEmailProp && confirmEmailProp.error) {
            return (
                <div className="rec-alert rec-alert-danger" role="alert">
                    <div className="rec-alert-header">
                        <h4>
                            <i
                                className="fa fa-exclamation-circle m_R-2x"
                                aria-hidden="true"
                            />
                            {errorForConfirmEmail()}
                        </h4>
                    </div>
                    <div className="rec-alert-body">
                        <p>
                            <button type="button" id="back-to-login" onClick={doLogOut}>
                                Back to Login Page.
                            </button>
                        </p>
                    </div>
                </div>
            );
        }
        return null;
    };

    const user = confirmEmailProp ? confirmEmailProp.user : null;

    if (user != null) {
        if (user.must_set_password) {
            return (
                <PasswordForm
                    token={confirmEmailProp.token}
                    resetPassword={confirmEmailProp}
                    reset={resetAction}
                    logOut={logOutAction}
                />
            );
        }
        return null;
    }

    const errorHtml = error();
    let display = <div className="confirm-email-message">Confirming email...</div>;
    if (errorHtml != null) {
        display = errorHtml;
    }

    return (
        <div className="logged-out-container">
            <HelmetWrapperInternal title="Confirm Email" />
            <div className="confirm-email-component">
                <div className="usa-grid usa-grid-centered">
                    <div className="usa-width-five-twelfths">{display}</div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: ConfirmEmailPageState) => {
    return {
        confirmEmail: state.confirmEmail,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            logOutAction: logOut,
            confirmEmailAction: confirmEmail,
            resetAction: resetPassword,
            resetSessionAction: resetSession,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailPage);
