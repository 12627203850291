/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import { makeAuthorizedRequest } from './login';
import { doneLoading, startLoading } from './loading';
import * as globals from '../constants/globals';

type UsersSearchResponse = {
    search_for_users_request: {
        location_id: string;
        location_type: string;
        location_name: string;
        first_name: string;
        last_name: string;
        email: string;
        user_id: string;
    };
    users: types.User[];
};

type FetchUsersAction = {
    type: string;
    payload?: UsersSearchResponse;
    loaded?: boolean;
    error?: Error | null;
};

export const queryFromSearchParameters = (paramArray: any) => {
    let query: string = '';

    if (typeof paramArray === 'object' && paramArray.length) {
        for (const param of paramArray) {
            const keys: string[] = Object.keys(param);

            for (const key of keys) {
                const value = param[key];
                const encodedValue: string = encodeURIComponent(value);

                query += `${key}=${encodedValue}&`;
            }
        }
    }

    return query.slice(0, -1);
};

function fetchUsersSuccess(payload: UsersSearchResponse): FetchUsersAction {
    return {
        type: types.FETCH_USERS_SUCCESS,
        payload,
        loaded: true,
        error: null,
    };
}

function fetchUsersFailure(error: Error): FetchUsersAction {
    return {
        type: types.FETCH_USERS_FAILED,
        error,
    };
}

const deDupeUserList = (users: types.User[]): types.User[] => {
    const userSet: Map<string, types.User> = new Map();
    users.forEach((u: types.User): void => {
        if (!userSet.get(u.user_id)) {
            userSet.set(u.user_id, u);
        }
    });
    return Array.from(userSet.values());
};

function sortListByLocation(users: types.User[], locationID: string): types.User[] {
    for (const user of users) {
        let tempRoleList: types.Role[] = [];
        if (user.roles.length > 1) {
            user.roles.forEach((role: types.Role) => {
                if (role.location_id === locationID) {
                    tempRoleList = [role, ...tempRoleList];
                } else {
                    tempRoleList.push(role);
                }
            });

            user.roles = tempRoleList;
        }
    }

    return users;
}

export function fetchUsers(paramArray: any, spin: boolean) {
    const doLoading: boolean = spin !== false;
    return async (dispatch: Function, getState: Function): Promise<void> => {
        if (doLoading) {
            dispatch(startLoading('Loading list of users ...'));
        }

        try {
            const state = getState();
            const user = state.login.user;

            let encodedUsersQuery: string = queryFromSearchParameters(paramArray);
            encodedUsersQuery = encodedUsersQuery.length
                ? `/${user.user_id}/search-for-users?${encodedUsersQuery}`
                : '';
            const response = await makeAuthorizedRequest(
                `${globals.API_URL}/user${encodedUsersQuery}`,
                'GET',
                dispatch
            );

            response.users = deDupeUserList(response.users);

            if (
                typeof paramArray === 'object' &&
                paramArray[0].hasOwnProperty('location_id')
            ) {
                sortListByLocation(response.users, paramArray[0].location_id);
            }

            dispatch(fetchUsersSuccess(response));
        } catch (error: any) {
            dispatch(fetchUsersFailure(error));
        }

        if (doLoading) {
            dispatch(doneLoading());
        }
    };
}

export function fetchUsersForPOCSelection(paramArray: any) {
    return async (dispatch: Function, getState: Function): Promise<void> => {
        try {
            const state = getState();
            const user = state.login.user;
            const requests = [];

            let encodedUsersQuery: string = queryFromSearchParameters(paramArray);
            encodedUsersQuery = encodedUsersQuery.length
                ? `/${user.user_id}/search-for-users?${encodedUsersQuery}`
                : '';
            requests.push(
                makeAuthorizedRequest(
                    `${globals.API_URL}/user${encodedUsersQuery}`,
                    'GET',
                    dispatch
                )
            );

            let encodedRecAreaUsersQuery: string = '';
            if (state.facilityInfo && state.facilityInfo.location) {
                // this is used by the facility info page, to fetch list of users at the rec area
                const path = state.facilityInfo.location.location_path;
                const recArea = path.find(
                    (l: types.Location): boolean => l.location_type === 'Rec Area'
                );
                if (recArea) {
                    encodedRecAreaUsersQuery = queryFromSearchParameters([recArea]);
                }

                if (encodedRecAreaUsersQuery.length) {
                    encodedRecAreaUsersQuery = `/${user.user_id}/search-for-users?${encodedRecAreaUsersQuery}`;
                    requests.push(
                        makeAuthorizedRequest(
                            `${globals.API_URL}/user${encodedRecAreaUsersQuery}`,
                            'GET',
                            dispatch
                        )
                    );
                }
            }

            const responses = await Promise.all(requests);

            if (responses.length === 2) {
                responses[0].users = responses[0].users.concat(responses[1].users);
            }
            responses[0].users = deDupeUserList(responses[0].users);

            if (
                typeof paramArray === 'object' &&
                paramArray[0].hasOwnProperty('location_id')
            ) {
                sortListByLocation(responses[0].users, paramArray[0].location_id);
            }

            dispatch(fetchUsersSuccess(responses[0]));
        } catch (error: any) {
            dispatch(fetchUsersFailure(error));
        }
    };
}

export function fetchUsersClear(): FetchUsersAction {
    return {
        type: types.FETCH_USERS_CLEAR,
    };
}
