/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Checkbox,
    Icons,
    Select,
    Button,
    ButtonGroup,
    Heading,
    Alert,
} from 'sarsaparilla';
import { memoize } from 'lodash';

import {
    doesUserHaveRoleType,
    highestRoleAtLocation,
    SUPER_USER,
    PMO,
    BAH_ADMIN,
    ATR,
    FACILITY_MANAGER,
} from '../utilities/roles';

import SiteWrapper from './SiteWrapper';
import UserManagementHeader from './UserManagementHeader';

import EditUserProgress from '../components/EditUserProgress';
import RoleCapabilitiesTable from '../components/RoleCapabilitiesTable';
import {
    cancelAssigningLocations as cancelAssigningLocationsAction,
    createUser as createUserAction,
    roleAssigned as roleAssignedAction,
    updateRoles as updateRolesAction,
} from '../actions/updateUser';
import {
    computeLocationStateCode,
    displayStringForLocationType,
    getLocationDisplayName,
    isLocTopLevelForUser,
    locationIsUnder,
} from '../utilities/locations';
import { errorForUpdateUser } from '../utilities/errorMessages';
import SimpleTable from '../components/tableUtil/SimpleTable';

const propTypes = {
    assignedLocations: PropTypes.array,
    roleAssignmentOptions: PropTypes.object,
    roleAssigned: PropTypes.func,
    creating: PropTypes.bool,
    editing: PropTypes.bool,
    createUser: PropTypes.func,
    updateRoles: PropTypes.func,
    cancelAssigningLocations: PropTypes.func,
    error: PropTypes.object,
    accountToSave: PropTypes.object,
    loggedInUser: PropTypes.object,
};

export function EditRoles({
    assignedLocations,
    roleAssignmentOptions,
    assignedRoleForLocationId,
    accountToSave,
    loggedInUser,
    roleAssigned,
    creating,
    editing,
    error,
    createUser,
    updateRoles,
    cancelAssigningLocations,
}) {
    const [showRoleCapabilities, setShowRoleCapabilities] = React.useState(false);

    const roleSelectedForLocation = React.useCallback(
        (roleOption, canAttestToPeers, location) => {
            const role = {
                role_type: roleOption,
                can_attest_to_peers: roleOption === FACILITY_MANAGER && canAttestToPeers,
                location,
            };

            roleAssigned(role);
        },
        [roleAssigned]
    );

    const includeAttestToPeers = React.useMemo(() => {
        return [SUPER_USER, PMO, BAH_ADMIN, ATR, FACILITY_MANAGER]
            .map((role) => doesUserHaveRoleType(loggedInUser, role))
            .reduce((prev, curr) => prev || curr, false);
    });

    const highestRoleForTargetLocation = React.useMemo(
        () =>
            memoize(
                (loc) => {
                    return highestRoleAtLocation(loggedInUser.roles, loc).role_type;
                },
                (loc) => loc.location_id
            ),
        [loggedInUser]
    );

    const canModifyAttestToPeers = (location) => {
        const isAPMUserOrAbove = [SUPER_USER, PMO, BAH_ADMIN, ATR].includes(
            highestRoleForTargetLocation(location)
        );
        if (isAPMUserOrAbove) return true;
        const highestLoggedInRoleAtLocation = highestRoleAtLocation(
            loggedInUser.roles,
            location
        );
        const isAnFMUser = highestLoggedInRoleAtLocation.role_type === FACILITY_MANAGER;
        const canUserAttestToPeersAtLocation =
            highestLoggedInRoleAtLocation.can_attest_to_peers;
        const isAChildLocation = locationIsUnder(
            location,
            highestLoggedInRoleAtLocation.location
        );
        return isAChildLocation && canUserAttestToPeersAtLocation && isAnFMUser;
    };

    const getOriginalRole = (loc) => {
        const roles = accountToSave.roles?.filter((role) => role.location_id === loc);
        if (roles?.length !== 1) {
            return null;
        }

        return roles[0];
    };

    const columns = React.useMemo(() => {
        const cols = [
            {
                Header: 'Roles',
                sortable: false,
                id: 'roles',
                Cell: ({ row }) => {
                    const options = roleAssignmentOptions[row.original.location_id];
                    if (!options) {
                        return null;
                    }

                    const topLevel =
                        accountToSave &&
                        loggedInUser.user_id === accountToSave.user_id &&
                        isLocTopLevelForUser(accountToSave?.roles, row.original);

                    let value = '';

                    if (options.initiallySelectedOption) {
                        value = options.initiallySelectedOption.value;
                    }

                    const id = `edit-role-selector-${row.original.location_id}`;

                    return (
                        <div
                            className={
                                topLevel ? 'ia-role-select ia-disabled' : 'ia-role-select'
                            }
                        >
                            <Select
                                id={id}
                                label="Select role"
                                isLabelVisible={false}
                                disabled={topLevel}
                                tabIndex={0}
                                options={options.dropDownOptions}
                                onChange={(option) =>
                                    roleSelectedForLocation(
                                        option.target.value,
                                        row.original.can_attest_to_peers,
                                        row.original
                                    )
                                }
                                placeholder="Select Role..."
                                value={value}
                            />
                        </div>
                    );
                },
                minWidth: 150,
            },
            {
                Header: 'Location',
                id: 'location_name',
                accessor: (row) => {
                    let name = getLocationDisplayName(row);
                    if (row.hierarchyChildren)
                        name += ` (${row.hierarchyChildren.length} locations under)`;
                    return name;
                },
                minWidth: 150,
            },
            {
                Header: 'Level',
                id: 'location_type',
                accessor: (row) => displayStringForLocationType(row.location_type),
                minWidth: 150,
            },
            {
                Header: 'State',
                id: 'state_code',
                accessor: computeLocationStateCode,
                minWidth: 150,
            },
        ];

        if (includeAttestToPeers) {
            cols.push({
                Header: 'Can Attest to Peers',
                id: 'can-attest-to-peers',
                Cell: ({ row }) => {
                    const selectedRole =
                        roleAssignmentOptions[row.original.location_id]
                            ?.initiallySelectedOption?.value;
                    const canAttestToPeers =
                        assignedRoleForLocationId[row.original.location_id]
                            ?.can_attest_to_peers ??
                        getOriginalRole(row.original.location_id)?.can_attest_to_peers;
                    return (
                        <Checkbox
                            id={`${row.original.location_id}-can-attest-to-peers`}
                            label={`Can the role at ${getLocationDisplayName(row)} attest to peers`}
                            isLabelVisible={false}
                            isChecked={canAttestToPeers}
                            isDisabled={
                                !canModifyAttestToPeers(row.original) ||
                                selectedRole !== FACILITY_MANAGER
                            }
                            onChange={(e) => {
                                roleSelectedForLocation(
                                    selectedRole,
                                    e.target.checked,
                                    row.original
                                );
                            }}
                        />
                    );
                },
            });
        }

        return cols;
    }, [
        roleAssignmentOptions,
        assignedRoleForLocationId,
        accountToSave,
        loggedInUser,
        roleSelectedForLocation,
    ]);

    const save = () => {
        if (creating) {
            createUser();
        } else {
            updateRoles();
        }
    };

    const cancel = () => {
        cancelAssigningLocations();
    };

    return (
        <SiteWrapper>
            <div>
                <div className="page-title">
                    <UserManagementHeader editing={editing} />
                </div>
                <div id="UserManagement" className="page-content wrapper">
                    <div className="edit-user-wrapper">
                        <div className="progress-ribbon-wrapper">
                            <EditUserProgress showUserDetails={creating} current="2" />
                            <div className="user-management-button-header">
                                <ButtonGroup>
                                    <Button appearance="tertiary" onClick={cancel}>
                                        Previous
                                    </Button>
                                    <Button appearance="primary" onClick={save}>
                                        Submit
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                        {!!error && (
                            <Alert shouldFocusOnMount type="error">
                                {errorForUpdateUser(error)}
                            </Alert>
                        )}

                        <div className="role-capabilities">
                            <Heading appearance="h5" headingLevel={2} className="mb-2">
                                Select Role
                            </Heading>
                            <div className="ia-create-user-note h5">
                                Select a role at each location for
                                <b>
                                    {accountToSave?.first_name || 'NA'}{' '}
                                    {accountToSave?.last_name || 'NA'}
                                </b>
                                :
                            </div>
                            <Button
                                className="role-capabilities-toggle"
                                appearance="tertiary"
                                iconBeforeElement={<Icons.IconHelp />}
                                onClick={() =>
                                    setShowRoleCapabilities(!showRoleCapabilities)
                                }
                            >
                                Information About Roles
                            </Button>
                            <RoleCapabilitiesTable collapsed={!showRoleCapabilities} />
                        </div>

                        <SimpleTable
                            noDataText={'No Assigned Locations'}
                            defaultSortId={'location_name'}
                            data={assignedLocations}
                            columns={columns}
                        />
                    </div>
                </div>
            </div>
        </SiteWrapper>
    );
}

EditRoles.propTypes = propTypes;

export const mapStateToProps = (state) => {
    return {
        editing: state.userManagement.selectedTabIndex === 2,
        loggedInUser: state.login.user,
        accountToSave: state.updateUser.accountToSave,
        assignedLocations: state.updateUser.assignedLocations,
        creating: state.updateUser.creating,
        error: state.updateUser.error,
        roleAssignmentOptions: state.updateUser.roleAssignmentOptions,
        assignedRoleForLocationId: state.updateUser.assignedRoleForLocationId,
    };
};

export const mapDispatchToProps = (dispatch, ownProps) =>
    bindActionCreators(
        {
            roleAssigned: roleAssignedAction,
            createUser: () => createUserAction(ownProps.history),
            cancelAssigningLocations: () =>
                cancelAssigningLocationsAction(ownProps.history),
            updateRoles: () => updateRolesAction(ownProps.history),
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EditRoles);
