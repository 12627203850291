/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import './HubLoginTermsOfService.scss';

import { Heading } from 'sarsaparilla';

export function HubLoginTermsOfService() {
    return (
        <div className="r1s-terms-of-service">
            <Heading headingLevel={2} appearance="h6">
                Upon login you agree to the following information:
            </Heading>
            <ul>
                <li>
                    You are accessing a U.S. Government information system, which includes
                    (1) this computer, (2) this computer network, (3) all computers
                    connected to this network, and (4) all devices and storage media
                    attached to this network or to a computer on this network. This
                    information system is provided for U.S. Government-authorized use
                    only.
                </li>
                <li>
                    Unauthorized or improper use of this system may result in disciplinary
                    action, as well as civil and criminal penalties.
                </li>
                <li>
                    By using this information system, you understand and consent to the
                    following:
                    <ul>
                        <li>
                            You have no reasonable expectation of privacy regarding any
                            communications or data transiting or stored on this
                            information system. At any time, the government may, for any
                            lawful government purpose, monitor, intercept, search and
                            seize any communication or data transiting or stored on this
                            information system.
                        </li>
                        <li>
                            Any communications or data transiting or stored on this
                            information system may be disclosed or used for any lawful
                            government purpose.
                        </li>
                        <li>
                            Your consent is final and irrevocable. You may not rely on any
                            statements or informal policies purporting to provide you with
                            any expectation of privacy regarding communications on this
                            system, whether oral or written, by your supervisor or any
                            other official, except USDA’s Chief Information Officer.
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}
