/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ReadMoreBlock, DisplayHtmlContent, Icon } from 'sarsaparilla';
import { closeSiteWideBanner } from '../../../ui-internal-account/src/actions/login';

export default function SiteWideAlert({ alerts = [], closedSiteWideIDs = {} }) {
    const filteredAlerts = alerts.filter((item) => !item.canceled_at);
    const dispatch = useDispatch();

    const shouldNotDisplay =
        filteredAlerts.filter((alert) => !!closedSiteWideIDs[alert.id]).length ===
        filteredAlerts.length;

    if (shouldNotDisplay) return null;
    const renderSideWide = (alert) => {
        if (closedSiteWideIDs[alert.id]) return null;

        return (
            <div
                key={alert.id}
                className="rec-error-full-banner"
                aria-labelledby="alertHeading"
            >
                <div className="rec-msg">
                    <div className="rec-msg-text">
                        <div className="rec-msg-text-type" id="alertHeading">
                            <div className="rec-alerts-notification-icon">
                                <Icon iconName="announcement" />
                            </div>
                            <strong>Urgent Announcement:</strong>
                        </div>
                        <div className="rec-msg-text-content">
                            <ul>
                                <li>
                                    <ReadMoreBlock linesToShow={2}>
                                        <DisplayHtmlContent html={alert.body} />
                                    </ReadMoreBlock>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="rec-notification-close"
                        aria-label="Close notification"
                        onClick={() => dispatch(closeSiteWideBanner(alert.id))}
                    >
                        <Icon iconName="close" />
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className={cx('sitewide-header-alerts', { 'is-inline': false })}>
            {filteredAlerts.map((alert) => renderSideWide(alert))}
        </div>
    );
}

SiteWideAlert.propTypes = {
    alerts: PropTypes.array,
    closedSiteWideIDs: PropTypes.object,
};
