/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { FlexRow, FlexCol } from 'sarsaparilla';
import ContactUsFAQ from './ContactUsFAQ';

export default function ContactUsTopInfo() {
    return (
        <section className="mb-6">
            <p>
                Have a question – we’re here to help! The quickest way to find the answer
                you’re looking for is to visit our&nbsp;
                <a
                    href={process.env.SN_KB_INT_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Help Center
                </a>
                . This is the best spot to get information about daily operations,
                inventory management, administration capabilities, and more. If you still
                have questions, you can contact the number below.
            </p>

            <FlexRow hasGutters={false} className="ia-nav-contact-info-table-wrap">
                <FlexCol md={4} className="ia-nav-contact-info-table-cell">
                    Help Desk Line (Toll-Free)
                </FlexCol>
                <FlexCol md={3} className="ia-nav-contact-info-table-cell">
                    877-345-6777
                </FlexCol>
                <FlexCol md={5} className="ia-nav-contact-info-table-cell">
                    Monday - Sunday 10 a.m. – 12:00 a.m. (Eastern Time Zone) *
                </FlexCol>
            </FlexRow>

            <span className="nav-contact-table-note">
                <strong>*</strong> Note - Closed on Thanksgiving, Christmas and New
                Year&#39;s Day
            </span>

            <div className="ia-contact-in-body-faq">
                <ContactUsFAQ />
            </div>
        </section>
    );
}
