/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import moment from 'moment';
import { isEmpty, isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Alert,
    Button,
    ButtonGroup,
    DividerNew as Divider,
    FeatureFlag,
    FlexCol,
    FlexRow,
    Icons,
    ModalActions,
    Spacer,
    StyledModal,
    Text,
    TextArea,
    TYPES,
    TextFieldStateless,
    Switch,
    phoneValidator,
    showToastAction,
    Heading,
} from 'sarsaparilla';
import { AnchorPageWithSidebarLayout, AnchorPageSection } from 'shared-ui';
import { enablePasswordReset } from '../../actions/resetPassword';
import {
    continueFromCreatingUser,
    deleteRole as deleteRoleAction,
    editUserRoles as editUserRolesAction,
    editingUser,
    resetUser,
    resendConfirmation as resendConfirmationAction,
    resetConfirmStatus,
    resetResetStatus,
    setDefaultRole as setDefaultRoleAction,
    setMFABypass,
    updateConcAssignment as updateConcAssignmentAction,
    updateMyProfile,
    userMFAEnrollmentStatus,
    resetMFA as resetMfaAction,
    emailChangeCancelled,
} from '../../actions/updateUser';
import { updateUserStatus as updateUserStatusAction } from '../../actions/userManagement';
import { addNoteForExistingUser } from '../../actions/notes';
import { postMfaBackupCodes as postMfaBackupCodesAction } from '../../actions/mfa';
import ActionErrorDisplay from '../ActionErrorDisplay';
import PhoneNumbers from '../UpdateUser/PhoneNumbers';
import RolesTable from '../UpdateUser/RolesTable';
import { errorForUpdateUser } from '../../utilities/errorMessages';
import {
    ATR,
    BAH_ADMIN,
    CSR1,
    CSR2,
    CSR_SUPERVISOR,
    PMO,
    SUPER_USER,
} from '../../utilities/roles';
import Toast from '../Toast';
import NewEmailAddressModal from '../UpdateUser/NewEmailAddressModal';
import UserStatusToggle from '../UserStatusToggle';
import { cancelEmailChange } from '../../actions/confirmEmail';

type MyProfilePanelProps = {
    dispatch: Function;
    setMFABypassFn: (userID: string) => Promise<any>;
    createToast: (toastObject: any) => void;
    reloadAccount: boolean;
    match: TYPES.MATCH;
    history: TYPES.HISTORY;
    location: TYPES.LOCATION;
    userAccount: any;
    accountToSave: any;
    readOnly: boolean;
    error: any;
    savedAccount: any;
    setDefaultRole: (role: any) => void;
    selfSave: (user: any) => void;
    resendConfirmation: (user: any) => void;
    resetPassword: (userId: string) => void;
    editUserRoles: (history: any) => void;
    updateConcAssignment: (history: any) => void;
    deleteRole: (role: any) => void;
    loggedInUser: any;
    postMfaBackupCodes: (userID: string) => Promise<any>;
    concessionaire: any;
    concessionaires: any[];
    resetMFA: (userID: string) => void;
    addNoteForUpdate: (text: string, reason: string) => void;
    updateUserStatus: (user: any, note: string) => void;
    updateUserStatusErrorMessage: string;
    updateUserStatusSuccessful: boolean;
    userIdEnabledPasswordReset: string;
    errorEnablingPasswordReset: string;
    requestConfirmResponse: any;
    toggleIsMfaModalOpen: () => void;
};

const createPhoneNumberListFromUser = (myProps: MyProfilePanelProps) => {
    const { accountToSave } = myProps;
    if (!accountToSave?.phone_numbers) return [];

    return accountToSave.phone_numbers.map((phone: any) => {
        const number = phone.phone
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(/\s+/g, '');
        return {
            ...phone,
            phone: `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`,
        };
    });
};

function MyProfilePanel({
    dispatch,
    setMFABypassFn,
    createToast,
    reloadAccount,
    match,
    history,
    location,
    userAccount,
    accountToSave,
    readOnly,
    error,
    savedAccount,
    setDefaultRole,
    selfSave,
    resendConfirmation,
    resetPassword,
    editUserRoles,
    updateConcAssignment,
    deleteRole,
    loggedInUser,
    postMfaBackupCodes,
    concessionaire,
    concessionaires,
    resetMFA,
    addNoteForUpdate,
    updateUserStatus,
    updateUserStatusErrorMessage,
    updateUserStatusSuccessful,
    userIdEnabledPasswordReset,
    errorEnablingPasswordReset,
    requestConfirmResponse,
    toggleIsMfaModalOpen,
}: MyProfilePanelProps) {
    const [state, setState] = useState({
        phoneNumbers: createPhoneNumberListFromUser({
            accountToSave,
        } as MyProfilePanelProps),
        canCreateConcessionaireManager: false,
        canCreateConcessionaire: false,
        creatingConcessionaire: false,
        loggedInConcessionaire: false,
        loggedInConcessionaireManager: false,
        edited: false,
        isCsrSupervisor: false,
        isValidPhoneNumber: true,
        canControlMFA: false,
        canDoMFABypass: false,
        canForceLogout: false,
        isMFAModalOpen: false,
        isMFABypassModalOpen: false,
        isNewMFABackupCodesModalOpen: false,
        isNewEmailModalOpen: false,
        statusToggleModalIsOpen: false,
        bypassMFAJustification: '',
        titleMFAModal: '',
        bodyMFAModal: <></>,
        actionMFAModal: () => {},
        userMFAStatus: '',
        user: {
            first_name: '',
            last_name: '',
            email: '',
            new_email: '',
            locked: false,
            can_run_transactions: false,
            should_receive_sms: false,
            enforce_effective_dates: false,
            effective_start_at: null,
            effective_end_at: null,
            roles: [],
        },
        changesCounter: 0,
    });
    const [showToast, setShowToast] = useState(false);
    const [typeToast, setTypeToast] = useState('success');
    const [msgToast, setMsgToast] = useState('');
    const [initialPhoneNumbers, setInitialPhoneNumbers] = useState<any>(null);

    const countDifferences = (obj1: any, obj2: any) => {
        let differences = 0;
        const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
        keys.forEach((key) => {
            const val1 = obj1[key];
            const val2 = obj2[key];
            if (Array.isArray(val1) && Array.isArray(val2)) {
                if (val1.length !== val2.length) {
                    differences += 1;
                }
            } else if (val1 !== val2) {
                differences += 1;
            }
        });
        return differences;
    };

    const updateStateUser = (user: any) => {
        setState((prevState) => {
            const newUser = {
                ...prevState.user,
                ...user,
            };
            return {
                ...prevState,
                user: newUser,
            };
        });
    };

    const setInitialValues = useCallback(
        (user: any) => {
            if (!user) return;
            const account = {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                new_email: '',
                locked: user.locked,
                can_run_transactions: user.can_run_transactions,
                should_receive_sms: user.should_receive_sms,
                enforce_effective_dates: accountToSave?.enforce_effective_dates,
                effective_start_at: accountToSave?.effective_start_at,
                effective_end_at: accountToSave?.effective_end_at,
                roles: user.roles,
            };
            updateStateUser(account);
        },
        [accountToSave]
    );

    const isUserConcessionaire = (user: any) => {
        return user && user.is_concessionaire && !user.inherits_concessionaire_roles;
    };

    const isUserConcessionaireManager = (user: any) => {
        return user && user.is_concessionaire && user.inherits_concessionaire_roles;
    };

    const initializeValues = useCallback(
        (user: any) => {
            const phoneNumbers = createPhoneNumberListFromUser({
                accountToSave: user,
            } as MyProfilePanelProps);
            const userMFAStatus = userMFAEnrollmentStatus(user);
            const isCsrOrPmoOrApmOrSuper = user?.roles.some((role: any) =>
                [SUPER_USER, BAH_ADMIN, PMO, ATR, CSR1].includes(role.role_type)
            );
            const isCsrSupervisor = user?.roles?.some((role: any) => {
                return [CSR_SUPERVISOR].includes(role.role_type);
            });
            const canControlMFA = user?.roles.some((role: any) =>
                [SUPER_USER, BAH_ADMIN, PMO, CSR2, CSR_SUPERVISOR].includes(
                    role.role_type
                )
            );
            const canDoMFABypass = user?.roles.some((role: any) =>
                [SUPER_USER, BAH_ADMIN, PMO].includes(role.role_type)
            );
            const canForceLogout = user?.roles.some((role: any) =>
                [CSR2, CSR_SUPERVISOR, BAH_ADMIN, SUPER_USER].includes(role.role_type)
            );
            const isConcessionaire = isUserConcessionaire(user);
            const isConcessionaireManager = isUserConcessionaireManager(user);

            setState((prevState) => ({
                ...prevState,
                phoneNumbers,
                userMFAStatus,
                canCreateConcessionaireManager: isCsrOrPmoOrApmOrSuper,
                canCreateConcessionaire: isConcessionaire,
                loggedInConcessionaire: isConcessionaire,
                loggedInConcessionaireManager: isConcessionaireManager,
                canControlMFA,
                canDoMFABypass,
                canForceLogout,
                isCsrSupervisor,
            }));

            setInitialPhoneNumbers(phoneNumbers);

            setInitialValues(user);

            if (location && location.pathname.includes('/internal/account/edit-user')) {
                if (match?.params.userId) {
                    dispatch(editingUser(match.params.userId));
                }
                dispatch(resetResetStatus());
                dispatch(resetConfirmStatus());
            }
        },
        [setInitialValues, dispatch, location, match]
    );

    const resetState = () =>
        setState((prevState) => ({ ...prevState, edited: false, changesCounter: 0 }));

    useEffect(() => {
        resetUser();
    }, []);

    useEffect(() => {
        dispatch(editingUser(loggedInUser.user_id));
    }, [dispatch, loggedInUser]);

    useEffect(() => {
        initializeValues(accountToSave);
    }, [initializeValues, accountToSave]);

    useEffect(() => {
        if (reloadAccount && accountToSave) {
            setInitialValues(accountToSave);
            const phoneNumbers = createPhoneNumberListFromUser({
                accountToSave,
            } as MyProfilePanelProps);
            const userMFAStatus = userMFAEnrollmentStatus(accountToSave);
            setInitialPhoneNumbers(phoneNumbers);
            setState((prevState) => ({
                ...prevState,
                phoneNumbers,
                creatingConcessionaire: accountToSave.is_concessionaire,
                userMFAStatus,
            }));
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadAccount, accountToSave]);

    useEffect(() => {
        if (savedAccount) {
            setInitialValues(savedAccount);
            const phoneNumbers = createPhoneNumberListFromUser({
                accountToSave: savedAccount,
            } as MyProfilePanelProps);
            const userMFAStatus = userMFAEnrollmentStatus(savedAccount);
            setInitialPhoneNumbers(phoneNumbers);
            setState((prevState) => ({
                ...prevState,
                phoneNumbers,
                creatingConcessionaire: savedAccount.is_concessionaire,
                userMFAStatus,
            }));
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedAccount]);

    const setEdited = () => setState((prevState) => ({ ...prevState, edited: true }));

    const setIsValidPhoneNumber = (inputPhoneVal: string) => {
        const isValidPhoneNumber = phoneValidator(inputPhoneVal).isValid;
        setState((prevState) => ({ ...prevState, isValidPhoneNumber }));
    };

    const setCanSave = () => {
        return (
            !readOnly ||
            !accountToSave ||
            (loggedInUser && loggedInUser.user_id === accountToSave.user_id)
        );
    };

    const getPhoneNumberList = (phoneNumbers: any) => {
        const formattedPhoneNumbers = phoneNumbers
            .filter(
                (inputNumber: any) =>
                    inputNumber.phone && inputNumber.phone !== '(___) ___ - ____'
            )
            .map((inputNumber: any) => ({
                ...inputNumber,
                phone: inputNumber.phone
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '')
                    .replace(/\s+/g, ''),
            }));
        return formattedPhoneNumbers;
    };

    const closeModal = () => {
        setState((prevState) => ({
            ...prevState,
            isMFAModalOpen: false,
            isMFABypassModalOpen: false,
            isNewMFABackupCodesModalOpen: false,
        }));
    };

    const closeNewEmailModal = () => {
        setState((prevState) => ({
            ...prevState,
            isNewEmailModalOpen: false,
        }));
    };

    const closeStatusToggleModal = () => {
        setState((prevState) => ({
            ...prevState,
            statusToggleModalIsOpen: false,
        }));
    };

    const confirmStatusToggleModal = async (note: string) => {
        closeStatusToggleModal();
        await updateUserStatus({ ...state.user, user_id: accountToSave.user_id }, note);
        updateStateUser({ locked: !state.user.locked });
        setShowToast(true);
    };

    const actionResetMFA = async () => {
        await resetMFA(accountToSave.user_id);
        createToast({ message: 'Reset MFA was successful!', type: 'info' });
        closeModal();
    };

    const actionRegenerateBackupCodes = async () => {
        const response = await postMfaBackupCodes(accountToSave.user_id);
        setState((prevState) => ({
            ...prevState,
            isNewMFABackupCodesModalOpen: true,
            isMFAModalOpen: true,
            titleMFAModal: 'New MFA Backup Codes',
            bodyMFAModal: (
                <>
                    <div>
                        The following are new backup codes that the user can use to
                        authenticate during login.
                    </div>
                    {response.codes.map((code: string) => (
                        <pre key={code}>{code}</pre>
                    ))}
                </>
            ),
            actionMFAModal: closeModal,
        }));
    };

    const actionSaveAllowMFABypass = async () => {
        const hasError = await setMFABypassFn(accountToSave?.user_id);
        if (hasError !== null) {
            createToast({
                message: 'Failed to set MFA bypass for the user',
                type: 'error',
            });
        } else {
            addNoteForUpdate(
                `MFA bypass allowed: ${state.bypassMFAJustification}`,
                'MFA_BYPASS'
            );
            createToast({ message: 'MFA bypass was successful!', type: 'info' });
            setState((prevState) => ({ ...prevState, bypassMFAJustification: '' }));
        }
        closeModal();
    };

    const setMFAModalState = (type: string) => {
        switch (type) {
            case 'reset':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    titleMFAModal: 'Reset MFA Confirmation',
                    bodyMFAModal: (
                        <>
                            <div>Are you sure you want to reset MFA?</div>
                            <br />
                            <div>
                                This action will unenroll MFA, User will be prompted to
                                enroll using a multi-factor authentication app on the next
                                login.
                            </div>
                        </>
                    ),
                    actionMFAModal: actionResetMFA,
                }));
                break;
            case 'backup':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    titleMFAModal: 'Regenerate Backup Codes',
                    bodyMFAModal: (
                        <>
                            <div>Are you sure you want to Regenerate Backup Codes?</div>
                            <br />
                            <div>
                                This action will delete previous Backup Codes generated,
                                and regenerate new ones to be used by the User next time
                                needed.
                            </div>
                        </>
                    ),
                    actionMFAModal: actionRegenerateBackupCodes,
                }));
                break;
            case 'bypass':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    isMFABypassModalOpen: true,
                    titleMFAModal: 'Allow MFA Bypass',
                    bodyMFAModal: (
                        <>
                            <div>
                                Are you sure you want to disable multi-factor
                                authentication (MFA) for this user?
                            </div>
                            <br />
                            <div>
                                You are required to enter a justification for this change.
                            </div>
                        </>
                    ),
                    actionMFAModal: actionSaveAllowMFABypass,
                }));
                break;
            case 'enroll':
                setState((prevState) => ({
                    ...prevState,
                    isMFAModalOpen: true,
                    isMFABypassModalOpen: true,
                    titleMFAModal: 'Enroll New MFA',
                    bodyMFAModal: (
                        <>
                            <div>Are you sure you want to Enroll New MFA?</div>
                            <br />
                            <div>This action will Enroll new MFA.</div>
                        </>
                    ),
                    actionMFAModal: toggleIsMfaModalOpen,
                }));
                break;
            default:
                break;
        }
    };

    const saveEnabled = () => {
        const { enforce_effective_dates, effective_start_at, effective_end_at } =
            state.user;
        return (
            (state.edited ||
                (state.changesCounter > 0 &&
                    getPhoneNumberList(state.phoneNumbers).length > 0)) &&
            state.isValidPhoneNumber &&
            (!enforce_effective_dates || (effective_start_at && effective_end_at))
        );
    };

    const handleSave = async (event?: React.FormEvent) => {
        event?.preventDefault();

        let user: any = {
            ...state.user,
            phone_numbers: getPhoneNumberList(state.phoneNumbers),
            email: !isEmpty(state.user.new_email)
                ? state.user.new_email
                : state.user.email,
            locked: state.user.locked,
            can_run_transactions: state.user.can_run_transactions,
            is_concessionaire:
                state.creatingConcessionaire ||
                state.loggedInConcessionaireManager ||
                state.loggedInConcessionaire,
            addresses: [],
        };

        user.should_receive_sms = state.user.should_receive_sms;

        if (accountToSave) {
            user = { ...accountToSave, ...user };
        }

        if (user.roles) {
            user.roles = user.roles.map((role: any) => ({
                role_type: role.role_type,
                is_default_role: role.is_default_role,
                permit_id: role.permit_id,
                poc_type: role.poc_type,
                location: {
                    location_id: role.location.location_id,
                    location_type: role.location.location_type,
                    has_lottery: role.location.has_lottery,
                    has_cooperators: role.location.has_cooperators,
                },
            }));
        }

        await selfSave(user);
        setShowToast(true);
        if (!isEmpty(state.user.new_email)) {
            updateStateUser({
                new_email: '',
            });
        }
    };

    const defaultRoleChange = (role: any) => {
        if (!role.is_default_role) {
            setDefaultRole(role);
        }
    };

    const handleEditRoleClick = () => editUserRoles(history);

    const handleEditAssignedConcessionairesClick = () => updateConcAssignment(history);

    const addPhoneNumber = () => {
        setState((prevState) => {
            const phoneNumbers = [
                ...prevState.phoneNumbers,
                { phone: '', ext: '', type: 'cell' },
            ];
            return {
                ...prevState,
                phoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    phoneNumbers
                ),
            };
        });
    };

    const removePhoneNumber = (index: number) => {
        const edited =
            getPhoneNumberList(state.phoneNumbers).length > 0 &&
            initialPhoneNumbers.length > 0;
        setState((prevState) => {
            const phoneNumbers = [...prevState.phoneNumbers];
            phoneNumbers.splice(index, 1);
            return {
                ...prevState,
                phoneNumbers,
                edited,
                changesCounter:
                    phoneNumbers.length === initialPhoneNumbers.length
                        ? 0
                        : (prevState.phoneNumbers ?? initialPhoneNumbers).length -
                          phoneNumbers.length,
            };
        });
    };

    const isLoggedUserSameRoleAsUser = (userA: any, userB: any) => {
        if (!userA.roles?.length || !userB.roles?.length) return null;

        const rolesArr1 = userA.roles.map((item: any) => item.role_type);
        const rolesArr2 = userB.roles.map((item: any) => item.role_type);

        return rolesArr1.some(
            (roleType: string) =>
                [PMO, BAH_ADMIN, ATR].includes(roleType) && rolesArr2.includes(roleType)
        );
    };

    const successUI = () => {
        if (!error && savedAccount) {
            return (
                <Toast show={showToast} type={typeToast} setShow={setShowToast}>
                    <p className="sarsa-text size-lg">{msgToast}</p>
                </Toast>
            );
        }
        if (updateUserStatusSuccessful) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Update user status was successful!
                    </p>
                </Toast>
            );
        }
        if (userIdEnabledPasswordReset) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Password reset email was sent successfully!
                    </p>
                </Toast>
            );
        }
        if (requestConfirmResponse?.ttl && !requestConfirmResponse?.error) {
            return (
                <Toast show={showToast} type="success" setShow={setShowToast}>
                    <p className="sarsa-text size-lg">
                        Confirmation email was sent successfully!
                    </p>
                </Toast>
            );
        }
        if (showToast) {
            return (
                <Toast show={showToast} type={typeToast} setShow={setShowToast}>
                    <p className="sarsa-text size-lg">{msgToast}</p>
                </Toast>
            );
        }
        return null;
    };

    const concessionaireEntity = () => {
        if (!accountToSave?.is_concessionaire) return null;

        if (concessionaires?.length > 0) {
            return (
                <div className="assigned-concessionaires-wrapper mt-3">
                    <div className="assigned-concessionaires-header">
                        Associated Concessionaires
                    </div>
                    <Button
                        appearance="link"
                        className="update-user-update-concessionaires-button"
                        iconBeforeElement={<Icons.IconAddCircle />}
                        onClick={handleEditAssignedConcessionairesClick}
                    >
                        Edit Assigned Concessionaires
                    </Button>
                    <ul className="assigned-concessionaires-list">
                        {concessionaires.map((conc) => (
                            <li key={`conc_${conc.concessionaire_id}`}>
                                {conc.concessionaire_name}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }

        if (concessionaire) {
            return (
                <div className="assigned-concessionaire-wrapper">
                    <div className="assigned-concessionaire-title">
                        Associated Concessionaire:
                        <span className="assigned-concessionaire-name">
                            {concessionaire.concessionaire_name}
                        </span>
                    </div>
                </div>
            );
        }

        return null;
    };

    const onPhoneTypeChange = (index: number, type: string) => {
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                ext: type === 'work' ? updatedPhoneNumbers[index].ext : '',
                type,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const onPhoneExtensionChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                ext: event.target.value,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const onPhoneNumberChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setState((prevState) => {
            const updatedPhoneNumbers = [...prevState.phoneNumbers];
            updatedPhoneNumbers[index] = {
                ...updatedPhoneNumbers[index],
                phone: event.target.value,
            };
            return {
                ...prevState,
                phoneNumbers: updatedPhoneNumbers,
                changesCounter: countDifferences(
                    prevState.phoneNumbers ?? initialPhoneNumbers,
                    updatedPhoneNumbers
                ),
            };
        });
    };

    const renderSidebarLayout = (effectiveStartAt: any, effectiveEndAt: any) => {
        const showCancelEmail =
            !isNil(accountToSave?.email_change) &&
            isNil(accountToSave?.email_change.completed_at);

        const canResendConfirmation =
            accountToSave?.is_email_confirmed === false || showCancelEmail;

        return (
            <div className="rec-nested-wrap">
                {successUI()}
                <AnchorPageWithSidebarLayout
                    className="te-facility-fees-tab"
                    preventScrollToAnchor
                    singlePanelMode
                >
                    <AnchorPageSection
                        heading="Personal Info"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        <FlexRow>
                            <FlexCol sm={3}>
                                <TextFieldStateless
                                    id="updateUserFirstName"
                                    label="First Name"
                                    value={state.user.first_name}
                                    onChange={(e) => {
                                        setEdited();
                                        updateStateUser({ first_name: e.target.value });
                                    }}
                                    placeholder="First Name"
                                    isRequired
                                    isDisabled={!setCanSave()}
                                />
                                <Spacer size="sm" />
                                <Text className="sarsa-label mt-1">Email</Text>
                                <Text>{state.user.email}</Text>
                                <Spacer size="sm" />
                            </FlexCol>
                            <FlexCol sm={3}>
                                <TextFieldStateless
                                    label="Last Name"
                                    value={state.user.last_name}
                                    onChange={(e) => {
                                        setEdited();
                                        updateStateUser({ last_name: e.target.value });
                                    }}
                                    placeholder="Last Name"
                                    isRequired
                                    isDisabled={!setCanSave()}
                                    id="updateUserLastName"
                                />
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol sm={6}>
                                <Alert type="info">
                                    Contact your manager if email address change is
                                    required.
                                </Alert>
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol>
                                <Divider className="mt-3 mb-3" />
                                <div className="rec-color-gray">
                                    <Heading headingLevel={2} appearance="h5">
                                        Phone Numbers
                                    </Heading>
                                </div>
                                <Spacer size="sm" />
                                <Spacer size="sm" />
                                <Switch
                                    isSelected={state.user.should_receive_sms}
                                    onChange={(event: any) => {
                                        setEdited();
                                        if (
                                            !state.user.should_receive_sms &&
                                            state.phoneNumbers.filter(
                                                (phone: any) => phone.type === 'cell'
                                            ).length === 0
                                        ) {
                                            addPhoneNumber();
                                        }
                                        if (!event?.target?.checked) {
                                            const phoneNumbers =
                                                createPhoneNumberListFromUser({
                                                    accountToSave: loggedInUser,
                                                } as MyProfilePanelProps);
                                            setState((prevState) => ({
                                                ...prevState,
                                                phoneNumbers,
                                            }));
                                            setInitialPhoneNumbers(phoneNumbers);
                                        }
                                        updateStateUser({
                                            should_receive_sms:
                                                !state.user.should_receive_sms,
                                        });
                                    }}
                                    label="Receive SMS (Text)"
                                    id="should_receive_sms"
                                />
                                <PhoneNumbers
                                    phoneNumbers={state.phoneNumbers}
                                    canSave={setCanSave()}
                                    onExtChange={(event, index) => {
                                        onPhoneExtensionChange(index, event);
                                    }}
                                    onTypeChange={(type, index) => {
                                        onPhoneTypeChange(index, type);
                                    }}
                                    onNumberChange={(event, index) => {
                                        setIsValidPhoneNumber(
                                            state.phoneNumbers[index].phone
                                        );
                                        onPhoneNumberChange(index, event);
                                    }}
                                    onRemovePhone={removePhoneNumber}
                                    onAddPhone={addPhoneNumber}
                                />
                            </FlexCol>
                        </FlexRow>
                        <FlexRow>
                            <FlexCol>
                                <ButtonGroup>
                                    <Button
                                        onClick={() => initializeValues(accountToSave)}
                                        id="updateUserCancel"
                                        appearance="tertiary"
                                    >
                                        Discard Changes
                                    </Button>
                                    {setCanSave() && (
                                        <Button
                                            appearance="primary"
                                            isDisabled={!saveEnabled()}
                                            onClick={(e) => {
                                                setTypeToast('success');
                                                setMsgToast(
                                                    `${!isEmpty(state.user.new_email) ? state.user.new_email : state.user.email} was saved successfully!`
                                                );
                                                handleSave(e);
                                            }}
                                            id="updateUserSave"
                                        >
                                            Save
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </FlexCol>
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="Roles and Locations"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        <FlexRow>
                            <FlexCol>
                                {concessionaireEntity()}
                                <RolesTable
                                    showBorders
                                    showHeader={false}
                                    showDefaultColumn={accountToSave?.roles?.length > 1}
                                    showDeleteColumn={false}
                                    canEditLocations={false}
                                    onEditLocations={handleEditRoleClick}
                                    onRoleDelete={deleteRole}
                                    onDefaultRoleChange={defaultRoleChange}
                                    editingSelf
                                    roles={accountToSave?.roles}
                                />
                            </FlexCol>
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="User History"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        {!userAccount.must_set_password && (
                            <FlexRow className="mt-3">
                                <FlexCol xs={6}>
                                    <Alert type="warning">
                                        <Link to="/internal/account/change-password">
                                            Update your password
                                        </Link>{' '}
                                        before{' '}
                                        {moment(userAccount.password_set_at)
                                            .add(3, 'months')
                                            .format('MMM D, YYYY')}
                                        .
                                    </Alert>
                                </FlexCol>
                            </FlexRow>
                        )}
                        <FlexRow className="mt-3 mb-2">
                            <FlexCol sm={2}>
                                <Text fontWeight="semibold">Created at</Text>
                                <Text>{`${moment(userAccount.created_at).format('MM/DD/YYYY')}`}</Text>
                            </FlexCol>
                            <FlexCol sm={2} className="">
                                <Text fontWeight="semibold">Last Logged in</Text>
                                <Text>{`${moment(userAccount.last_logged_in_at).format('MM/DD/YYYY')}`}</Text>
                            </FlexCol>
                            <FlexCol sm={2}>
                                <Text fontWeight="semibold">Last Password set</Text>
                                <Text>{`${moment(userAccount.password_set_at).format('MM/DD/YYYY')}`}</Text>
                            </FlexCol>
                        </FlexRow>
                    </AnchorPageSection>
                    <AnchorPageSection
                        heading="Security"
                        headingAppearance="h4"
                        headingLevel={2}
                    >
                        {state.user.enforce_effective_dates && (
                            <>
                                <FlexRow className="mt-5 mb-3">
                                    <FlexCol sm={6}>
                                        <div className="rec-color-gray">
                                            <Heading headingLevel={2} appearance="h5Caps">
                                                Effective Dates
                                            </Heading>
                                        </div>
                                    </FlexCol>
                                </FlexRow>
                                <FlexRow className="mt-3 mb-3">
                                    <FlexCol sm={1}>
                                        <Text fontWeight="semibold">Start Date</Text>
                                        <Text>{`${effectiveStartAt ? moment(effectiveStartAt).format('MM/DD/YYYY') : 'N/A'}`}</Text>
                                    </FlexCol>
                                    <FlexCol sm={1}>
                                        <Text fontWeight="semibold">End Date</Text>
                                        <Text>{`${effectiveEndAt ? moment(effectiveEndAt).format('MM/DD/YYYY') : 'N/A'}`}</Text>
                                    </FlexCol>
                                </FlexRow>
                                <Divider className="mt-3 mb-3" />
                            </>
                        )}
                        <FlexRow className="mt-4 mb-2">
                            <FlexCol sm={6}>
                                <div className="rec-color-gray">
                                    <Heading headingLevel={2} appearance="h5Caps">
                                        Email
                                    </Heading>
                                </div>
                                <Spacer size="sm" />
                                <Text className="sarsa-label mt-1">
                                    Current Email Address
                                </Text>
                                <Text>{state.user.email}</Text>
                            </FlexCol>
                        </FlexRow>
                        {!isNil(accountToSave?.email_change) &&
                            isNil(accountToSave?.email_change.completed_at) && (
                                <FlexRow className="mb-2">
                                    <FlexCol sm={12}>
                                        <Alert
                                            type="warning"
                                            heading="Email Change Pending"
                                        >
                                            You have a e-mail change request pending
                                            confirmation.
                                            <Spacer size="sm" />
                                            New e-mail:{' '}
                                            {accountToSave?.email_change.new_email}
                                        </Alert>
                                    </FlexCol>
                                </FlexRow>
                            )}
                        <FlexRow className="space-left mb-1">
                            {isLoggedUserSameRoleAsUser(loggedInUser, state.user) ===
                                false && (
                                <FlexRow className="space-left mb-3">
                                    <ButtonGroup isStretchedToFit>
                                        {showCancelEmail && (
                                            <Button
                                                appearance="tertiary"
                                                onClick={() => {
                                                    dispatch(
                                                        cancelEmailChange(
                                                            accountToSave?.user_id
                                                        )
                                                    );
                                                    dispatch(emailChangeCancelled());
                                                }}
                                            >
                                                Cancel Email Change
                                            </Button>
                                        )}
                                        {canResendConfirmation && (
                                            <Button
                                                appearance="tertiary"
                                                onClick={() => {
                                                    resendConfirmation(accountToSave);
                                                    setShowToast(true);
                                                }}
                                            >
                                                Resend Confirmation Email
                                            </Button>
                                        )}
                                        {accountToSave?.is_email_confirmed && (
                                            <Button
                                                appearance="tertiary"
                                                onClick={() => {
                                                    resetPassword(accountToSave.user_id);
                                                    setShowToast(true);
                                                }}
                                            >
                                                Send Password Reset Email
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </FlexRow>
                            )}
                        </FlexRow>
                        <FeatureFlag flag="iaEnableMfa">
                            <Divider className="mt-1 mb-3" />
                            <FlexRow className="mt-4 mb-3">
                                <FlexCol sm={6}>
                                    <div className="rec-color-gray">
                                        <Heading headingLevel={2} appearance="h5Caps">
                                            Multi-factor Authentication
                                        </Heading>
                                    </div>
                                </FlexCol>
                            </FlexRow>
                            <FlexRow className="space-left">
                                <div className="update-user-roles-table-header">
                                    Multi-factor Authentication Enrollment Status
                                </div>
                            </FlexRow>
                            <FlexRow className="space-left">
                                <div className="mfa-enrollment-status">
                                    {state.userMFAStatus}
                                </div>
                            </FlexRow>
                            <FlexRow className="space-left mb-4">
                                {state.canControlMFA && (
                                    <ButtonGroup isStretchedToFit>
                                        <Button
                                            appearance="tertiary"
                                            onClick={() => setMFAModalState('reset')}
                                        >
                                            Reset MFA
                                        </Button>
                                        <Button
                                            appearance="tertiary"
                                            onClick={() => setMFAModalState('backup')}
                                        >
                                            Regenerate Back Up Codes
                                        </Button>
                                        <Button
                                            appearance="tertiary"
                                            onClick={() => setMFAModalState('enroll')}
                                        >
                                            Enroll New MFA
                                        </Button>
                                    </ButtonGroup>
                                )}
                            </FlexRow>
                        </FeatureFlag>
                    </AnchorPageSection>
                </AnchorPageWithSidebarLayout>
            </div>
        );
    };

    const effectiveStartAt = !isEmpty(state.user.effective_start_at)
        ? state.user.effective_start_at
        : null;
    const effectiveEndAt = !isEmpty(state.user.effective_end_at)
        ? state.user.effective_end_at
        : null;

    return (
        <div>
            <div className="edit-user-wrapper">
                <div id="page-body">
                    <ActionErrorDisplay
                        error={error}
                        errorStringMapping={errorForUpdateUser}
                    />
                    {updateUserStatusErrorMessage && (
                        <Alert shouldFocusOnMount type="error">
                            {updateUserStatusErrorMessage}
                        </Alert>
                    )}
                    {errorEnablingPasswordReset && (
                        <Alert shouldFocusOnMount type="error">
                            {errorEnablingPasswordReset}
                        </Alert>
                    )}
                    {requestConfirmResponse?.error && (
                        <Alert shouldFocusOnMount type="error">
                            {requestConfirmResponse?.error}
                        </Alert>
                    )}
                    {renderSidebarLayout(effectiveStartAt, effectiveEndAt)}
                </div>
            </div>
            <FeatureFlag flag="iaEnableMfa">
                <StyledModal
                    size="sm"
                    isOpen={state.isMFAModalOpen}
                    heading={state.titleMFAModal}
                    onRequestClose={closeModal}
                >
                    <div className="modal-mfa-text-body">{state.bodyMFAModal}</div>
                    {state.isMFABypassModalOpen && (
                        <TextArea
                            placeholder="Write the justification here:"
                            id="justification"
                            label=""
                            value={state.bypassMFAJustification}
                            onChange={(e: { target: { value: any } }) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    bypassMFAJustification: e.target.value,
                                }))
                            }
                        />
                    )}
                    <ModalActions>
                        <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                            {!state.isNewMFABackupCodesModalOpen && (
                                <Button appearance="tertiary" onClick={closeModal}>
                                    Cancel
                                </Button>
                            )}
                            <Button
                                onClick={state.actionMFAModal}
                                isDisabled={
                                    state.isMFABypassModalOpen &&
                                    state.bypassMFAJustification.length <= 1
                                }
                            >
                                {state.isMFABypassModalOpen
                                    ? 'Confirm MFA Bypass'
                                    : 'Okay'}
                            </Button>
                        </ButtonGroup>
                    </ModalActions>
                </StyledModal>
            </FeatureFlag>
            <NewEmailAddressModal
                isOpen={state.isNewEmailModalOpen}
                email={state.user.new_email}
                currentEmail={state.user.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateStateUser({
                        new_email: e.target.value,
                    });
                }}
                onConfirm={() => {
                    closeNewEmailModal();
                    setTypeToast('info');
                    setMsgToast(
                        `A change to the user's email address has been requested. The user will receive a confirmation email (sent to the requested address) and must click a link in that email to confirm this change.`
                    );
                    handleSave();
                }}
                onClose={closeNewEmailModal}
            />
            <UserStatusToggle
                isOpen={state.statusToggleModalIsOpen}
                selectedUser={state.user}
                cancel={closeStatusToggleModal}
                confirm={confirmStatusToggleModal}
            />
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    userAccount: state.login.user,
    accountToSave: state.updateUser.accountToSave,
    savedAccount: state.updateUser.accountSaved,
    error: state.updateUser.error,
    reloadAccount: state.updateUser.reloadAccount,
    readOnly: state.updateUser.readOnly,
    loggedInUser: state.login ? state.login.user : null,
    concessionaire: state.fetchManagedConcessionaires.assignedConcessionaire,
    concessionaires: state.updateUser.assignedConcessionaires,
    updateUserStatusErrorMessage: state.userManagement.updateUserStatusErrorMessage,
    updateUserStatusSuccessful: state.userManagement.updateUserStatusSuccessful,
    userIdEnabledPasswordReset: state.updateUser.userIdEnabledPasswordReset,
    errorEnablingPasswordReset: state.updateUser.errorEnablingPasswordReset,
    requestConfirmResponse: state.updateUser.requestConfirmResponse,
});

const mapDispatchToProps = (dispatch: Function, ownProps: any) => ({
    dispatch,
    editUserRoles: () => dispatch(editUserRolesAction(ownProps.history)),
    updateConcAssignment: (history: any) => dispatch(updateConcAssignmentAction(history)),
    deleteRole: (role: any) => dispatch(deleteRoleAction(role)),
    selfSave: (user: any) => dispatch(updateMyProfile(user)),
    continueFromCreate: (user: any) => dispatch(continueFromCreatingUser(user)),
    setDefaultRole: (role: any) => dispatch(setDefaultRoleAction(role)),
    resendConfirmation: (user: any) => dispatch(resendConfirmationAction(user)),
    resetPassword: (userId: string) => dispatch(enablePasswordReset(userId)),
    setMFABypassFn: (userID: string) => dispatch(setMFABypass(userID)),
    resetMFA: (userID: string) => dispatch(resetMfaAction(userID)),
    postMfaBackupCodes: (userID: string) => postMfaBackupCodesAction(dispatch, userID),
    createToast: (toastObject: any) => dispatch(showToastAction(toastObject)),
    addNoteForUpdate: (text: string, reason: string) =>
        dispatch(addNoteForExistingUser(text, reason)),
    updateUserStatus: (user: any, note: string) =>
        dispatch(updateUserStatusAction(user, note)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePanel);
