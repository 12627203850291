/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button, FlexCol, FlexRow, Text, Heading, Alert } from 'sarsaparilla';
import { errorForLogin } from 'ui-internal-account/src/utilities/errorMessages';
import { logOut } from '../../actions/login';

type MfaFormProps = {
    submitFn: (code: string) => void;
    error: Error;
    hideStartOver?: boolean;
    submitText?: string;
};

export function MfaValidateForm(props: MfaFormProps) {
    const dispatch = useDispatch();
    const [codes, setCodes] = useState('');

    const isValidCode = (code: string) => {
        const regex = /^[0-9]{6}$/;
        const backupCodeRegex = /^[a-zA-Z0-9]{10}$/;
        return !isEmpty(code) && (regex.test(code) || backupCodeRegex.test(code));
    };

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const code = e.target.value;
        setCodes(code);
    };

    const logoutHandler = () => {
        // @ts-ignore ... redux store is not typed
        dispatch(logOut());
    };

    const signIn = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.submitFn(codes);
    };

    const renderErrorMessage = () => {
        if (props.error) {
            const err = errorForLogin(props.error, 0);

            return (
                <Alert shouldFocusOnMount type="error">
                    {err}
                </Alert>
            );
        }

        return null;
    };

    const hideStartOver =
        props.hideStartOver === false || props.hideStartOver === undefined;
    const submitText = props.submitText ?? 'Log In';

    return (
        <form onSubmit={signIn}>
            <div>
                <Heading headingLevel={1} appearance="h1" className="mfa-login-heading">
                    Sign In
                </Heading>

                <Heading headingLevel={2} appearance="h6" className="mfa-login-heading">
                    6-digit Code from Authenticator App or one-time backup code
                </Heading>
                <FlexRow className="mt-2">
                    <FlexCol>
                        <input
                            id="codes"
                            type="text"
                            className={
                                codes.length > 6 ? 'mfa-backup-code' : 'mfa-input-code'
                            }
                            value={codes}
                            onChange={handleCodeChange}
                        />
                    </FlexCol>
                </FlexRow>

                {renderErrorMessage()}

                <Button type="submit" id="login" isDisabled={!isValidCode(codes)}>
                    {submitText}
                </Button>
                <div>
                    <Text>Having trouble logging in?</Text>
                    <Text>
                        Reach out to your manager or contact the Help Desk&apos;s Internal
                        Help Line.
                    </Text>
                    {!hideStartOver && (
                        <Link
                            id="logout"
                            to="/internal/account/login"
                            onClick={logoutHandler}
                        >
                            Start over
                        </Link>
                    )}
                </div>
            </div>
        </form>
    );
}
