/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import download from 'downloadjs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Button,
    ButtonGroup,
    Checkbox,
    EmailField,
    FlexCol,
    FlexRow,
    ModalActions,
    Spacer,
    StyledModal,
    TYPES,
    TextFieldStateless,
    Switch,
    phoneValidator,
    useFlags,
    Alert,
} from 'sarsaparilla';
import { createMfaBackupCodes, clearGenerateMfaTotp } from '../../actions/mfa';
import {
    cancelUpdate,
    updateMyProfile,
    userMFAEnrollmentStatus,
    resetMFA,
    setDefaultRole as setDefaultRoleAction,
} from '../../actions/updateUser';
import ActionErrorDisplay from '../ActionErrorDisplay';
import AdditionalInformation from '../UpdateUser/AdditionalInformation';
import PhoneNumbers from '../UpdateUser/PhoneNumbers';
import RolesTable from '../UpdateUser/RolesTable';
import EffectiveDates from '../UserEffectiveDates';
import {
    errorForUpdateUser,
    errorForMfaBackupCodes,
} from '../../utilities/errorMessages';
import UserNotes from '../UserNotes';

const propTypes = {
    history: TYPES.HISTORY,
    savedAccount: PropTypes.object,
    accountToSave: PropTypes.object,
    reloadAccount: PropTypes.bool,
    error: PropTypes.string,
    save: PropTypes.func,
    cancelUpdate: PropTypes.func,
    loggedInUser: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        phone_numbers: PropTypes.array,
        should_receive_sms: PropTypes.bool,
        user_id: PropTypes.string,
        email: PropTypes.string,
        roles: PropTypes.array,
        enforce_effective_dates: PropTypes.bool,
        effective_start_at: PropTypes.string,
        effective_end_at: PropTypes.string,
        mfa_data: PropTypes.shape({
            bypass_at: PropTypes.string,
            opt_out_at: PropTypes.string,
            verified_at: PropTypes.string,
        }),
    }),
    toggleIsMfaModalOpen: PropTypes.func,
    resetMFA: PropTypes.func,
    createBackupCodes: PropTypes.func,
    clearBackupCodes: PropTypes.func,
    backupCodes: PropTypes.array,
    createMfaBackupCodesError: PropTypes.bool,
    setDefaultRole: PropTypes.func,
};

const createPhoneNumberListFromUser = (accountToSave) => {
    let phoneNumberList = [];
    if (accountToSave && accountToSave.phone_numbers) {
        phoneNumberList = accountToSave.phone_numbers.map((_phone) => {
            const phone = { ..._phone };
            const number = phone.phone;
            const formattedNum = `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
            phone.phone = formattedNum;
            return phone;
        });
    } else {
        phoneNumberList = [];
    }
    return phoneNumberList;
};

const setInitialValues = (accountToSave) => {
    if (accountToSave) {
        return {
            first_name: accountToSave.first_name,
            last_name: accountToSave.last_name,
            should_receive_sms: accountToSave.should_receive_sms,
        };
    }
    return {
        first_name: '',
        last_name: '',
        should_receive_sms: false,
    };
};

function MyProfile(props) {
    const { iaEnableMfa } = useFlags();
    const [phoneNumbers, setPhoneNumbers] = useState(createPhoneNumberListFromUser());
    const [edited, setEdited] = useState(false);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [user, setUser] = useState(setInitialValues(props.loggedInUser));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [titleMFAModal, setTitleMFAModal] = useState('');
    const [bodyMFAModal, setBodyMFAModal] = useState('');
    const [mfaType, setMfaType] = useState('');
    const [userMFAStatus, setUserMFAStatus] = useState(
        userMFAEnrollmentStatus(props.accountToSave)
    );
    const canSave = !isEmpty(props.loggedInUser) && !isEmpty(props.loggedInUser.user_id);

    useEffect(
        () => setUserMFAStatus(userMFAEnrollmentStatus(props.accountToSave)),
        [props.accountToSave]
    );

    const updateStateUser = (account) => {
        setUser({ ...user, ...account });
    };

    const defaultRoleChange = (role) => {
        if (!role.is_default_role) {
            props.setDefaultRole(role);
        }
    };

    const onPhoneTypeChange = (index, type) => {
        const updatedPhoneNumbers = [...phoneNumbers];

        updatedPhoneNumbers[index] = {
            phone: phoneNumbers[index].phone,
            ext: type === 'work' ? phoneNumbers[index].ext : '',
            type,
        };

        setPhoneNumbers(updatedPhoneNumbers);
    };

    const onPhoneNumberChange = (index, event) => {
        const updatedPhoneNumbers = [...phoneNumbers];

        updatedPhoneNumbers[index] = {
            phone: event.target.value,
            ext: phoneNumbers[index].ext,
            type: phoneNumbers[index].type,
        };

        setPhoneNumbers(updatedPhoneNumbers);
    };

    const onPhoneExtensionChange = (index, event) => {
        const updatedPhoneNumbers = [...phoneNumbers];

        updatedPhoneNumbers[index] = {
            phone: phoneNumbers[index].phone,
            ext: event.target.value,
            type: phoneNumbers[index].type,
        };

        setPhoneNumbers(updatedPhoneNumbers);
    };

    const setValidPhoneNumber = (inputPhoneVal) => {
        setIsValidPhoneNumber(phoneValidator(inputPhoneVal).isValid);
    };

    const getPhoneNumberList = () => {
        const formattedPhoneNumbers = [];
        const inputPhoneNumbers = phoneNumbers;

        for (const inputNumber of inputPhoneNumbers) {
            if (inputNumber.phone !== '' && inputNumber.phone !== '(___) ___ - ____') {
                // Remove all parentheses, dashes, and spaces
                inputNumber.phone = inputNumber.phone
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '')
                    .replace(/\s+/g, '');

                formattedPhoneNumbers.push(inputNumber);
            }
        }

        return formattedPhoneNumbers;
    };

    const save = (event) => {
        event.preventDefault();
        const account = {
            first_name: user.first_name,
            last_name: user.last_name,
            phone_numbers: getPhoneNumberList(),
            should_receive_sms: user.should_receive_sms,
        };
        props.save({
            ...props.loggedInUser,
            ...account,
        });
        setEdited(false);
    };

    const successUI = () => {
        if (!props.error && props.savedAccount) {
            return (
                <Alert shouldFocusOnMount type="success">
                    {`${props.savedAccount.email} was saved successfully.`}
                </Alert>
            );
        }

        return null;
    };

    const addPhoneNumber = () => {
        const numbers = phoneNumbers.concat({
            phone: '',
            ext: '',
            type: 'cell',
        });
        setPhoneNumbers(numbers);
    };

    const removePhoneNumber = (index) => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers.splice(index, 1);
        setPhoneNumbers(newPhoneNumbers);
    };

    const setMFAModalState = (type) => {
        setIsModalOpen(true);
        switch (type) {
            case 'reset':
                setMfaType('reset');
                setTitleMFAModal('Reset MFA Confirmation');
                setBodyMFAModal(
                    `Are you sure you want to reset MFA?\n\nThis action will unenroll MFA, User will be prompted to enroll using a multi-factor authentication app on the next login.`
                );
                break;
            case 'backup':
                setMfaType('backup');
                setTitleMFAModal('Regenerate Backup Codes');
                setBodyMFAModal(
                    `Are you sure you want to Regenerate Backup Codes?\n\nThis action will delete previous Backup Codes generated, and regenerate new ones to be used by the User next time needed.`
                );
                break;
            case 'enroll':
                setMfaType('enroll');
                setTitleMFAModal('Enroll New MFA');
                setBodyMFAModal(
                    `Are you sure you want to Enroll New MFA?\n\nThis action will Enroll new MFA.`
                );
                break;
            default:
                break;
        }
    };

    const saveEnabled = () => {
        if (!edited) {
            return false;
        }

        if (!isValidPhoneNumber) {
            return false;
        }

        if (!user.first_name || !user.last_name) {
            return false;
        }

        return true;
    };

    const executeMfaAction = async () => {
        setIsModalOpen(false);
        switch (mfaType) {
            case 'reset':
                await props.resetMFA(props.loggedInUser.user_id);
                break;
            case 'backup':
                await props.createBackupCodes();
                break;
            case 'enroll':
                props.toggleIsMfaModalOpen();
                break;
            default:
                break;
        }
    };

    const progressRibbon = () => {
        return (
            <div className="progress-ribbon-wrapper">
                <div className="user-management-button-header">
                    <ButtonGroup>
                        <Button
                            onClick={() => props.cancelUpdate(props.history)}
                            id="updateUserCancel"
                            appearance="tertiary"
                        >
                            Cancel
                        </Button>
                        {canSave && (
                            <Button
                                appearance="primary"
                                isDisabled={!saveEnabled()}
                                onClick={save}
                                id="updateUserSave"
                            >
                                Save
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
        );
    };

    const isUserConcessionaire = () => {
        return user && user.is_concessionaire && !user.inherits_concessionaire_roles;
    };

    const isUserConcessionaireManager = () => {
        return user && user.is_concessionaire && user.inherits_concessionaire_roles;
    };

    const isConcessionaire = isUserConcessionaire();
    const isConcessionaireManager = isUserConcessionaireManager();
    const enforceEffectiveDates = props.loggedInUser?.enforce_effective_dates;
    const effectiveStartAt = !isEmpty(props.loggedInUser?.effective_start_at)
        ? props.loggedInUser?.effective_start_at
        : null;
    const effectiveEndAt = !isEmpty(props.loggedInUser?.effective_end_at)
        ? props.loggedInUser?.effective_end_at
        : null;

    useEffect(() => {
        setPhoneNumbers(createPhoneNumberListFromUser(props.loggedInUser));
    }, [props]);

    useEffect(() => {
        if (props.reloadAccount && props.loggedInUser) {
            setPhoneNumbers(createPhoneNumberListFromUser(props.loggedInUser));
        }
    }, [props]);

    useEffect(() => {
        if (!isEmpty(props.backupCodes) && !props.createMfaBackupCodesError) {
            download(props.backupCodes.join('\n'), 'mfa_backup_codes.txt', 'text/plain');
            props.clearBackupCodes();
        }
    }, [props]);

    return (
        <div>
            <div className="edit-user-wrapper">
                {progressRibbon()}
                <div id="page-body">
                    {successUI()}
                    <ActionErrorDisplay
                        error={!isEmpty(props.error) || props.createMfaBackupCodesError}
                        errorStringMapping={
                            !isEmpty(props.error)
                                ? errorForUpdateUser
                                : errorForMfaBackupCodes
                        }
                    />
                    <div className="rec-section-outer-wrap">
                        <div className="rec-nested-wrap">
                            <FlexRow>
                                <FlexCol sm={6}>
                                    <TextFieldStateless
                                        id="updateUserFirstName"
                                        label="First Name"
                                        value={user.first_name}
                                        onChange={(e) => {
                                            setEdited(true);
                                            updateStateUser({
                                                first_name: e.target.value,
                                            });
                                        }}
                                        placeholder="First Name"
                                        isRequired
                                        isDisabled={!canSave}
                                    />
                                    <Spacer size="sm" />
                                    <TextFieldStateless
                                        label="Last Name"
                                        value={user.last_name}
                                        onChange={(e) => {
                                            setEdited(true);
                                            updateStateUser({
                                                last_name: e.target.value,
                                            });
                                        }}
                                        placeholder="Last Name"
                                        isRequired
                                        isDisabled={!canSave}
                                        id="updateUserLastName"
                                    />
                                    <Spacer size="sm" />
                                    <EmailField
                                        value={props.loggedInUser?.email}
                                        readOnly
                                        isDisabled
                                        id="updateUserEmail"
                                    />
                                    <Spacer size="sm" />
                                    {(isConcessionaire || isConcessionaireManager) && (
                                        <Checkbox
                                            isDisabled
                                            isChecked
                                            label={
                                                isConcessionaire
                                                    ? 'Concessionaire'
                                                    : 'Concessionaire Facility Manager'
                                            }
                                            id="concessionaire"
                                        />
                                    )}
                                    <Link
                                        className="update-user-change-password-link"
                                        to="/internal/account/change-password"
                                    >
                                        Change Password
                                    </Link>
                                    <fieldset>
                                        <div>
                                            <Spacer size="sm" />
                                            <Switch
                                                isSelected={user.should_receive_sms}
                                                onChange={(event) => {
                                                    setEdited(true);
                                                    if (
                                                        !user.should_receive_sms &&
                                                        phoneNumbers.filter(
                                                            (phone) =>
                                                                phone.type === 'cell'
                                                        ).length === 0
                                                    ) {
                                                        addPhoneNumber();
                                                    }
                                                    if (!event?.target?.checked) {
                                                        setPhoneNumbers(
                                                            createPhoneNumberListFromUser(
                                                                props.loggedInUser
                                                            )
                                                        );
                                                    }
                                                    updateStateUser({
                                                        should_receive_sms:
                                                            !user.should_receive_sms,
                                                    });
                                                }}
                                                label="Receive SMS (Text)"
                                                id="should_receive_sms"
                                            />
                                            <Spacer size="sm" />
                                        </div>
                                    </fieldset>
                                    <PhoneNumbers
                                        phoneNumbers={phoneNumbers}
                                        canSave={canSave}
                                        onExtChange={(event, index) => {
                                            setEdited(true);
                                            onPhoneExtensionChange(index, event);
                                        }}
                                        onTypeChange={(type, index) => {
                                            setEdited(true);
                                            onPhoneTypeChange(index, type);
                                        }}
                                        onNumberChange={(event, index) => {
                                            setEdited(true);
                                            setValidPhoneNumber(
                                                phoneNumbers[index].phone
                                            );
                                            onPhoneNumberChange(index, event);
                                        }}
                                        onRemovePhone={(index) => {
                                            removePhoneNumber(index);
                                        }}
                                        onAddPhone={() => {
                                            addPhoneNumber();
                                        }}
                                        disabledAddPhone={!user.should_receive_sms}
                                    />
                                    {iaEnableMfa && (
                                        <>
                                            <div className="update-user-roles-table-header">
                                                Multi-factor Authentication Enrollment
                                                Status
                                            </div>
                                            <div className="mfa-enrollment-status-label">
                                                {userMFAStatus}
                                            </div>
                                            <ButtonGroup isStretchedToFit>
                                                <Button
                                                    appearance="tertiary"
                                                    onClick={() =>
                                                        setMFAModalState('reset')
                                                    }
                                                >
                                                    Reset MFA
                                                </Button>
                                                <Button
                                                    appearance="tertiary"
                                                    onClick={() =>
                                                        setMFAModalState('backup')
                                                    }
                                                >
                                                    Regenerate Back Up Codes
                                                </Button>
                                                <Button
                                                    appearance="tertiary"
                                                    onClick={() =>
                                                        setMFAModalState('enroll')
                                                    }
                                                >
                                                    Enroll New MFA
                                                </Button>
                                            </ButtonGroup>
                                        </>
                                    )}
                                    <RolesTable
                                        showDefaultColumn={
                                            props.loggedInUser?.roles?.length > 1
                                        }
                                        onDefaultRoleChange={defaultRoleChange}
                                        showDeleteColumn={false}
                                        canEditLocations={false}
                                        onRoleDelete={() => {}}
                                        editingSelf
                                        roles={props.loggedInUser?.roles}
                                    />
                                </FlexCol>
                                <FlexCol sm={6}>
                                    <div className="nested-grid-inner-wrap">
                                        <div className="rec-form-check-wrap">
                                            <div className="rec-grid-9-12 mt-1">
                                                <Switch
                                                    label={
                                                        !user.locked ? 'Active' : 'Locked'
                                                    }
                                                    isSelected={!user.locked}
                                                    isDisabled
                                                    onChange={() => {}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <EffectiveDates
                                        editable={false}
                                        effectiveDatesEnabled={enforceEffectiveDates}
                                        inStartDate={effectiveStartAt}
                                        inEndDate={effectiveEndAt}
                                    />
                                    <div className="rec-form-item-wrap">
                                        <UserNotes readOnly />
                                    </div>
                                    <AdditionalInformation
                                        user={{
                                            ...props.loggedInUser,
                                            enforce_effective_dates:
                                                enforceEffectiveDates,
                                            effective_start_at: effectiveStartAt,
                                            effective_end_at: effectiveEndAt,
                                        }}
                                    />
                                </FlexCol>
                            </FlexRow>
                        </div>
                    </div>
                </div>
            </div>
            <StyledModal
                size="sm"
                isOpen={isModalOpen}
                heading={titleMFAModal}
                onRequestClose={() => setIsModalOpen(false)}
            >
                <div className="modal-mfa-text-body">{bodyMFAModal}</div>
                <ModalActions>
                    <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                        <Button
                            appearance="tertiary"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button onClick={executeMfaAction}>Okay</Button>
                    </ButtonGroup>
                </ModalActions>
            </StyledModal>
        </div>
    );
}

MyProfile.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        savedAccount: state.updateUser.accountSaved,
        error: state.updateUser.error ?? null,
        reloadAccount: state.updateUser.reloadAccount,
        loggedInUser: state.login ? state.login.user : null,
        backupCodes: state.mfa?.backupCodes ?? [],
        createMfaBackupCodesError: state.mfa?.createMfaBackupCodesError ?? false,
        accountToSave: state.updateUser.accountToSave,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        save: (user) => dispatch(updateMyProfile(user)),
        cancelUpdate: (history) => dispatch(cancelUpdate(history)),
        resetMFA: (userID) => dispatch(resetMFA(userID)),
        createBackupCodes: () => dispatch(createMfaBackupCodes()),
        clearBackupCodes: () => dispatch(clearGenerateMfaTotp()),
        setDefaultRole: (role) => dispatch(setDefaultRoleAction(role)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
