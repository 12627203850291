/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * InternalContactUsNotification.jsx
 * Created by Destin Frasier on 01/19/21
 * This is controled via the launchdarkly "internal-contact-us-alert" flag.
 */

import React from 'react';
import { DisplayHtmlContent, useFlags, Alert } from 'sarsaparilla';

export default function PassNotification() {
    const { internalContactUsAlert } = useFlags();

    // Bail if no data from LaunchDarkly
    if (!internalContactUsAlert) {
        return null;
    }

    const [title, headingLevel, alertContent, alertType, className] =
        internalContactUsAlert;

    const isOff =
        !Array.isArray(internalContactUsAlert) || internalContactUsAlert.length === 0;

    // Don't show if the flag is set to off
    if (isOff) {
        return null;
    }

    let decodedAlertBody = '';
    try {
        decodedAlertBody = decodeURIComponent(alertContent);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        decodedAlertBody = '';
    }

    return (
        <Alert
            type={alertType}
            headingLevel={headingLevel}
            heading={title}
            className={className}
        >
            <DisplayHtmlContent html={decodedAlertBody} />
        </Alert>
    );
}
