/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

const ACC_PASS_RESET: string = 'ACC_PASS_RESET';
const BULK_TICKET: string = 'BULK_TICKET';
const CAMPINV: string = 'CAMPINV';
const CAMPRES: string = 'CAMPRES';
const CLOSURES: string = 'CLOSURES';
const COMM_SETUP: string = 'COMM_SETUP';
const CONF: string = 'CONF';
const EMV: string = 'EMV';
const ENHANCEMENT: string = 'ENHANCEMENT';
const MAPPING: string = 'MAPPING';
const REFUND: string = 'REFUND';
const REPORTS: string = 'REPORTS';
const RIDB: string = 'RIDB';
const SATELLITE: string = 'SATELLITE';
const SEASON: string = 'SEASON';
const SHIFTS: string = 'SHIFTS';
const SPECIAL_EVENT: string = 'SPECIAL_EVENT';
const SYS_REQS: string = 'SYS_REQS';
const TECH: string = 'TECH';
const TRAINING: string = 'TRAINING';
const OTHER: string = 'OTHER';

export default [
    {
        label: 'Account/Password Issue',
        value: ACC_PASS_RESET,
    },
    {
        label: 'Alert/Communications Setup',
        value: COMM_SETUP,
    },
    {
        label: 'Bulk Ticket Shipping Request',
        value: BULK_TICKET,
    },
    {
        label: 'Camping Inventory Management',
        value: CAMPINV,
    },
    {
        label: 'Camping Reservation Management/Field Sales',
        value: CAMPRES,
    },
    {
        label: 'Closures',
        value: CLOSURES,
    },
    {
        label: 'Confirm Customer Reservation',
        value: CONF,
    },
    {
        label: 'EMV Device',
        value: EMV,
    },
    {
        label: 'Enhancement',
        value: ENHANCEMENT,
    },
    {
        label: 'Facility Tech Issues',
        value: TECH,
    },
    {
        label: 'Mapping',
        value: MAPPING,
    },
    {
        label: 'Refund Requests/Refund Issues',
        value: REFUND,
    },
    {
        label: 'Reports',
        value: REPORTS,
    },
    {
        label: 'RIDB Issue',
        value: RIDB,
    },
    {
        label: 'Satellite Issue',
        value: SATELLITE,
    },
    {
        label: 'Season & Fee Management',
        value: SEASON,
    },
    {
        label: 'Shifts & Deposits',
        value: SHIFTS,
    },
    {
        label: 'Special Event Ticket Printing',
        value: SPECIAL_EVENT,
    },
    {
        label: 'System Requests',
        value: SYS_REQS,
    },
    {
        label: 'Training',
        value: TRAINING,
    },
    {
        label: 'Other',
        value: OTHER,
    },
];
