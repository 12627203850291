/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    error: null,
};

const changePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CHANGE_PASSWORD_FAILED:
            return { ...state, error: action.error };
        case types.CHANGE_PASSWORD_CANCELED:
        case types.CHANGE_PASSWORD_SUCCESSFUL:
            return { ...state, error: null };
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};

export default changePasswordReducer;
