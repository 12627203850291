/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Heading, Spacer, TYPES, Alert } from 'sarsaparilla';
import SiteWrapper from './SiteWrapper';
import UserManagementHeader from './UserManagementHeader';

import {
    assignLocation,
    cancelAssigningLocations,
    continueFromAssigningLocations,
    deleteLocation,
} from '../actions/updateUser';
import { fetchAssignableConcessionairePermits } from '../actions/manageConcessionaires';

import EditUserProgress from '../components/EditUserProgress';
import {
    errorForAssigningLocations,
    errorForLoadingLocations,
} from '../utilities/errorMessages';
import UserLocationSelection from './UserLocationSelection';
import DisplayLocations from '../components/userLocation/DisplayLocations';

const propTypes = {
    history: TYPES.HISTORY,
    dispatch: PropTypes.func,
    assignLocation: PropTypes.func,
    deleteLocation: PropTypes.func,
    error: PropTypes.string,
    save: PropTypes.func,
    cancelAssigningLocations: PropTypes.func,
    loadingLocationsError: PropTypes.string,
    creating: PropTypes.bool,
    accountToSave: PropTypes.object,
};

export class EditLocations extends React.Component {
    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fetchAssignableConcessionairePermits());
    }

    save = () => {
        this.props.save();
        this.props.history.push('/internal/account/edit-roles');
    };

    cancel = () => {
        this.props.cancelAssigningLocations(this.props.history);
    };

    continueError = () => {
        if (!this.props.error) {
            return null;
        }

        return (
            <Alert shouldFocusOnMount type="error" className="assign-locations-error">
                {errorForAssigningLocations(this.props.error)}
            </Alert>
        );
    };

    loadingLocationError = () => {
        if (!this.props.loadingLocationsError) {
            return null;
        }

        return (
            <Alert shouldFocusOnMount type="error">
                {errorForLoadingLocations()}
            </Alert>
        );
    };

    assignLocation = (location) => {
        const locationAlreadyAssigned = this.props.assignedLocations?.some(
            (assignedLocation) => assignedLocation.location_id === location.location_id
        );
        if (!locationAlreadyAssigned) {
            this.props.assignLocation(location);
        }
    };

    progressRibbon() {
        return (
            <div className="progress-ribbon-wrapper">
                <EditUserProgress showUserDetails={this.props.creating} current="1" />
                <div className="user-management-button-header">
                    <button
                        className="edit-locations-cancel rec-button-tertiary"
                        onClick={this.cancel}
                    >
                        Previous
                    </button>
                    <button
                        className="edit-locations-continue rec-button-primary"
                        disabled={this.props.assignedLocations <= 0}
                        onClick={this.save}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <SiteWrapper>
                <div className="page-title">
                    <UserManagementHeader editing={this.props.editing} />
                </div>
                <div id="UserManagement" className="page-content wrapper">
                    <div className="edit-user-wrapper">
                        {this.progressRibbon()}
                        {this.continueError()}
                        <div className="edit-locations-wrapper">
                            <Heading appearance="h5" headingLevel={2} className="mb-2">
                                Assign Location
                            </Heading>
                            <div className="ia-create-user-note h5">
                                Find and add a location below for
                                <b>
                                    {get(this.props, 'accountToSave.first_name', 'NA')}{' '}
                                    {get(this.props, 'accountToSave.last_name', 'NA')}
                                </b>
                                :
                            </div>
                            <DisplayLocations
                                assignedLocations={this.props.assignedLocations}
                                deleteLocation={this.props.deleteLocation}
                            />
                            <Spacer size="lg" />

                            {this.loadingLocationError()}

                            <UserLocationSelection
                                assignLocation={this.assignLocation}
                                useConcessionaireHierarchy={this.props.creating}
                            />
                        </div>
                    </div>
                </div>
            </SiteWrapper>
        );
    }
}

EditLocations.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        creatingUser: state.updateUser.creating,
        editing: state.userManagement.selectedTabIndex === 2,
        assignedLocations: state.updateUser.assignedLocations,
        managedLocations: state.fetchManagedLocations.managedLocations,
        assignablePermits: state.updateUser.assignablePermits,
        creating: state.updateUser.creating,
        accountToSave: state.updateUser.accountToSave,
        error: state.updateUser.error,
        loadingLocationsError: state.updateUser.loadingLocationsError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        assignLocation: (location) => dispatch(assignLocation(location)),
        deleteLocation: (location) => dispatch(deleteLocation(location)),
        save: () => dispatch(continueFromAssigningLocations()),
        cancelAssigningLocations: (history) =>
            dispatch(cancelAssigningLocations(history)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLocations);
