/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import memoize from 'memoize-one';
import { getLocationDisplayName, isFacilityNode } from './locations';
import { ALL_LOCATIONS_OPTION } from '../constants/locationPicker';

export function addTreeData(location, parent) {
    const count = parent?.count ? parent.count + 1 : 1;
    const val = {
        ...location,
        id: `${location.location_id}_${location.location_type}_${count}`,
        parentId: null,
        count,
    };
    if (parent?.id) {
        val.parentId = parent.id;
        val.location_parent_id = parent.location_id;
        val.location_parent_type = parent.location_type;
    }
    return val;
}

const addParentIdToReservableLocations = (parent, reservableLocations) => {
    return reservableLocations.map((location) => {
        return {
            ...location,
            id: location.location_id + location.location_type,
            parentId: parent.id,
            location_parent_id: parent.location_id,
            location_parent_type: parent.location_type,
        };
    });
};

const getReservableChildLocations = (location, assumeAlwaysChildren) => {
    let childLocations = [];

    if (location.campsites && location.campsites.length > 0) {
        childLocations = childLocations.concat(
            addParentIdToReservableLocations(location, location.campsites)
        );
    }

    if (location.tours && location.tours.length > 0) {
        childLocations = childLocations.concat(
            addParentIdToReservableLocations(location, location.tours)
        );
    }

    if (assumeAlwaysChildren && childLocations.length === 0) {
        childLocations = childLocations.concat(
            addParentIdToReservableLocations(location, [
                {
                    id: 'empty',
                    location_id: 'empty',
                    location_name: 'Loading',
                },
            ])
        );
    }

    childLocations.sort((a, b) => a.location_name.localeCompare(b.location_name));
    return childLocations;
};

function locationMatchesFilters(location, filters) {
    return (
        location.id === 'nodata' ||
        location.id === 'empty' ||
        filters.includes(location.location_type) ||
        filters.some((filter) => location.types_in_subtree?.includes(filter))
    );
}

function sortLocationDisplayName(locA, locB) {
    const nameA = getLocationDisplayName(locA).toUpperCase();
    const nameB = getLocationDisplayName(locB).toUpperCase();
    return nameA.localeCompare(nameB);
}

export function doBuildTreeData(
    data,
    maxDepth,
    showReservableLocations,
    allowAllLocationSelect,
    visibleLocationTypes
) {
    const tree = allowAllLocationSelect ? [ALL_LOCATIONS_OPTION] : [];
    const branchesMap = {};

    for (const location of data) {
        if (
            visibleLocationTypes?.length > 0 &&
            !locationMatchesFilters(location, visibleLocationTypes)
        ) {
            continue;
        }
        if (!location.parentId) {
            tree.push(location);
        } else {
            const children = branchesMap[location.parentId];

            if (!children) {
                branchesMap[location.parentId] = [location];
            } else {
                children.push(location);
            }
        }
    }

    for (const trunk of tree) {
        trunk.children =
            branchesMap[trunk.id] && branchesMap[trunk.id].sort(sortLocationDisplayName);
        trunk.depth = 0;
        if (!trunk.children && trunk.location_children_count > 0) {
            //Adding a loading row for location whose children have not been loaded yet.
            trunk.children = [
                {
                    id: 'empty',
                    parentId: `${trunk.location_id}_${trunk.location_type}_${trunk.count}`,
                    location_id: 'empty',
                    location_name: 'Loading',
                    count: trunk.count, //This is weird
                    depth: trunk.count,
                },
            ];
        } else if (showReservableLocations && isFacilityNode(trunk)) {
            trunk.children = getReservableChildLocations(trunk, true);
        }

        const growTree = (branches, depth) => {
            if (branches) {
                for (const branch of branches) {
                    branch.children =
                        branchesMap[branch.id] &&
                        branchesMap[branch.id].sort(sortLocationDisplayName);
                    branch.depth = depth;

                    if (depth < maxDepth) {
                        if (branch.children) {
                            growTree(branch.children, branch.depth + 1);
                        } else if (branch.location_children_count > 0) {
                            //Adding a loading row for location whose children have not been loaded yet.
                            branch.children = [
                                {
                                    id: 'empty',
                                    parentId: `${branch.location_id}_${branch.location_type}_${branch.count}`,
                                    location_id: 'empty',
                                    location_name: 'Loading',
                                    count: branch.count + 1,
                                    depth: branch.count,
                                },
                            ];
                        } else if (showReservableLocations && isFacilityNode(branch)) {
                            branch.children = getReservableChildLocations(branch);
                        }
                    }
                }
            }
        };

        growTree(trunk.children, trunk.depth + 1);
    }

    return tree;
}

export const BuildTreeData = memoize(doBuildTreeData);
