/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
    TYPES,
    Button,
    ButtonGroup,
    TextFieldStateless,
    Spacer,
    FlexRow,
    FlexCol,
    Alert,
} from 'sarsaparilla';
import SiteWrapper from './SiteWrapper';
import PageHeader from '../components/PageHeader';
import { cancelUpdate } from '../actions/updateUser';
import {
    fetchMachineApiKeys,
    generateMachineApiKey,
    disableMachineApiKey,
} from '../actions/machineApi';
import ActionErrorDisplay from '../components/ActionErrorDisplay';
import {
    errorForGetMachineApiKeys,
    errorForGenerateMachineApiKey,
    errorForDisableMachineApiKey,
} from '../utilities/errorMessages';

const propTypes = {
    history: TYPES.HISTORY,
    machineApiKeys: PropTypes.array,
    getMachineApiKeysError: PropTypes.bool,
    generateMachineApiKeyError: PropTypes.bool,
    disableMachineApiKeyError: PropTypes.bool,
    error: PropTypes.any,
    goBack: PropTypes.func,
    fetchKeys: PropTypes.func,
    generateKey: PropTypes.func,
    disableKey: PropTypes.func,
};

function MachineApiKeysPage({
    history,
    machineApiKeys,
    getMachineApiKeysError,
    generateMachineApiKeyError,
    disableMachineApiKeyError,
    error,
    goBack,
    fetchKeys,
    generateKey,
    disableKey,
}) {
    const [showNotification, setShowNotification] = useState(false);

    const displayNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 5000);
    };

    const generate = async () => {
        await generateKey();
        await fetchKeys();
    };

    const disable = async (id) => {
        await disableKey(id);
        await fetchKeys();
        displayNotification();
    };

    useEffect(() => {
        const fetch = async () => {
            await fetchKeys();
        };
        fetch();
    }, [fetchKeys]);

    const filteredKeys = machineApiKeys?.filter((key) => !isEmpty(key?.id)) ?? [];

    return (
        <SiteWrapper>
            <div className="page-title">
                <PageHeader title="Machine API Keys" />
            </div>
            <div id="UserManagement" className="page-content wrapper">
                <div>
                    <div className="edit-user-wrapper">
                        <div className="progress-ribbon-wrapper">
                            <div className="user-management-button-header">
                                <ButtonGroup>
                                    <Button
                                        id="back"
                                        appearance="tertiary"
                                        onClick={() => goBack(history)}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        id="generate"
                                        appearance="primary"
                                        onClick={generate}
                                    >
                                        Generate New APIKey
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div id="page-body">
                            {showNotification && (
                                <Alert shouldFocusOnMount type="success">
                                    Expiration date updated successfully.
                                </Alert>
                            )}
                            {getMachineApiKeysError && error && (
                                <ActionErrorDisplay
                                    error={error}
                                    errorStringMapping={errorForGetMachineApiKeys}
                                />
                            )}
                            {generateMachineApiKeyError && error && (
                                <ActionErrorDisplay
                                    error={error}
                                    errorStringMapping={errorForGenerateMachineApiKey}
                                />
                            )}
                            {disableMachineApiKeyError && error && (
                                <ActionErrorDisplay
                                    error={error}
                                    errorStringMapping={errorForDisableMachineApiKey}
                                />
                            )}
                            <div className="rec-section-outer-wrap">
                                <div className="rec-nested-wrap">
                                    {!!filteredKeys?.length &&
                                        filteredKeys.map((apiKey, index) => (
                                            <FlexRow key={apiKey.id}>
                                                <FlexCol sm={6}>
                                                    <TextFieldStateless
                                                        id={`api-key-${index}`}
                                                        label={`Machine APIKey #${index + 1}${
                                                            apiKey.expiration_date > 0
                                                                ? ` (Expires in ${`${moment
                                                                      .unix(
                                                                          apiKey.expiration_date
                                                                      )
                                                                      .format(
                                                                          'MM/DD/YYYY [at] hh:mm a z'
                                                                      )}`.trim()}):`
                                                                : ':'
                                                        }`}
                                                        value={apiKey.id}
                                                        isDisabled
                                                    />
                                                    <Spacer size="sm" />
                                                </FlexCol>
                                                <FlexCol sm={2}>
                                                    <Button
                                                        className="disable-key-button"
                                                        appearance="secondary"
                                                        onClick={() => disable(apiKey.id)}
                                                    >
                                                        Disable
                                                    </Button>
                                                </FlexCol>
                                            </FlexRow>
                                        ))}
                                    {!filteredKeys.length && (
                                        <FlexRow>
                                            <FlexCol sm={6}>
                                                <TextFieldStateless
                                                    id="api-key"
                                                    label="Machine APIKey:"
                                                    value="No machine APIKeys found"
                                                    isDisabled
                                                />
                                                <Spacer size="sm" />
                                            </FlexCol>
                                        </FlexRow>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SiteWrapper>
    );
}

MachineApiKeysPage.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        machineApiKeys: state.machineApi.machineApiKeys,
        getMachineApiKeysError: state.machineApi.getMachineApiKeysError,
        generateMachineApiKeyError: state.machineApi.generateMachineApiKeyError,
        disableMachineApiKeyError: state.machineApi.disableMachineApiKeyError,
        error: state.machineApi.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        goBack: (history) => dispatch(cancelUpdate(history)),
        fetchKeys: () => dispatch(fetchMachineApiKeys()),
        generateKey: () => dispatch(generateMachineApiKey()),
        disableKey: (id) => dispatch(disableMachineApiKey(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineApiKeysPage);
