/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';

// import { fetchChildLocations, fetchSearchLocations } from "../../../ui-internal-account";
import PageHeader from '../components/PageHeader';
import SiteWrapper from './SiteWrapper';
import { saveConcessionaire } from '../actions/manageConcessionaires';

import EditConcessionaire from '../components/concessionaire/EditConcessionaire';

export class EditConcessionairesContainer extends React.PureComponent {
    static propTypes = {
        history: TYPES.HISTORY,
        concessionaire: PropTypes.any,
        permit: PropTypes.any,
        savingManagedConcessionairesError: PropTypes.any,
        savingConcessionairePermitsError: PropTypes.any,
        saveConcessionaire: PropTypes.func,
    };

    saveConcessionaire = (concessionaire, add) => {
        const conc = concessionaire || this.props.concessionaire;
        this.props.saveConcessionaire(conc, add, this.props.history);
    };

    render() {
        const {
            history,
            concessionaire,
            permit,
            savingConcessionairePermitsError,
            savingManagedConcessionairesError,
        } = this.props;

        let error = '';
        let navBackUrl = '/internal/account/manage-concessionaires';
        const tabs = [
            {
                title: 'Concessionaires',
            },
        ];
        const crumbs = [
            {
                label: 'Concessionaires',
                onClick: () => history.push('/internal/account/manage-concessionaires'),
            },
        ];

        if (this.props.concessionaire && this.props.concessionaire.concessionaire_id) {
            navBackUrl = '/internal/account/concessionaire-details';
            crumbs.push({
                label: 'Concessionaire Details',
                onClick: () => history.push('/internal/account/concessionaire-details'),
            });
        }

        if (concessionaire && concessionaire.concessionaire_id && permit) {
            crumbs.push({
                label: this.props.permit.permit_id ? 'Edit Permit' : 'Add Permit',
            });
            error = savingConcessionairePermitsError;
        } else if (concessionaire) {
            crumbs.push({
                label: concessionaire.concessionaire_id
                    ? 'Edit Concessionaire'
                    : 'Add Concessionaire',
            });
            error = savingManagedConcessionairesError;
        }

        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader
                        title="Concessionaire Management"
                        tabs={tabs}
                        breadcrumbs={crumbs}
                        navBack={navBackUrl}
                        selectedTabIndex={0}
                    />
                </div>
                <div id="ConcessionaireManagement" className="page-content wrapper">
                    <EditConcessionaire
                        history={history}
                        concessionaire={concessionaire}
                        saveConcessionaire={this.saveConcessionaire}
                        error={error}
                    />
                </div>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        concessionaire: state.manageConcessionairesSet.managedConcessionaire,
        permit: state.manageConcessionairesSet.concessionairePermit,
        concessionaires: state.manageConcessionaires.managedConcessionaires,
        permits: state.manageConcessionaires.concessionairePermits,
        loadingManagedConcessionairesError:
            state.manageConcessionaires.loadingManagedConcessionairesError,
        loadingConcessionairePermitsError:
            state.manageConcessionaires.loadingConcessionairePermitsError,
        savingManagedConcessionairesError:
            state.manageConcessionaires.savingManagedConcessionairesError,
        savingConcessionairePermitsError:
            state.manageConcessionaires.savingConcessionairePermitsError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveConcessionaire: (concessionaires, add, history) =>
            dispatch(saveConcessionaire(concessionaires, add, history)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditConcessionairesContainer);
