/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Icons,
    TextFieldStateless,
    TYPES,
    StyledModal,
    ModalActions,
    ButtonGroup,
    Button,
    Spacer,
    Alert,
} from 'sarsaparilla';
import { compileAddress } from '../../utilities/locations';
import SelectRowTable from '../tableUtil/SelectRowTable';
import TextHighlight from '../tableUtil/TextHighlight';
import ManageCooperatorsTableActions from './ManageCooperatorsTableActions';

const propTypes = {
    history: TYPES.HISTORY,
    gridData: PropTypes.array,
    setCooperator: PropTypes.func,
    saveCooperators: PropTypes.func,
    deleteCooperators: PropTypes.func,
    errorText: PropTypes.string,
    selectedFlatRows: PropTypes.array,
};

export default function ManageCooperators({
    history,
    gridData,
    setCooperator,
    saveCooperators,
    deleteCooperators,
    errorText,
}) {
    const [modalAction, setModalAction] = useState('');
    const [modalCoops, setModalCoops] = useState([]);

    function openModal(action, coops) {
        setModalAction(action);
        setModalCoops(coops);
    }

    function closeModal() {
        setModalAction('');
        setModalCoops([]);
    }

    function toggleLock(doLock) {
        saveCooperators(modalCoops.map((coop) => ({ ...coop, locked: doLock })));
    }

    function confirmModal() {
        switch (modalAction) {
            case 'Lock':
                toggleLock(true);
                break;
            case 'Unlock':
                toggleLock(false);
                break;
            case 'Delete':
                deleteCooperators(modalCoops);
                break;
            default:
            //Nothing
        }
        closeModal();
    }

    function navEditCooperator(cooperator) {
        setCooperator(cooperator);
        history.push('/internal/account/cooperator-details');
    }

    function navAddCooperator() {
        setCooperator({});
        history.push('/internal/account/cooperator-edit');
    }

    const filterUI = useCallback(
        ({ setGlobalFilter, globalFilter }) => (
            <TextFieldStateless
                label="Search Text"
                isLabelVisible={false}
                placeholder="Search items"
                onChange={({ target: { value } }) => setGlobalFilter(value)}
                handleClearButtonClick={() => setGlobalFilter('')}
                hasClearButton
                value={globalFilter || ''}
                iconElement={<Icons.IconSearch />}
                id="filter-text-search"
            />
        ),
        []
    );

    const selectActions = useCallback(
        (props) => (
            <ManageCooperatorsTableActions
                onDelete={(coops) => openModal('Delete', coops)}
                onLock={(coops) => openModal('Lock', coops)}
                onUnlock={(coops) => openModal('Unlock', coops)}
                selectedFlatRows={props.selectedFlatRows}
            />
        ),
        []
    );

    const renderLockColumn = (coop) => {
        if (coop.locked) {
            return (
                <div className="grid-icon-holder">
                    <Icons.IconLock />
                </div>
            );
        }
        return null;
    };

    const renderCooperatorName = (coop, globalFilter) => {
        return (
            <Button onClick={() => navEditCooperator(coop)} appearance="link">
                <TextHighlight searchTerm={globalFilter} body={coop.cooperator_name} />
            </Button>
        );
    };

    const renderPrimaryContact = (value, globalFilter) => {
        return <TextHighlight body={value} searchTerm={globalFilter} />;
    };

    const renderPhoneNumber = (value, globalFilter) => {
        <TextHighlight body={value} searchTerm={globalFilter} />;
    };

    const renderMailingAddress = (value, globalFilter) => {
        return <TextHighlight body={value} searchTerm={globalFilter} />;
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                minWidth: 30,
                maxWidth: 50,
                id: 'lock-column',
                disableGlobalFilter: true,
                sortable: false,
                Cell: ({ row: { original: coop } }) => {
                    return renderLockColumn(coop);
                },
            },
            {
                Header: 'Name',
                accessor: (row) => row.cooperator_name.toLowerCase(),
                id: 'cooperator_name',
                width: 80,
                minWidth: 80,
                Cell: ({ row: { original: coop }, state: { globalFilter } }) => {
                    return renderCooperatorName(coop, globalFilter);
                },
            },
            {
                Header: 'Primary Contact',
                id: 'primary-contact',
                width: 70,
                minWidth: 70,
                Cell: ({ value, state: { globalFilter } }) => {
                    renderPrimaryContact(value, globalFilter);
                },
                accessor: (coop) => {
                    return coop.designated_agent
                        ? coop.designated_agent.replace(/([A-Za-z],)([A-Za-z])/, '$1 $2')
                        : '';
                },
            },
            {
                Header: 'Employees',
                accessor: 'num_users',
                width: 30,
                minWidth: 30,
                disableGlobalFilter: true,
            },
            {
                Header: 'Phone #',
                accessor: 'phone_number',
                Cell: ({ value, state: { globalFilter } }) => {
                    return renderPhoneNumber(value, globalFilter);
                },
                width: 30,
                minWidth: 30,
            },
            {
                Header: 'Mailing Address',
                id: 'address',
                width: 110,
                minWidth: 110,
                accessor: (coop) => compileAddress(coop.address),
                Cell: ({ value, state: { globalFilter } }) => {
                    return renderMailingAddress(value, globalFilter);
                },
            },
        ],
        []
    );

    return (
        <div className="manage-cooperators-wrapper">
            <div className="cooperators-header">
                <h2 className="cooperator-component-title h5">
                    <span className="header-main-title">Managed Cooperators</span>
                </h2>
                <Button
                    appearance="primary"
                    iconBeforeElement={<Icons.IconAddCircle />}
                    onClick={navAddCooperator}
                >
                    Add Cooperator
                </Button>
            </div>

            {errorText && (
                <Alert shouldFocusOnMount type="error" className="cooperators-error mb-1">
                    {errorText}
                </Alert>
            )}

            <Spacer size="sm" />
            <SelectRowTable
                data={gridData || []}
                noDataText="No cooperators managed."
                defaultSortId="cooperator_name"
                columns={columns}
                filtersComponent={filterUI}
                selectActionComponent={selectActions}
                tableCaption="managed cooperators"
            />

            <StyledModal
                heading={`${modalAction} Cooperator${modalCoops.length > 1 ? 's' : ''}`}
                onRequestClose={closeModal}
                isOpen={modalAction.length > 0}
                size="md"
            >
                <div className="delete-user-warning-modal-body">
                    <div className="modal-sub-title">
                        Would you like to {modalAction.toLowerCase()} the following:
                    </div>
                    <ul>
                        {modalCoops.map((coop, i) => (
                            <li key={`list_coop_${i}`}>{coop.cooperator_name}</li>
                        ))}
                    </ul>
                </div>
                <ModalActions>
                    <ButtonGroup>
                        <Button onClick={closeModal} appearance="tertiary">
                            Cancel
                        </Button>
                        <Button onClick={confirmModal}>Confirm and {modalAction} </Button>
                    </ButtonGroup>
                </ModalActions>
            </StyledModal>
        </div>
    );
}

ManageCooperators.propTypes = propTypes;
