/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as globals from '../constants/globals';
import {
    ATTESTATION_STATE,
    FETCH_ATTESTATION_FAILURE,
    FETCH_ATTESTATION_START,
    FETCH_ATTESTATION_SUCCESS,
    POST_ATTESTATION_START,
    POST_ATTESTATION_SUCCESS,
    POST_ATTESTATION_FAILURE,
    FETCH_ATTESTATION_RESET,
} from '../constants/types';
import { attestPostSelector, attestStateSelector } from '../reducers/attestation';
import { makeAuthorizedRequest } from './login';

// Attestation State
export const setAttestationState = (payload) => ({ type: ATTESTATION_STATE, payload });

// Fetch Attestation
const fetchAttestationEndpoint = (locationType, locationId, roleType) =>
    `${globals.API_URL}/attestations/${encodeURIComponent(locationType)}/${encodeURIComponent(
        locationId
    )}/${encodeURIComponent(roleType)}/last`;

const startFetch = () => ({ type: FETCH_ATTESTATION_START });
const fetchSuccess = (payload) => ({ type: FETCH_ATTESTATION_SUCCESS, payload });
const fetchFailure = (error) => ({ type: FETCH_ATTESTATION_FAILURE, error });

export const fetchAttestation =
    (locationType, locationId, roleType) => async (dispatch) => {
        dispatch(startFetch());
        try {
            const response = await makeAuthorizedRequest(
                fetchAttestationEndpoint(locationType, locationId, roleType),
                'GET',
                dispatch
            );
            dispatch(fetchSuccess(response));
        } catch (e) {
            dispatch(fetchFailure(e));
        }
    };

export const fetchAttestationReset = () => ({ type: FETCH_ATTESTATION_RESET });

// Post Attestation
const mergeAttestation = () => (dispatch, getState) => {
    const data = attestPostSelector(getState());
    dispatch(fetchSuccess(data));
};

const postAttestationEndpoint = () => `${globals.API_URL}/attestations`;

const startPost = () => ({ type: POST_ATTESTATION_START });
const postSuccess = (payload) => ({ type: POST_ATTESTATION_SUCCESS, payload });
const postFailure = (error) => ({ type: POST_ATTESTATION_FAILURE, error });

const postAttestation = (payload) => async (dispatch) => {
    dispatch(startPost());
    try {
        const response = await makeAuthorizedRequest(
            postAttestationEndpoint(),
            'POST',
            dispatch,
            payload
        );
        dispatch(postSuccess(response));
        dispatch(mergeAttestation());
    } catch (e) {
        dispatch(postFailure(e));
    }
};

export const attestUsers = () => async (dispatch, getState) => {
    const state = getState();
    const attestState = attestStateSelector(state);
    const userResults = state.fetchUsers.userResults;
    const attestedUsers = userResults.filter((user) => {
        const roles = user?.roles || [];
        const filteredRoles = roles.filter(
            (role) =>
                role.role_type === attestState.role &&
                role.location_id === attestState.location.location_id
        );
        return filteredRoles.length > 0;
    });

    const payload = {
        location: {
            location_id: attestState.location.location_id,
            location_type: attestState.location.location_type,
        },
        role_type: attestState.role,
        attested_user_ids: attestedUsers.map((user) => user.user_id),
    };
    await dispatch(postAttestation(payload));
};
