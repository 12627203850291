/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Icons } from '@fp/sarsaparilla';
import { isDescendant } from '../utilities/tools';
import { getLocationDisplayName } from '../utilities/locations';
import LaunchLocationSelect from './LaunchLocationSelect';

const propTypes = {
    filterID: PropTypes.string,
    selectedLocation: PropTypes.object,
    locationSelect: PropTypes.func.isRequired,
    handleSelectorClick: PropTypes.func,
    initiallySelectedLocation: PropTypes.object,
    placeholder: PropTypes.string,
    launchButtonAppearance: PropTypes.string,
    launchButtonRoleText: PropTypes.string,
    launchButtonClassName: PropTypes.string,
    clearFuncHack: PropTypes.func,
    showReservableLocations: PropTypes.bool,
    allowAllLocationSelect: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    displaySelectedLocation: PropTypes.bool,
    selectableLocationTypes: PropTypes.arrayOf(PropTypes.string),
    visibleLocationTypes: PropTypes.arrayOf(PropTypes.string),
    disableConcessionaireHierarchy: PropTypes.bool,
    allowHierarchyFillSelection: PropTypes.bool,
    dropdownPlacement: PropTypes.string,
};

const defaultProps = {
    filterID: 'location-picker',
    launchButtonAppearance: 'tertiary',
    launchButtonClassName: 'picker-launch-button',
    showReservableLocations: false,
    allowAllLocationSelect: false,
    closeOnSelect: true,
    displaySelectedLocation: true,
    disableConcessionaireHierarchy: false,
    allowHierarchyFillSelection: false,
    dropdownPlacement: 'left',
};

const isResizeObserverSupported =
    typeof window !== 'undefined' && 'ResizeObserver' in window;

export default function LocationPicker(props) {
    const [locationFocused, setLocationFocused] = React.useState(false);
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const parentDivRef = React.useRef(null);

    //What follows is a hacky way to have two props that set the same state.
    //This was a pre-existing condition that I am replicating.
    //Luckily useEffects seem to be called in order.

    // Called every time selectedLocation prop changes.
    React.useEffect(() => {
        setSelectedLocation(props.selectedLocation);
    }, [props.selectedLocation]);

    // Called once.
    React.useEffect(() => {
        if (props.initiallySelectedLocation) {
            setSelectedLocation(props.initiallySelectedLocation);
        }
    }, []);

    //end hack

    function clearLocationSelection() {
        setLocationFocused(false);
        setSelectedLocation(null);
    }

    React.useEffect(() => {
        if (isResizeObserverSupported) {
            const locationSelector = parentDivRef.current.querySelector(
                '.ia-location-selector'
            );
            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.target === locationSelector) {
                        const locationSelectorWidth = locationSelector.offsetWidth;
                        const grid = locationSelector.querySelector(
                            '#launch-location-select-grid'
                        );
                        const gridWidth = grid.offsetWidth;

                        // We do not want the dropdown list to be narrower than dropdown trigger
                        if (gridWidth < locationSelectorWidth) {
                            grid.style.maxWidth = `${locationSelectorWidth}px`;
                        }
                    }
                }
            });

            if (locationSelector) {
                resizeObserver.observe(locationSelector);
            }

            return () => {
                if (locationSelector) {
                    resizeObserver.unobserve(locationSelector);
                }
            };
        }

        return () => {};
    }, []);

    function anchorGridToLeft(elem) {
        // eslint-disable-next-line no-param-reassign
        elem.style.left = '0';
    }

    function anchorGridToRight(elem, amount) {
        // eslint-disable-next-line no-param-reassign
        elem.style.left = `${amount * -1}px`;
    }

    function handleLeftAnchoring(parentOffsetLeft, parentWidth, gridWidth, grid) {
        const documentAvailableSpace =
            window.innerWidth - (parentOffsetLeft + parentWidth);
        const diffWidth = gridWidth - parentWidth;

        // It means there is not enough room to anchor the dropdown at left
        if (diffWidth > documentAvailableSpace) {
            anchorGridToRight(grid, diffWidth);
        } else {
            anchorGridToLeft(grid);
        }
    }

    function handleRightAnchoring(parentOffsetLeft, parentWidth, gridWidth, grid) {
        const diffWidth = gridWidth - parentWidth;

        // It means we can anchor the dropdown at right
        if (diffWidth < parentOffsetLeft) {
            anchorGridToRight(grid, diffWidth);
        } else {
            anchorGridToLeft(grid);
        }
    }

    function handleGridAnchoring() {
        const { dropdownPlacement } = props;
        const locationSelector = parentDivRef.current.querySelector(
            '.ia-location-selector'
        );
        const locationSelectorCoordinates = locationSelector.getBoundingClientRect();
        const grid = locationSelector.querySelector('#launch-location-select-grid');
        const gridCoordinates = grid.getBoundingClientRect();
        const { width: locationSelectorWidth, left: locationSelectorOffsetLeft } =
            locationSelectorCoordinates;
        const { width: gridWidth } = gridCoordinates;

        if (dropdownPlacement === 'left') {
            handleLeftAnchoring(
                locationSelectorOffsetLeft,
                locationSelectorWidth,
                gridWidth,
                grid
            );
        }

        if (dropdownPlacement === 'right') {
            handleRightAnchoring(
                locationSelectorOffsetLeft,
                locationSelectorWidth,
                gridWidth,
                grid
            );
        }
    }

    React.useEffect(() => {
        if (props.clearFuncHack) {
            props.clearFuncHack(clearLocationSelection);
        }
    }, [props.clearFuncHack]);

    function globalCollapse(evt) {
        let selectorChild = false;
        const stopAts = [
            ...document.getElementsByClassName('launch-location-select-grid'),
        ];
        stopAts.forEach((parent) => {
            selectorChild = selectorChild || isDescendant(parent, evt.target);
        });

        if (!selectorChild) {
            if (!props.closeOnSelect) {
                setLocationFocused(false);
            }
        } else {
            document
                .getElementsByTagName('body')[0]
                .addEventListener('mousedown', globalCollapse, { once: true });
        }
    }

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (parentDivRef.current && !parentDivRef.current.contains(event.target)) {
                setLocationFocused(false);
            }
        }

        function handleKeyDown(event) {
            if (event.key === 'Escape') {
                setLocationFocused(false);
            }
        }

        if (locationFocused) {
            document.addEventListener('click', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);
            document
                .getElementsByTagName('body')[0]
                .addEventListener('mousedown', globalCollapse, { once: true });
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);
        };
    }, [locationFocused]);

    React.useEffect(() => {
        handleGridAnchoring();
    }, [props.dropdownPlacement]);

    function handleSelectorClick(e) {
        e.stopPropagation();

        handleGridAnchoring();

        const event = new CustomEvent('click', { bubbles: true, cancelable: true });
        document.dispatchEvent(event);

        setLocationFocused(!locationFocused);

        if (props.handleSelectorClick) props.handleSelectorClick();
    }

    function handleLocationClick(row, facility) {
        if (props.closeOnSelect) {
            setLocationFocused(false);
        }
        if (props.displaySelectedLocation) {
            setSelectedLocation(row);
        }
        props.locationSelect(row, facility);
    }

    function launchButtonIcon() {
        return locationFocused ? (
            <Icons.IconChevronUp size="md" />
        ) : (
            <Icons.IconChevronDown size="md" />
        );
    }

    let locationButtonTitle = props.placeholder || 'Select Location';
    if (selectedLocation) {
        locationButtonTitle = getLocationDisplayName(selectedLocation);
        if (props.launchButtonRoleText) {
            locationButtonTitle = `${locationButtonTitle}, ${props.launchButtonRoleText}`;
        }
    }
    return (
        <div className="location-picker" ref={parentDivRef}>
            <div className="ia-location-selector">
                <Button
                    appearance={props.launchButtonAppearance}
                    shouldFitContainer
                    className={props.launchButtonClassName}
                    iconAfterElement={launchButtonIcon()}
                    onClick={handleSelectorClick}
                >
                    {locationButtonTitle}
                </Button>
                <LaunchLocationSelect
                    filterID={props.filterID}
                    collapsed={!locationFocused}
                    selectedLocation={selectedLocation}
                    locationSelect={handleLocationClick}
                    allowAllLocationSelect={props.allowAllLocationSelect}
                    showReservableLocations={props.showReservableLocations}
                    selectableLocationTypes={props.selectableLocationTypes}
                    disableConcessionaireHierarchy={props.disableConcessionaireHierarchy}
                    allowHierarchyFillSelection={props.allowHierarchyFillSelection}
                    visibleLocationTypes={props.visibleLocationTypes}
                />
            </div>
        </div>
    );
}

LocationPicker.propTypes = propTypes;
LocationPicker.defaultProps = defaultProps;
