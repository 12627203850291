/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup, ModalActions, StyledModal } from '@fp/sarsaparilla';
import { keepaliveNoThrottle } from 'ui-internal-account/src/actions/keepalive';
import {
    closeAutoLogoutWarning,
    autoLogoutWarningCanceled,
    logOutForInactivity,
} from '../actions/login';

const propTypes = {
    isOpen: PropTypes.bool,
    logoutFn: PropTypes.func,
    logoutInactivityFn: PropTypes.func,
    confirmFn: PropTypes.func,
};

const warningTimeLimit = 60; // seconds
const msToSecond = 1000;
const intervalDurationMs = msToSecond; // 1 second
const inactivityWarningLimitMs = warningTimeLimit * msToSecond;

export function AutoLogoutWarning({
    isOpen = false,
    logoutFn = null,
    logoutInactivityFn = null,
    confirmFn = null,
}) {
    const [timeLeft, setTimeLeft] = useState(warningTimeLimit);

    useEffect(() => {
        const endTime = new Date().getTime() + inactivityWarningLimitMs;

        if (isOpen) {
            // modal was opened
            const interval = setInterval(() => {
                const now = new Date().getTime();
                const newTimeLeft = Math.max(Math.ceil((endTime - now) / msToSecond), 0);

                if (newTimeLeft === 0) {
                    logoutInactivityFn();
                }
                setTimeLeft(newTimeLeft);
            }, intervalDurationMs);

            return () => {
                clearInterval(interval);
                setTimeLeft(warningTimeLimit);
            };
        }
        return () => {};
    }, [isOpen]);

    const stayLoggedIn = () => {
        confirmFn();
    };

    const title = 'Auto Logout in Progress';

    return (
        <StyledModal
            size={'sm'}
            isOpen={isOpen}
            onRequestClose={stayLoggedIn}
            contentLabel={title}
            shouldCloseOnEsc={false}
            shouldShowCloseButton={false}
        >
            <div>
                <h3>{title}</h3>
            </div>
            <div className="modal-body">
                <p>Your session will expire in {timeLeft} seconds.</p>
                <p>
                    Press <b>Continue</b> to remain logged in or Press <b>Log Off</b> to
                    log off.
                </p>
                <p>If you are logged off, any changes will be lost.</p>
            </div>
            <ModalActions>
                <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                    <Button appearance={'tertiary'} onClick={logoutFn}>
                        Log Off
                    </Button>
                    <Button appearance={'primary'} onClick={stayLoggedIn}>
                        Continue
                    </Button>
                </ButtonGroup>
            </ModalActions>
        </StyledModal>
    );
}

const mapStateToProps = (state) => {
    return {
        isOpen: state.login?.warnForAutoLogout,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        confirmFn: () => {
            dispatch(closeAutoLogoutWarning());
            dispatch(keepaliveNoThrottle());
        },
        logoutFn: () => dispatch(autoLogoutWarningCanceled()),
        logoutInactivityFn: () => dispatch(logOutForInactivity()),
    };
};

AutoLogoutWarning.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogoutWarning);
