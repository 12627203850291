/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';

import PageHeader from '../components/PageHeader';
import { creatingUser } from '../actions/updateUser';
import { setTabIndex } from '../actions/userManagement';
import Attestation from './Attestation';

const propTypes = {
    history: TYPES.HISTORY,
    editing: PropTypes.bool,
    creatingUser: PropTypes.func,
    setTabIndex: PropTypes.func,
    selectedTabIndex: PropTypes.number,
};

export class UserManagementHeader extends Component {
    pageHeaderTabs() {
        const tabs = [
            {
                title: 'User List',
                onClick: () => {
                    this.props.setTabIndex(0);
                    this.props.history.push('/internal/account/manage-users/');
                },
            },
            {
                title: 'Create User',
                onClick: () => {
                    this.props.setTabIndex(1);
                    this.props.history.push('/internal/account/create-user');
                    this.props.creatingUser();
                },
            },
        ];

        if (this.props.editing) {
            tabs.push({
                title: 'Edit User',
            });
        }

        return tabs;
    }

    render() {
        return (
            <PageHeader
                title="Internal User Management"
                tabs={this.pageHeaderTabs()}
                selectedTabIndex={this.props.selectedTabIndex}
            >
                {this.props.selectedTabIndex === 0 && <Attestation />}
            </PageHeader>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedTabIndex: state.userManagement.selectedTabIndex,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        creatingUser: () => dispatch(creatingUser()),
        setTabIndex: (index) => dispatch(setTabIndex(index)),
    };
};

UserManagementHeader.propTypes = propTypes;

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserManagementHeader)
);
