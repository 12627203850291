/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Dispatch } from 'redux';
import * as types from '../constants/types';
import { makeAuthorizedRequest } from './login';
import * as globals from '../constants/globals';

export const fetchNotes = (userId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const url = `${globals.API_URL}/user/${userId}/notes`;
            const notesResponse = await makeAuthorizedRequest(url, 'GET', dispatch);
            dispatch({
                type: types.FETCH_USER_NOTES_SUCCESS,
                notes: notesResponse.notes || [],
            });
        } catch (error) {
            dispatch({
                type: types.FETCH_USER_NOTES_FAILED,
                error,
            });
        }
    };
};

export const storeNoteForCreate = (text: string, reason: string) => {
    return (dispatch: Dispatch, getState: () => any) => {
        const state = getState();
        const createdByUserId = state.login.account.user.user_id;
        const createdByName = `${state.login.account.user.first_name} ${state.login.account.user.last_name}`;

        return dispatch({
            type: types.STORE_NOTE_FOR_CREATE,
            note: {
                text,
                reason,
                created_at: new Date(),
                created_by: createdByUserId,
                created_by_name: createdByName,
            },
        });
    };
};

const addNoteFailed = (error: Error) => {
    return {
        type: types.ADD_NOTE_FAILED,
        error,
    };
};

export const addNotesForNewUser = (notes: any[], newUserId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const notesRequests = [];
            const url = `${globals.API_URL}/user/${newUserId}/notes`;

            for (const note of notes) {
                const postData = JSON.stringify(note);

                const request = makeAuthorizedRequest(url, 'POST', dispatch, postData);
                notesRequests.push(request);
            }

            // Add all the notes in parallel
            await Promise.all(notesRequests);
        } catch (error) {
            dispatch(addNoteFailed(error as Error));
        }
    };
};

const noteAddedForEditingUser = () => {
    return {
        type: types.NOTE_ADDED_FOR_EXISTING_USER,
    };
};

export const addNoteForExistingUser = (text: string, reason: string) => {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
        try {
            const state = getState();

            const noteAboutUserId = state.updateUser.accountToSave.user_id;
            const url = `${globals.API_URL}/user/${noteAboutUserId}/notes`;

            const createdByUserId = state.login.account.user.user_id;
            const postData = JSON.stringify({
                text,
                reason,
                created_by: createdByUserId,
            });

            await makeAuthorizedRequest(url, 'POST', dispatch, postData);

            dispatch(noteAddedForEditingUser());
            dispatch(fetchNotes(noteAboutUserId));
        } catch (error) {
            dispatch(addNoteFailed(error as Error));
        }
    };
};
