/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    errorText: PropTypes.string,
};

export default class LineLoader extends React.Component {
    static propTypes = propTypes;

    render() {
        const classes = this.props.errorText
            ? 'line-error rec-notification-error'
            : 'line-loader';
        return <div className={classes}>{this.props.errorText}</div>;
    }
}
