/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { isEqual, isNil } from 'lodash';
import qs from 'query-string';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { confirmEmailChange } from '../actions/confirmEmail';
import Loading from './Loading';
import { makeCompatNavigate } from '../utilities/compatNavigateUtil';
import { navigateLogin, resetSession } from '../actions/login';
import useLoggedInUser from '../hooks/useLoggedInUser';

type ConfirmEmailChangePageActions = {
    confirmEmailChangeAction: (token: string) => void;
    resetSessionAction: () => void;
};

type ConfirmEmailChangePageState = {
    confirmEmail: {
        validated: boolean | null;
        successForChange: boolean | null;
        error: Error | string | null;
        successFor: string | null;
    };
};

type ConfirmEmailChangePageProps = ConfirmEmailChangePageActions &
    ConfirmEmailChangePageState;

function ConfirmEmailChangePage({
    confirmEmail: confirmEmailProp,
    confirmEmailChangeAction,
    resetSessionAction,
}: ConfirmEmailChangePageProps) {
    const history = useHistory();
    const navigate = makeCompatNavigate(history);
    const user = useLoggedInUser();

    useEffect(() => {
        if (!user?.confirmEmailChangeVerified) {
            //need to log in first
            resetSessionAction();
            navigateLogin('/internal/account/confirm-email-change');
        }
    }, [user, resetSessionAction, navigate]);

    useEffect(() => {
        //didn't dispatch the confirm email action yet, dispatching...
        if (
            isEqual(user?.confirmEmailChangeVerified, true) &&
            confirmEmailProp &&
            isNil(confirmEmailProp?.error) &&
            !confirmEmailProp?.successForChange
        ) {
            if (!isNil(user?.email)) {
                const queryParams = qs.parse(history.location.search);
                const token = queryParams.token;

                confirmEmailChangeAction(token);
            }
        }
    }, [confirmEmailProp, user, confirmEmailChangeAction, resetSessionAction, history]);

    useEffect(() => {
        //logged-in and change successful
        if (
            confirmEmailProp &&
            isEqual(user?.confirmEmailChangeVerified, true) &&
            isNil(confirmEmailProp?.error) &&
            isEqual(confirmEmailProp?.successForChange, true)
        ) {
            navigate('/internal/account/hub');
        }
    }, [user, confirmEmailProp, resetSessionAction, navigate]);

    return <Loading />;
}

const mapStateToProps = (state: ConfirmEmailChangePageState) => {
    return {
        confirmEmail: state.confirmEmail,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            confirmEmailChangeAction: confirmEmailChange,
            resetSessionAction: resetSession,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailChangePage);
