/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HistoryV4, Location } from '../../../../shared-ui/types/HistoryV4';
import { AppDispatch } from '../../../dev/store';
import { authorizeCallback } from '../../actions/oidc';
import Loading from '../../containers/Loading';

type OidcCallbackProps = {
    history: HistoryV4;
    location: Location;
};

export default function OidcCallback(props: OidcCallbackProps) {
    const dispatch = useDispatch<AppDispatch>();
    const sp = new URLSearchParams(props.location.search);

    useEffect(() => {
        dispatch(
            authorizeCallback(
                sp.get('code') as string,
                sp.get('state') as string,
                props.history
            )
        );
    });

    return <Loading />;
}
