/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { INVENTORY_VENUE_RESERVATIONS } from '../../../ui-activitypass/src/constants';
import {
    FIELD_SALES_SALES_CHANNEL,
    CALL_CENTER_SALES_CHANNEL,
    INVENTORY_CAMPING,
    INVENTORY_PASS,
    INVENTORY_ACTIVITY_PASS,
    INVENTORY_RENTAL,
    INVENTORY_PERMIT,
    INVENTORY_POS,
    INVENTORY_TICKET,
    INVENTORY_TIMED_ENTRY,
    INVENTORY_VEHICLE_PERMIT,
} from './types';

/* eslint-disable no-template-curly-in-string */

export const TICKET_LOCATION_TYPE = 'Ticket Facility';
export const TIMED_ENTRY_LOCATION_TYPE = 'Timed Entry';
export const CAMPING_LOCATION_TYPE = 'Campground';
export const RENTALS_LOCATION_TYPE = 'Rentals';
export const PERMIT_LOCATION_TYPE = 'Permit';
export const ACTIVITY_PASS_TYPE = 'activitypass';
export const VEHICLE_PERMIT_LOCATION_TYPE = 'Vehicle Permit';
export const VENUE_RESERVATIONS_TYPE = 'Venue Reservations';

export const TICKET_TAB = 'ticket';
export const TIMED_ENTRY_TAB = 'timed-entry';
export const CAMPING_TAB = 'camping';
export const RENTALS_TAB = 'rentals';
export const PERMIT_TAB = 'permit';
export const VEHICLE_PERMIT_TAB = 'vehiclepermit';
export const PASS_TAB = 'pass';
export const POS_TAB = 'pos';
export const ACTIVITY_PASS_TAB = 'Activity Pass';
export const LOTTERY_PERMIT_TAB = 'lottery_permit';
export const VENUE_RESERVATIONS_TAB = 'venues';

export const TICKET_TAB_LABEL = 'Tickets';
export const TIMED_ENTRY_TAB_LABEL = 'Tickets';
export const CAMPING_TAB_LABEL = 'Campsites';
export const RENTALS_TAB_LABEL = 'Rentals';
export const PERMIT_TAB_LABEL = 'Permits';
export const VEHICLE_PERMIT_TAB_LABEL = 'Vehicle Permits';
export const PASS_TAB_LABEL = 'Interagency Passes';
export const POS_TAB_LABEL = 'POS Items';
export const ACTIVITY_PASS_TAB_LABEL = 'Activity Passes';
export const VENUE_RESERVATIONS_TAB_LABEL = 'Venue Reservations';

export const TICKET_TAB_ARIA_LABEL = 'Tickets';
export const TIMED_ENTRY_TAB_ARIA_LABEL = 'Tickets';
export const CAMPING_TAB_ARIA_LABEL = 'Campsites';
export const RENTALS_TAB_ARIA_LABEL = 'Rentals';
export const PERMIT_TAB_ARIA_LABEL = 'Permits';
export const VEHICLE_PERMIT_TAB_ARIA_LABEL = 'Vehicle Permits';
export const PASS_TAB_ARIA_LABEL = 'Inter Agency Passes';
export const ACTIVITY_PASS_TAB_ARIA_LABEL = 'Activity Passes';
export const POS_TAB_ARIA_LABEL = 'Point of Sales Items';
export const VENUE_RESERVATIONS_TAB_ARIA_LABEL = 'Venue Reservations';

// TODO update with true param urls
export const SALES_URL = '/internal/sales/${sales_type}';
export const TICKET_TAB_URL = SALES_URL.concat(`/${TICKET_TAB}`).concat(
    '/${location_id}'
);
export const TIMED_ENTRY_TAB_URL = SALES_URL.concat(`/${TIMED_ENTRY_TAB}`).concat(
    '/${location_id}'
);
export const CAMPING_TAB_URL = SALES_URL.concat(`/${CAMPING_TAB}`).concat(
    '/${location_id}'
);
export const RENTALS_TAB_URL = SALES_URL.concat(`/${RENTALS_TAB}`).concat(
    '/camping/${location_id}'
);
export const PERMIT_TAB_URL = SALES_URL.concat(`/${PERMIT_TAB}`).concat(
    '/${location_id}'
);
export const PASS_TAB_URL = SALES_URL.concat(`/${PASS_TAB}`).concat(
    '/${location_type}/${location_id}'
);
export const POS_TAB_URL = SALES_URL.concat(`/${POS_TAB}`).concat(
    '/${location_type}/${location_id}'
);
export const ACTIVITY_PASS_TAB_URL = SALES_URL.concat(`/${ACTIVITY_PASS_TYPE}`).concat(
    '/${location_id}'
);
export const VEHICLE_PERMIT_TAB_URL = SALES_URL.concat(`/${VEHICLE_PERMIT_TAB}`).concat(
    '/${location_id}'
);
export const VEHICLE_PERMIT_TAB_EDIT_URL = SALES_URL.concat(`/${VEHICLE_PERMIT_TAB}`)
    .concat('/${location_id}')
    .concat('/${reservation_id}');
export const VENUE_RESERVATIONS_TAB_URL = SALES_URL.concat(
    `/${VENUE_RESERVATIONS_TAB}`
).concat('/${location_id}');

export const FIELD_SALES_URL_IDENTIFIER = 'field';
export const CALL_CENTER_URL_IDENTIFIER = 'call-center';

export const salesUrlIdentifierLookup = {
    [FIELD_SALES_SALES_CHANNEL]: FIELD_SALES_URL_IDENTIFIER,
    [CALL_CENTER_SALES_CHANNEL]: CALL_CENTER_URL_IDENTIFIER,
};

export const tabOrder = [
    TICKET_LOCATION_TYPE,
    TIMED_ENTRY_LOCATION_TYPE,
    CAMPING_LOCATION_TYPE,
    PERMIT_LOCATION_TYPE,
    VEHICLE_PERMIT_LOCATION_TYPE,
    RENTALS_LOCATION_TYPE,
    ACTIVITY_PASS_TYPE,
    VENUE_RESERVATIONS_TYPE,
    PASS_TAB,
    POS_TAB,
];

export const tabLookup = {
    [TICKET_LOCATION_TYPE]: {
        tab: TICKET_TAB,
        label: TICKET_TAB_LABEL,
        aria_label: TICKET_TAB_ARIA_LABEL,
        url: TICKET_TAB_URL,
    },
    [TIMED_ENTRY_LOCATION_TYPE]: {
        tab: TIMED_ENTRY_TAB,
        label: TIMED_ENTRY_TAB_LABEL,
        aria_label: TIMED_ENTRY_TAB_ARIA_LABEL,
        url: TIMED_ENTRY_TAB_URL,
    },
    [CAMPING_LOCATION_TYPE]: {
        tab: CAMPING_TAB,
        label: CAMPING_TAB_LABEL,
        aria_label: CAMPING_TAB_ARIA_LABEL,
        url: CAMPING_TAB_URL,
        editUrl: CAMPING_TAB_URL.concat('/orderdetails'),
    },
    [PERMIT_LOCATION_TYPE]: {
        tab: PERMIT_TAB,
        label: PERMIT_TAB_LABEL,
        aria_label: PERMIT_TAB_ARIA_LABEL,
        url: PERMIT_TAB_URL,
    },
    [VEHICLE_PERMIT_LOCATION_TYPE]: {
        tab: VEHICLE_PERMIT_TAB,
        label: VEHICLE_PERMIT_TAB_LABEL,
        aria_label: VEHICLE_PERMIT_TAB_ARIA_LABEL,
        url: VEHICLE_PERMIT_TAB_URL,
        editUrl: VEHICLE_PERMIT_TAB_EDIT_URL,
    },
    [RENTALS_LOCATION_TYPE]: {
        tab: RENTALS_TAB,
        label: RENTALS_TAB_LABEL,
        aria_label: RENTALS_TAB_ARIA_LABEL,
        url: RENTALS_TAB_URL,
        editUrl: RENTALS_TAB_URL.concat('/orderdetails'),
    },
    [ACTIVITY_PASS_TYPE]: {
        tab: ACTIVITY_PASS_TAB,
        label: ACTIVITY_PASS_TAB_LABEL,
        aria_label: ACTIVITY_PASS_TAB_ARIA_LABEL,
        url: ACTIVITY_PASS_TAB_URL,
    },
    [PASS_TAB]: {
        tab: PASS_TAB,
        label: PASS_TAB_LABEL,
        aria_label: PASS_TAB_ARIA_LABEL,
        url: PASS_TAB_URL,
    },
    [POS_TAB]: {
        tab: POS_TAB,
        label: POS_TAB_LABEL,
        aria_label: POS_TAB_ARIA_LABEL,
        url: POS_TAB_URL,
    },
    [VENUE_RESERVATIONS_TYPE]: {
        tab: VENUE_RESERVATIONS_TAB,
        label: VENUE_RESERVATIONS_TAB_LABEL,
        aria_label: VENUE_RESERVATIONS_TAB_ARIA_LABEL,
        url: VENUE_RESERVATIONS_TAB_URL,
        editUrl: VENUE_RESERVATIONS_TAB_URL.concat('/order/${reservation_id}'),
    },
};

export const inventoryTypeLookup = {
    [INVENTORY_TICKET]: TICKET_LOCATION_TYPE,
    [INVENTORY_TIMED_ENTRY]: TIMED_ENTRY_LOCATION_TYPE,
    [INVENTORY_CAMPING]: CAMPING_LOCATION_TYPE,
    [INVENTORY_RENTAL]: RENTALS_LOCATION_TYPE,
    [INVENTORY_PERMIT]: PERMIT_LOCATION_TYPE,
    [INVENTORY_VEHICLE_PERMIT]: VEHICLE_PERMIT_LOCATION_TYPE,
    [INVENTORY_PASS]: PASS_TAB,
    [INVENTORY_ACTIVITY_PASS]: ACTIVITY_PASS_TAB,
    [INVENTORY_POS]: POS_TAB,
    [INVENTORY_VENUE_RESERVATIONS]: VENUE_RESERVATIONS_TYPE,
};
