/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    manualKey: null,
    qrCode: null,
    backupCodes: [],
    generateMfaTotpError: false,
    patchMfaOptOutError: false,
    validateMfaFactorError: false,
    createMfaBackupCodesError: false,
    error: null,
};

const mfa = (state = initialState, action) => {
    switch (action.type) {
        case types.GENERATE_MFA_TOTP_SUCCESSFUL:
            return {
                ...state,
                manualKey: action.manualKey,
                qrCode: action.qrCode,
                generateMachineApiKeyError: false,
                error: null,
            };

        case types.GENERATE_MFA_TOTP_FAILED:
            return {
                ...state,
                manualKey: null,
                qrCode: null,
                generateMfaTotpError: true,
                error: action.error,
            };

        case types.GENERATE_MFA_TOTP_CLEAR:
            return {
                ...initialState,
            };

        case types.PATCH_MFA_OPT_OUT_SUCCESSFUL:
            return {
                ...state,
                patchMfaOptOutError: false,
                error: null,
            };

        case types.PATCH_MFA_OPT_OUT_FAILED:
            return {
                ...state,
                patchMfaOptOutError: true,
                error: action.error,
            };

        case types.VALIDATE_MFA_FACTOR_SUCCESSFUL:
            return {
                ...state,
                validateMfaFactorError: false,
                error: null,
            };

        case types.VALIDATE_MFA_FACTOR_FAILED:
            return {
                ...state,
                validateMfaFactorError: true,
                error: action.error,
            };

        // TODO: I don't like the codes being stored in redux. We should refactor to clean that up
        case types.CREATE_MFA_BACKUP_CODES_SUCCESSFUL:
            return {
                ...state,
                createMfaBackupCodesError: false,
                backupCodes: action.backupCodes,
            };

        case types.CREATE_MFA_BACKUP_CODES_FAILED:
            return {
                ...state,
                createMfaBackupCodesError: true,
                backupCodes: [],
                error: action.error,
            };
        case types.LOGGED_OUT:
            return initialState;

        default:
            return state;
    }
};

export default mfa;
