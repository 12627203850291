/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Icons, Spacer, TextFieldStateless } from 'sarsaparilla';
import { doBuildTreeData } from '../utilities/locationPickerTree';
import {
    computeLocationStateCode,
    displayStringForLocationType,
    getLocationDisplayName,
    ROOT,
} from '../utilities/locations';
import UserLocationTable from '../components/userLocation/UserLocationTable';
import HighlightCell from '../components/tableUtil/HighlightCell';
import useLocationDataSource from '../hooks/useLocationDataSource';
import useIsLoggedInUserAConcessionaire from '../hooks/useIsLoggedInUserAConcessionaire';
import useLoggedInUser from '../hooks/useLoggedInUser';

const propTypes = {
    assignLocation: PropTypes.func,
    useConcessionaireHierarchy: PropTypes.bool,
};

export default function UserLocationSelection({
    assignLocation,
    useConcessionaireHierarchy,
}) {
    const isUserConcessionaire = useIsLoggedInUserAConcessionaire();
    const loggedInUser = useLoggedInUser();
    const displayConcessionaireStyle =
        useConcessionaireHierarchy &&
        isUserConcessionaire &&
        loggedInUser?.concessionaires?.length === 1;

    const useFullHierarchy = isUserConcessionaire && useConcessionaireHierarchy;
    const { dataSource, searchValue, setSearchValue, fetchChildren, loading } =
        useLocationDataSource({
            useFullHierarchy,
        });

    const onLocationExpandWrapper = (location) => {
        if (!location.isHierarchyFill) {
            fetchChildren(location);
        }
    };

    const assignLocationWrapper = (location) => {
        assignLocation(location);
    };

    const tree = useMemo(() => {
        const root = doBuildTreeData(dataSource, 10, false, false);
        if (
            root?.length > 0 &&
            root[0].location_type === ROOT &&
            displayConcessionaireStyle
        ) {
            root[0].location_description =
                loggedInUser.concessionaires[0].concessionaire_name;
        }
        return root;
    }, [loggedInUser, displayConcessionaireStyle, dataSource]);

    const columns = useMemo(
        () => [
            {
                Header: 'Location',
                accessor: (row) => getLocationDisplayName(row, true),
                id: 'location',
                Cell: ({ value, state, row }) =>
                    row.isExpanded ? (
                        value
                    ) : (
                        <Button
                            appearance="link"
                            onClick={() => assignLocationWrapper(row.original)}
                        >
                            <HighlightCell value={value} state={state} />
                        </Button>
                    ),
            },
            {
                Header: 'Level',
                accessor: (row) => displayStringForLocationType(row.location_type),
                Cell: HighlightCell,
                disableGlobalFilter: true,
                id: 'level',
            },
            {
                Header: 'Facility ID',
                accessor: 'location_id',
                Cell: HighlightCell,
            },
            {
                Header: 'State',
                accessor: computeLocationStateCode,
                Cell: HighlightCell,
                id: 'state',
            },
            {
                Header: 'Agency',
                accessor: 'location_agency_name',
                Cell: HighlightCell,
            },
        ],
        []
    );

    return (
        <div>
            <TextFieldStateless
                id="-text-field"
                isLabelVisible={false}
                placeholder="Search Locations..."
                hasClearButton
                handleClearButtonClick={() => setSearchValue('')}
                iconElement={<Icons.IconSearch />}
                autoComplete="off"
                label="Location Search Input"
                value={searchValue || ''}
                onChange={({ target: { value } }) => setSearchValue(value)}
            />
            <Spacer size="sm" />
            <UserLocationTable
                data={tree}
                columns={columns}
                onExpand={onLocationExpandWrapper}
                apiSearchTerm={searchValue}
                loading={loading}
            />
        </div>
    );
}

UserLocationSelection.propTypes = propTypes;
