/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

export const setCooperator = (cooperator) => {
    return {
        cooperator,
        type: types.SET_MANAGED_COOPERATOR,
    };
};

export const setEmployee = (employee) => {
    return {
        employee,
        type: types.SET_COOPERATOR_EMPLOYEE,
    };
};
