/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * ContactUsFAQ.jsx
 * Created by Destin Frasier on 08/04/20
 */

import React from 'react';
import { Heading, Button } from 'sarsaparilla';

const faqLinks = [
    {
        articleId: 'ed3d4396db0988501bd870808c9619f1',
        title: `How Can I Reset My Password for The ${process.env.SITE_NAME} Hub?`,
    },
    {
        articleId: 'e27e8a1edb61abc0c571740d0f96194c',
        title: 'Frequently Asked Questions (FAQs)',
    },
];

export default class ContactUsFAQ extends React.PureComponent {
    render() {
        return (
            <>
                <Heading headingLevel={2} appearance="h5">
                    Top Help Center Articles
                </Heading>
                <ul className="ia-contact-faq">
                    {faqLinks.map((faqLink) => (
                        <li key={faqLink.articleId}>
                            <a
                                href={`${process.env.SN_KB_INT_URL}?id=kb_article&sys_id=${faqLink.articleId}`}
                            >
                                {faqLink.title}
                            </a>
                        </li>
                    ))}
                </ul>

                <Button
                    shouldFitContainer
                    href={process.env.SN_SYSTEM_UPDATES_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    System Updates
                </Button>
            </>
        );
    }
}
