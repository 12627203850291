/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import { Alert } from '@fp/sarsaparilla';
import { isNil } from 'lodash';

type ConfirmEmailAlertProps = {
    show: boolean;
    newConfirmFlow: boolean;
    setShow: () => void;
};

export default function ConfirmEmailAlert({
    newConfirmFlow,
    show,
    setShow,
}: ConfirmEmailAlertProps) {
    if (!show || isNil(newConfirmFlow)) {
        return null;
    }

    if (newConfirmFlow === true) {
        return (
            <div className="confirm-email-change">
                <Alert
                    type="info"
                    heading="Confirm Change of Email Address"
                    showCloseButton
                    onCloseButtonClick={setShow}
                >
                    {' '}
                    To complete your email change for your account, please sign in using
                    your current email and password to validate your identity.
                    <br />
                    If you have Multi-Factor Authentication (MFA) enabled, you will also
                    need to complete an MFA challenge.
                </Alert>
            </div>
        );
    }

    return (
        <div className="confirm-email-change">
            <Alert
                type="info"
                heading="Email Address Confirmed"
                showCloseButton
                onCloseButtonClick={setShow}
            >
                {' '}
                Email change confirmed successfully.
            </Alert>
        </div>
    );
}
