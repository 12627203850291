/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner, StyledModal } from 'sarsaparilla';

const propTypes = {
    isOpen: PropTypes.bool,
    message: PropTypes.string,
};

class Loading extends React.Component {
    message(message) {
        if (!message) {
            return null;
        }

        return <div id="loading-message">{message}</div>;
    }

    render() {
        return (
            <StyledModal
                size="sm"
                isOpen={this.props.isOpen}
                shouldShowCloseButton={false}
                heading={this.message(this.props.message)}
                shouldActionsStickInMobile
            >
                <Spinner size={100} />
            </StyledModal>
        );
    }
}

Loading.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        message: state.loading?.message,
        isOpen: state.loading?.display,
    };
};

export default connect(mapStateToProps)(Loading);
