/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    AlertsNotification,
    SkipNavContent,
    SkipNavLink,
    ToastContainer,
    TYPES,
} from '@fp/sarsaparilla';

import AutoLogoutWarning from './AutoLogoutWarning';
import Loading from './Loading';
import { logOut, navigateLogin } from '../actions/login';
import { getDomainsForLocation } from '../actions/fetchChildLocations';
import HeaderRibbon from '../components/HeaderRibbon';
import FooterRibbon from '../components/FooterRibbon';
import ActivityPing from '../components/ActivityPing';
import { parseUrlForLocation } from '../utilities/locations';
import SiteWideAlert from '../../../ui-communication/src/components/SiteWideAlert';
import { ClosedSiteWideBannerMap } from '../constants/types';

const propTypes = {
    history: TYPES.HISTORY,
    alerts: PropTypes.arrayOf(PropTypes.object),
    closedSiteWideIDs: PropTypes.object,
    loggedInUser: PropTypes.object,
    selectedRole: PropTypes.object,
    logOut: PropTypes.func,
    navigateLogin: PropTypes.func,
    children: PropTypes.node,
    domainsForLocationId: PropTypes.object,
    getDomainsForLocation: PropTypes.func,
};

export class SiteWrapper extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.doLogOut = this.doLogOut.bind(this);
    }

    componentDidMount() {
        const location = parseUrlForLocation();
        if (location) {
            if (this.props.getDomainsForLocation) {
                this.props.getDomainsForLocation(location, this.props.history);
            }
        }
    }

    handleLocationSelectorClick(e) {
        if (this._locationPicker) {
            this._locationPicker.handleSelectorClick(e);
        }
    }

    doLogOut() {
        this.props.logOut();
    }

    renderHeaderRibbon() {
        let role = null;
        if (this.props.loggedInUser) {
            role = this.props.selectedRole
                ? this.props.selectedRole
                : this.props.loggedInUser.roles[0];
        }

        let selectedLocation = null;
        if (this.props.selectedRole) {
            selectedLocation = this.props.selectedRole.location;
        } else if (
            this.props.loggedInUser &&
            this.props.loggedInUser.roles.length === 1
        ) {
            selectedLocation = this.props.loggedInUser.roles[0].location;
        }

        const doNav = window.location.pathname !== '/internal/account/hub';

        return (
            <HeaderRibbon
                history={this.props.history}
                user={this.props.loggedInUser}
                selectedRole={role}
                selectedLocation={selectedLocation}
                logout={this.doLogOut}
                navigateLogin={doNav ? this.props.navigateLogin : null}
                domainsForLocationId={this.props.domainsForLocationId}
                getDomainsForLocation={this.props.getDomainsForLocation}
                handleLocationSelectorClick={this.handleLocationSelectorClick.bind(this)}
            />
        );
    }

    render() {
        const classes = classNames({
            'page-wrapper': true,
            wrapper: true,
        });

        const siteWideAlerts = [];
        const regularAlerts = [];
        const storageClosedSiteWideIDs = JSON.parse(
            window.localStorage.getItem(ClosedSiteWideBannerMap) || '{}'
        );

        this.props.alerts?.forEach((alert) => {
            if (alert.location_id === '1') {
                if (!storageClosedSiteWideIDs || !storageClosedSiteWideIDs[alert.id])
                    siteWideAlerts.push(alert);
            } else regularAlerts.push(alert);
        });

        return (
            <div id="SiteWrapper" className={classes}>
                <SkipNavLink />
                <AutoLogoutWarning />
                <Loading />
                <ToastContainer />
                <ActivityPing />

                {this.renderHeaderRibbon()}

                <SiteWideAlert
                    alerts={siteWideAlerts}
                    closedSiteWideIDs={this.props.closedSiteWideIDs}
                />
                <AlertsNotification alerts={regularAlerts} />

                <SkipNavContent>
                    <div className="site-body wrapper">{this.props.children}</div>
                </SkipNavContent>

                <FooterRibbon />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const loginDomains = state.login?.account?.domains_by_location_id ?? {};

    return {
        loggedInUser: state.login?.user,
        alerts: state.alertsNotification?.alerts,
        closedSiteWideIDs: state.alertsNotification?.closedSiteWideIDs ?? {},
        selectedRole: state.selectedLocationRole?.role,
        roleSelection: state.selectedLocationRole?.role,
        domainsForLocationId:
            state.fetchChildLocations?.selected_domains_by_location_id ?? loginDomains,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: () => {
            dispatch(logOut());
        },
        navigateLogin: () => {
            navigateLogin('/internal/account/hub');
        },
        getDomainsForLocation: (location, history) =>
            dispatch(getDomainsForLocation(location, history)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteWrapper));
