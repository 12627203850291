/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// Used but never defined
export const GET_CONCESSIONAIRES_FAILED: undefined = undefined;

export const CONCURRENT_SESSION_ERROR: string = 'CONCURRENT_SESSION_EXISTS';
export const CANNOT_REUSE_PASSWORD: string = 'CANNOT_REUSE_PASSWORD';
export const PASSWORD_RESET_TOO_RECENTLY: string = 'PASSWORD_RESET_TOO_RECENTLY';

export const PASSWORD_TOO_SHORT: string = 'PASSWORD_TOO_SHORT';
export const PASSWORD_REQUIRES_NUMBER: string = 'PASSWORD_REQUIRES_NUMBER';
export const PASSWORD_REQUIRES_UPPER_CASE: string = 'PASSWORD_REQUIRES_UPPER_CASE';
export const PASSWORD_REQUIRES_LOWER_CASE: string = 'PASSWORD_REQUIRES_LOWER_CASE';
export const PASSWORD_REQUIRES_SPECIAL_CHARACTER: string =
    'PASSWORD_REQUIRES_SPECIAL_CHARACTER';
export const PASSWORDS_DO_NOT_MATCH: string = 'PASSWORDS_DO_NOT_MATCH';

export const MUST_ASSIGN_ONE_LOCATION: string = 'MUST_ASSIGN_ONE_LOCATION';
export const MUST_ASSIGN_ROLE_AT_EACH_LOCATION: string =
    'MUST_ASSIGN_ROLE_AT_EACH_LOCATION';
export const MALFORMED_TOKEN: string = 'MALFORMED_TOKEN';

export const NOT_AUTHORIZED: string = 'NOT_AUTHORIZED';
export const MFA_REJECTED: string = 'MFA_REJECTED';
export const SERVER_ERROR: string = 'LOGGED_SERVER_ERROR';

export const LOCKED_USER_REQUIRES_NOTE: string = 'LOCKED_USER_REQUIRES_NOTE';
export const USER_WITH_EMAIL_ALREADY_EXISTS: string = 'USER_WITH_EMAIL_ALREADY_EXISTS';
export const CONFIRMATION_EMAIL_NOT_SENT: string = 'CONFIRMATION_EMAIL_NOT_SENT';
export const INVALID_PHONE_NUMBER: string = 'INVALID_PHONE_NUMBER';
export const EMAIL_CHANGE_NOT_CONFIRMED: string = 'EMAIL_CHANGE_NOT_CONFIRMED';
export const EMAIL_NOT_CONFIRMED: string = 'EMAIL_NOT_CONFIRMED';
export const TOO_MANY_LOGIN_ATTEMPTS: string = 'TOO_MANY_LOGIN_ATTEMPTS';
export const ACCOUNT_IS_LOCKED: string = 'ACCOUNT_IS_LOCKED';
export const RESEND_CONFIRMATION_ALREADY_SENT: string =
    'RESEND_CONFIRMATION_ALREADY_SENT';
export const DISABLE_MACHINE_API_KEY_FAILED: string = 'DISABLE_MACHINE_API_KEY_FAILED';
export const GENERATE_MACHINE_API_KEY_FAILED: string = 'GENERATE_MACHINE_API_KEY_FAILED';
export const GET_MACHINE_API_KEYS_FAILED: string = 'GET_MACHINE_API_KEYS_FAILED';
export const GET_MACHINE_API_PERMISSIONS_FAILED: string =
    'GET_MACHINE_API_PERMISSIONS_FAILED';
export const UPDATE_MACHINE_API_PERMISSIONS_FAILED: string =
    'UPDATE_MACHINE_API_PERMISSIONS_FAILED';
export const GET_USER_FAILED: string = 'GET_USER_FAILED';
export const GET_CONCESSIONAIRE_FAILED: string = 'GET_CONCESSIONAIRE_FAILED';
export const GET_CONCESSIONAIRE_PERMITS_FAILED: string =
    'GET_CONCESSIONAIRE_PERMITS_FAILED';
export const MAY_ASSIGN_ONLY_ONE_CONCESSIONAIRE: string =
    'MAY_ASSIGN_ONLY_ONE_CONCESSIONAIRE';
export const MUST_ASSIGN_ONE_CONCESSIONAIRE: string = 'MUST_ASSIGN_ONE_CONCESSIONAIRE';

export const GET_COOPERATORS_FAILED: string = 'GET_COOPERATORS_FAILED';
export const GET_COOPERATOR_EMPLOYEES_FAILED: string = 'GET_COOPERATOR_EMPLOYEES_FAILED';
export const SAVE_COOPERATOR_FAILED: string = 'SAVE_COOPERATOR_FAILED';
export const SAVE_COOPERATORS_FAILED: string = 'SAVE_COOPERATORS_FAILED';
export const SAVE_COOPERATOR_EMPLOYEES_FAILED: string =
    'SAVE_COOPERATOR_EMPLOYEES_FAILED';
export const DELETE_COOPERATORS_FAILED: string = 'DELETE_COOPERATORS_FAILED';
export const DELETE_COOPERATOR_EMPLOYEES_FAILED: string =
    'DELETE_COOPERATOR_EMPLOYEES_FAILED';
export const REQUIRE_UNIQUE_COOPERATOR_NAME: string = 'REQUIRE_UNIQUE_COOPERATOR_NAME';

export const USER_ACTION_FORBIDDEN: string = 'USER_ACTION_FORBIDDEN';
export const NON_SUBORDINATE_ROLE: string = 'NON_SUBORDINATE_ROLE';
export const EDITABLE_NOTES_ERROR: string = 'EDITABLE_NOTES_ERROR';
export const NO_ROLES_TO_UPDATE: string = 'NO_ROLES_TO_UPDATE';

export const GENERATE_MFA_TOTP_FAILED: string = 'GENERATE_MFA_TOTP_FAILED';
export const PATCH_MFA_OPT_OUT_FAILED: string = 'PATCH_MFA_OPT_OUT_FAILED';
export const VALIDATE_MFA_FACTOR_FAILED: string = 'VALIDATE_MFA_FACTOR_FAILED';
export const CREATE_MFA_BACKUP_CODES_FAILED: string = 'CREATE_MFA_BACKUP_CODES_FAILED';
