/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Checkbox } from 'sarsaparilla';

export default function RowSelectCheckBox({ onChange, checked, title, id }) {
    return (
        <Checkbox
            id={id}
            onChange={onChange}
            isChecked={checked}
            label={title}
            isLabelVisible={false}
        />
    );
}
