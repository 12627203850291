/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    userResults: [],
    userResultsLoaded: false,
    userResultsReload: false,
    error: null,
};

export default function fetchUsers(state = initialState, action) {
    const st = { ...state };

    switch (action.type) {
        case types.FETCH_USERS_CLEAR:
            st.userResults = [];
            st.userResultsLoaded = false;
            st.userResultsReload = true;
            st.error = null;
            break;

        case types.FETCH_USERS_SUCCESS:
            st.userResults = action.payload.users || [];
            st.userResultsLoaded = action.loaded;
            st.userResultsReload = true;
            st.error = null;
            break;

        case types.FETCH_USERS_FAILED:
            st.userResults = [];
            st.userResultsLoaded = false;
            st.error = action.error;
            break;

        case types.USER_DELETED:
            st.userResults = state.userResults.filter(
                (user) => user.user_id !== action.deletedUser.user_id
            );
            break;

        case types.LOGGED_OUT:
            return initialState;

        default:
            break;
    }

    return st;
}
