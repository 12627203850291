/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    //Adding this back in for backwards compatibility.
    // If pages with the old code are navigated to from a page with the new code
    // having state.fetchChildLocations.datasource be undefined causes an error.
    dataSource: {
        data: [],
        loaded: false,
        search: '',
    },
    //End backward compatibility.
    selected_domains_by_location_id: null,
    error: null,
    spin: false,
};

const fetchChildLocationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_DOMAINS_FOR_LOCATION_SUCCESS: {
            const obj = { ...state, selected_domains_by_location_id: {}, error: null };

            obj.selected_domains_by_location_id = action.locDomains;
            return obj;
        }
        case types.FETCH_DOMAINS_FOR_LOCATION_ERROR:
            return { ...state, error: action.error };
        default:
            return state;
    }
};

export default fetchChildLocationsReducer;
