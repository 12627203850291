/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ActionErrorDisplay from 'ui-internal-account/src/components/ActionErrorDisplay';
import { errorForUpdateUser } from 'ui-internal-account/src/utilities/errorMessages';
import {
    TYPES,
    EmailField,
    PhoneNumberField,
    TextField,
    Button,
} from '@fp/sarsaparilla';
import useLoggedInUser from 'ui-internal-account/src/hooks/useLoggedInUser';
import { SUPER_USER, FINANCE_ADMIN } from 'ui-internal-account/src/utilities/roles';

const propTypes = {
    history: TYPES.HISTORY,
    concessionaire: PropTypes.any.isRequired,
    saveConcessionaire: PropTypes.func,
    error: PropTypes.any,
};

export default function EditConcessionaire({
    history,
    concessionaire,
    saveConcessionaire,
    error,
}) {
    const [altConcName, setAltConcName] = useState(concessionaire.concessionaire_name);
    const [altConcPrimaryContactName, setAltConcPrimaryContactName] = useState(
        concessionaire.primary_contact_name
    );
    const [altConcPrimaryContactPhone, setAltConcPrimaryContactPhone] = useState(
        concessionaire.primary_contact_phone
    );
    const [altConcPrimaryContactEmail, setAltConcPrimaryContactEmail] = useState(
        concessionaire.primary_contact_email
    );
    const [altConcSecondaryContactName, setAltConcSecondaryContactName] = useState(
        concessionaire.secondary_contact_name
    );
    const [altConcSecondaryContactPhone, setAltConcSecondaryContactPhone] = useState(
        concessionaire.secondary_contact_phone
    );
    const [altConcSecondaryContactEmail, setAltConcSecondaryContactEmail] = useState(
        concessionaire.secondary_contact_email
    );

    const user = useLoggedInUser();
    const canEditConcessionaireName = useMemo(() => {
        return user.roles.reduce(
            (acc, role) =>
                acc || role.role_type === SUPER_USER || role.role_type === FINANCE_ADMIN,
            false
        );
    }, [user]);

    const onCancelNav = () => {
        const url = concessionaire.concessionaire_id
            ? '/internal/account/concessionaire-details'
            : '/internal/account/manage-concessionaires';
        history.push(url);
    };

    const onAdd = () => {
        const updatedConc = {
            ...concessionaire,
            concessionaire_name: altConcName,
            primary_contact_name: altConcPrimaryContactName,
            primary_contact_phone: altConcPrimaryContactPhone,
            primary_contact_email: altConcPrimaryContactEmail,
            secondary_contact_name: altConcSecondaryContactName,
            secondary_contact_phone: altConcSecondaryContactPhone,
            secondary_contact_email: altConcSecondaryContactEmail,
        };

        saveConcessionaire(updatedConc, true);
    };

    return (
        <div className="concessionaire-details-wrapper">
            <ActionErrorDisplay error={error} errorStringMapping={errorForUpdateUser} />

            <div className="concessionaires-body">
                <div className="concessionaire-mod-inputs">
                    <h2 className="concessionaire-component-title h6">
                        Concessionaire Information
                    </h2>
                    <TextField
                        id="concessionaire_name_input"
                        label="Concessionaire Name"
                        className="concessionaire-mod-input-block rec-form-item-wrap"
                        value={altConcName}
                        onChange={(e) => setAltConcName(e.target.value)}
                        isRequired={canEditConcessionaireName}
                        isDisabled={!canEditConcessionaireName}
                    />
                </div>
                <div className="concessionaire-mod-inputs">
                    <h2 className="concessionaire-component-title h6">
                        Contact Information
                    </h2>
                    <div className="concessionaire-mod-inputs-group">
                        <TextField
                            id="primary_contact_name_input"
                            label="Primary Contact Name"
                            className="concessionaire-mod-input-block"
                            value={altConcPrimaryContactName}
                            onChange={(e) => setAltConcPrimaryContactName(e.target.value)}
                        />
                        <PhoneNumberField
                            id="primary_contact_phone_input"
                            label="Primary Contact Phone"
                            value={altConcPrimaryContactPhone}
                            onChange={(e) =>
                                setAltConcPrimaryContactPhone(e.target.value)
                            }
                        />
                        <EmailField
                            id="primary_contact_email_input"
                            label="Primary Contact Email"
                            value={altConcPrimaryContactEmail}
                            onChange={(e) =>
                                setAltConcPrimaryContactEmail(e.target.value)
                            }
                        />
                    </div>
                    <div className="concessionaire-mod-inputs-group">
                        <TextField
                            id="secondary_contact_name_input"
                            label="Secondary Contact Name"
                            className="concessionaire-mod-input-block"
                            value={altConcSecondaryContactName}
                            onChange={(e) =>
                                setAltConcSecondaryContactName(e.target.value)
                            }
                        />
                        <PhoneNumberField
                            id="secondary_contact_phone_input"
                            label="Secondary Contact Phone"
                            value={altConcSecondaryContactPhone}
                            onChange={(e) =>
                                setAltConcSecondaryContactPhone(e.target.value)
                            }
                        />
                        <EmailField
                            id="secondary_contact_email_input"
                            label="Secondary Contact Email"
                            value={altConcSecondaryContactEmail}
                            onChange={(e) =>
                                setAltConcSecondaryContactEmail(e.target.value)
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="concessionaires-footer ia-footer-submission">
                <div />
                <div>
                    <Button
                        className="cancel-concessionaire"
                        appearance="tertiary"
                        onClick={onCancelNav}
                    >
                        Cancel
                    </Button>
                    <Button className="submit-concessionaire" onClick={onAdd}>
                        {concessionaire.concessionaire_id ? 'Update' : 'Add'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

EditConcessionaire.propTypes = propTypes;
