/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { ScrollableList } from 'sarsaparilla';
import { ERROR_ROW_OBJECT, NO_RESULTS_OBJECT } from '../../constants/locationPicker';
import { LocationProps } from '../../../../ui-communication/src/constants/types';
import useIsLoggedInUserAConcessionaire from '../../hooks/useIsLoggedInUserAConcessionaire';
import useLoggedInUser from '../../hooks/useLoggedInUser';
import { errorForLocationsLineLoader } from '../../utilities/errorMessages';
import { BuildTreeData } from '../../utilities/locationPickerTree';
import { R1S_LOCATION_ID } from '../../utilities/locations';
import { ExpandableLocationRow } from './ExpandableLocationRow';

type ExpandableLocationSelectorProps = {
    dataSource: LocationProps[];
    maxDepth: number;
    onLocationSelection: (location: LocationProps, parent?: LocationProps) => void;
    onLocationExpandChange: (location: LocationProps, isExpanded: boolean) => void;
    expansionMap: Record<string, boolean>;
    showReservableLocations: boolean;
    allowAllLocationSelect: boolean;
    visibleLocationTypes: string[];
    selectableLocationTypes: string[];
    allowHierarchyFillSelection: boolean;
};

export function ExpandableLocationSelector({
    dataSource,
    maxDepth,
    onLocationSelection,
    onLocationExpandChange,
    expansionMap,
    showReservableLocations,
    allowAllLocationSelect,
    visibleLocationTypes,
    selectableLocationTypes,
    allowHierarchyFillSelection,
}: ExpandableLocationSelectorProps) {
    const isUserConcessionaire = useIsLoggedInUserAConcessionaire();
    const loggedInUser = useLoggedInUser();
    const displayConcessionaireStyle =
        isUserConcessionaire && loggedInUser?.concessionaires?.length === 1;

    if (!dataSource) {
        return null;
    }

    if (dataSource.length === 1) {
        switch (dataSource[0].location_id) {
            case ERROR_ROW_OBJECT.id:
                return (
                    <div className="expandable-location-selector-error">
                        {errorForLocationsLineLoader()}
                    </div>
                );
            case NO_RESULTS_OBJECT.id:
                return (
                    <div className="expandable-location-selector-error">
                        Search produced no results.
                    </div>
                );
            default:
            //Continue as normal.
        }
    }

    const onExpandChange = (location: LocationProps) => {
        const isExpanded = !expansionMap[location.id];
        onLocationExpandChange(location, isExpanded);
    };

    const rowsForBranch = (
        branch: LocationProps,
        parent?: LocationProps
    ): JSX.Element[] => {
        let branchRows: JSX.Element[] = [];
        let expanded = false;

        if (expansionMap[branch.id]) {
            expanded = true;
        }

        const hasLoadingChild =
            branch.children &&
            branch.children?.length === 1 &&
            branch.children[0].location_name === 'Loading';
        const isExpanding = expanded && hasLoadingChild;
        let key = branch.id;

        if (key === NO_RESULTS_OBJECT.id) {
            key = `${branch.parentId}-fake-child`;
        }

        const row = (
            <ExpandableLocationRow
                key={key}
                onSelect={() => onLocationSelection(branch, parent)}
                onExpanderChange={() => onExpandChange(branch)}
                isExpanding={isExpanding}
                expanded={expanded}
                selectableLocationTypes={selectableLocationTypes}
                allowHierarchyFillSelection={allowHierarchyFillSelection}
                location={branch}
            />
        );

        branchRows.push(row);

        if (expanded && branch.children) {
            for (const child of branch.children) {
                const childrenRows = rowsForBranch(child, branch);
                branchRows = branchRows.concat(childrenRows);
            }
        }

        return branchRows;
    };

    const treeData = BuildTreeData(
        dataSource,
        maxDepth,
        showReservableLocations,
        allowAllLocationSelect,
        visibleLocationTypes
    );

    const replaceRootWithConcessionaire =
        treeData.length > 0 &&
        treeData[0].location_id === R1S_LOCATION_ID &&
        displayConcessionaireStyle;

    if (replaceRootWithConcessionaire) {
        treeData[0].location_description =
            loggedInUser.concessionaires[0].concessionaire_name;
    }

    const rows = treeData.map((branch) => rowsForBranch(branch as LocationProps));

    return (
        <ScrollableList maxHeight={260} className="expandable-location-selector-list">
            {rows}
        </ScrollableList>
    );
}
