/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Alert,
    Button,
    ButtonGroup,
    EmailField,
    emailValidator,
    HelmetWrapperInternal,
    useFlags,
} from 'sarsaparilla';
import { loginMessagesStorageKey } from 'ui-internal-account/src/actions/login';
import { oidcRedirect } from 'ui-internal-account/src/actions/oidc';
import useOidcConfig from 'ui-internal-account/src/hooks/useOidcConfig';
import { errorForLogin } from 'ui-internal-account/src/utilities/errorMessages';

function LoginFormHeader({ title, info }: { title: string; info: string }) {
    return (
        <>
            <h1>{title}</h1>
            {info && <Alert type="info">{info}</Alert>}
        </>
    );
}

type LoginFormProps = {
    loginFn: (email: string, password: string) => void;
    initialEmail: string;
    setEmailFn: (email: string) => void;
    errorMessage: string;
    attempts: number;
};

type ErrorMessageProps = {
    errorMessage: string;
    concurrentSessionError: boolean;
    attempts: number;
};

function ErrorMessage({
    errorMessage,
    concurrentSessionError,
    attempts,
}: ErrorMessageProps) {
    if (errorMessage || concurrentSessionError) {
        let err = errorForLogin(errorMessage, attempts);

        if (concurrentSessionError) {
            err =
                'You have been logged out of your current session because this account is logged in with another browser. ' +
                'Please sign in again to gain access to your account and force a logout of the other session.';
        }

        return (
            <Alert shouldFocusOnMount type="error">
                {err}
            </Alert>
        );
    }

    return null;
}

export function LoginForm({
    loginFn,
    initialEmail,
    errorMessage,
    setEmailFn,
    attempts,
}: LoginFormProps) {
    const [email, setEmailField] = useState(initialEmail);
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [password, setPassword] = useState('');
    const [loginMessageFlags, setLoginMessageFlags] = useState({
        concurrentSessionError: false,
        loggedOutForInactivity: false,
    });

    const oidcCfg = useOidcConfig();
    const { enableOidc } = useFlags();

    useEffect(() => {
        const loginMessagesString = localStorage.getItem(loginMessagesStorageKey);
        if (loginMessagesString) {
            setLoginMessageFlags(JSON.parse(loginMessagesString));
        }
    }, []);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailField(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!emailValidator(email).isValid) {
            setEmailIsValid(false);
            return;
        }

        setEmailIsValid(true);
        loginFn(email, password);
    };

    const setEmail = () => {
        setEmailFn(email);
    };

    let title = 'Sign In';
    let info = '';
    if (loginMessageFlags.loggedOutForInactivity) {
        title = 'Session Timeout';
        info =
            'You have been logged out automatically due to inactivity. Login with your email and password.';
    }

    return (
        <form onSubmit={onLogin}>
            <div>
                <HelmetWrapperInternal title={title} />

                <LoginFormHeader title={title} info={info} />

                {!emailIsValid && (
                    <Alert type="error" className="mb-2">
                        That is not a valid email address.
                    </Alert>
                )}

                <div>
                    <EmailField
                        id="email"
                        onChange={handleEmailChange}
                        value={email}
                        aria-label="Email Address"
                        placeholder="Email Address"
                    />
                </div>
                <div>
                    <input
                        type="password"
                        id="password"
                        onChange={handlePasswordChange}
                        value={password}
                        aria-label="Password"
                        placeholder="Password"
                    />
                </div>

                <ErrorMessage
                    attempts={attempts}
                    errorMessage={errorMessage}
                    concurrentSessionError={loginMessageFlags?.concurrentSessionError}
                />
                <ButtonGroup isStacked>
                    <Button type="submit" id="login">
                        Login
                    </Button>
                    {enableOidc && (
                        <Button
                            onClick={() => oidcRedirect(oidcCfg)}
                            appearance="tertiary"
                            type="button"
                            id="oidc"
                        >
                            Use Login.gov
                        </Button>
                    )}
                </ButtonGroup>
                <div>
                    <Link
                        id="forgot-password"
                        to="/internal/account/recover-password"
                        onClick={setEmail}
                    >
                        Forgot Password?
                    </Link>
                </div>
            </div>
        </form>
    );
}
