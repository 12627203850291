/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { logOut } from '../actions/login';
import { resetPasswordV2, validateEmailToken } from '../actions/resetPassword';
import PasswordForm from '../components/PasswordForm';
import { claimIsEmailPassword } from '../utilities/internalSecurityUtil';

type ResetPasswordActions = {
    validateEmailAction: (token: string) => void;
    resetAction: (token: string, password: string) => void;
    logOutAction: () => void;
};

type ResetPasswordState = {
    error?: any;
    validated: boolean;
    mfaValidated: boolean;
    resetToken: string;
    resetTokenMFA: string;
};

type ResetPasswordPageProps = ResetPasswordActions & {
    resetPassword: ResetPasswordState;
};

function ResetPasswordPage({
    resetPassword,
    validateEmailAction,
    resetAction,
    logOutAction,
}: ResetPasswordPageProps) {
    const history = useHistory();

    useEffect(() => {
        const queryParams = qs.parse(history.location.search);
        const token = queryParams.token;
        if (token && claimIsEmailPassword(token)) {
            validateEmailAction(token);
        } else {
            // TODO We should throw an error here
            history.push('/internal/account/login');
        }
    }, [validateEmailAction, history]);

    return (
        <PasswordForm
            token={resetPassword.resetToken}
            resetPassword={resetPassword}
            reset={resetAction}
            logOut={logOutAction}
        />
    );
}

const mapStateToProps = (state: ResetPasswordPageProps) => {
    return {
        resetPassword: state.resetPassword,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            logOutAction: logOut,
            validateEmailAction: validateEmailToken,
            resetAction: resetPasswordV2,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
