/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    account: null,
    error: null,
    success: false,
    validated: false,
    resetToken: null,
    resetTokenMFA: null,
    mfaValidated: false,
};

export default function resetPassword(state = initialState, action) {
    switch (action.type) {
        case types.VALIDATE_SUCCESSFUL:
            return {
                ...state,
                validated: true,
                error: null,
                resetToken: action.resetToken,
            };

        case types.MFA_RESET_PASSWORD_SUCCESSFUL:
            return {
                ...state,
                validated: true,
                error: null,
                resetToken: action.resetToken,
                mfaValidated: true,
            };

        case types.VALIDATE_SUCCESSFUL_MFA:
            return {
                ...state,
                validated: true,
                error: null,
                resetTokenMFA: action.resetTokenMFA,
            };

        case types.PASSWORD_RESET:
            return {
                ...state,
                account: action.account,
                user: action.account.user,
                error: null,
            };

        case types.RESET_PASSWORD_FAILED:
            return { ...state, error: action.error };

        case types.RECOVER_PASSWORD_SUCCESSFUL:
            return { ...state, error: null, success: true };

        case types.RECOVER_PASSWORD_FAILED:
            return { ...state, error: action.error, success: false };

        case types.SET_EMAIL:
            return { ...state, email: action.email };

        case types.LOGGED_OUT:
            return initialState;

        default:
            return state;
    }
}
