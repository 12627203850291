/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { throttle } from 'lodash';
import * as globals from '../constants/globals';
import { makeAuthorizedRequest } from './login';
import { KEEP_ALIVE_CALLED } from '../constants/types';

type KeepaliveAction = {
    type: string;
};

const keepaliveCalled = (): KeepaliveAction => ({ type: KEEP_ALIVE_CALLED });

const keepalive = async (dispatch: Function): Promise<any> =>
    makeAuthorizedRequest(`${globals.API_URL}/keepalive`, 'GET', dispatch);

// lodash throttle wrapper, so the actual API call only happens every 60 seconds
// This must be defined on its own, so it's not re-created with every call, thus negating the effect of throttle.
const throttledApiCall = throttle(keepalive, 1000 * 60, {
    leading: true,
    trailing: true,
});

export const keepaliveThrottled = () => {
    return async (dispatch: Function): Promise<void> => {
        await throttledApiCall(dispatch);
        dispatch(keepaliveCalled());
    };
};

export const keepaliveNoThrottle = () => {
    return async (dispatch: Function): Promise<void> => {
        await keepalive(dispatch);
        dispatch(keepaliveCalled());
    };
};
