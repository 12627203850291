/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';

import { IS_BOUNDARY_WATER } from 'ui-permit/src/constants';
import CooperatorDetails from '../components/cooperator/CooperatorDetails';
import PageHeader from '../components/PageHeader';
import SiteWrapper from './SiteWrapper';
import {
    loadCooperatorEmployees,
    saveCooperators,
    saveEmployees,
    deleteEmployees,
} from '../actions/manageCooperators';
import { setCooperator, setEmployee } from '../actions/manageCooperatorsSet';
import { errorForCooperators } from '../utilities/errorMessages';
import * as errors from '../constants/errors';
import { updateUserStatus, setInitialValue } from '../actions/userManagement';

export class CooperatorDetailsContainer extends React.Component {
    static propTypes = {
        history: TYPES.HISTORY,
        dispatch: PropTypes.func,
        cooperator: PropTypes.any,
        employee: PropTypes.any,
        employees: PropTypes.array,
        loadingCooperatorEmployeesError: PropTypes.bool,
        savingCooperatorEmployeesError: PropTypes.bool,
        deletingCooperatorEmployeesError: PropTypes.bool,
        savingManagedCooperatorsError: PropTypes.bool,
        setCooperator: PropTypes.func,
        setEmployee: PropTypes.func,
        saveCooperators: PropTypes.func,
        saveEmployees: PropTypes.func,
        deleteEmployees: PropTypes.func,
        updateUserStatusSuccessful: PropTypes.bool,
        updateUserStatusErrorMessage: PropTypes.string,
        isShowingUsersLock: PropTypes.bool,
    };

    componentDidMount() {
        this.props.dispatch(loadCooperatorEmployees());
        this.props.setInitialValue();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.updateUserStatusSuccessful &&
            prevProps.updateUserStatusSuccessful !== this.props.updateUserStatusSuccessful
        ) {
            this.props.dispatch(loadCooperatorEmployees());
        }
    }

    saveCooperators = (cooperators, add) => {
        const coops = cooperators || [this.props.cooperator];
        this.props.saveCooperators(coops, add, this.props.history);
    };

    saveEmployees = (employees, add) => {
        const emps = employees || [this.props.employee];
        this.props.saveEmployees(emps, add, this.props.history);
    };

    render() {
        const {
            history,
            employees,
            cooperator,
            loadingCooperatorEmployeesError,
            savingCooperatorEmployeesError,
            deletingCooperatorEmployeesError,
            savingManagedCooperatorsError,
            updateUserStatusErrorMessage,
        } = this.props;

        let error = '';
        const navBackUrl = '/internal/account/manage-cooperators';
        const crumbs = [];
        const tabs = [
            {
                title: 'Cooperators',
            },
        ];

        error = loadingCooperatorEmployeesError
            ? errorForCooperators(errors.GET_COOPERATOR_EMPLOYEES_FAILED)
            : error;
        error = savingCooperatorEmployeesError
            ? errorForCooperators(errors.SAVE_COOPERATOR_EMPLOYEES_FAILED)
            : error;
        error = deletingCooperatorEmployeesError
            ? errorForCooperators(errors.DELETE_COOPERATOR_EMPLOYEES_FAILED)
            : error;
        error = savingManagedCooperatorsError ? errorForCooperators() : error;
        error = updateUserStatusErrorMessage;

        crumbs.push({
            label: 'Cooperators',
            onClick: () => history.push('/internal/account/manage-cooperators'),
        });
        crumbs.push({
            label: 'Cooperator Details',
        });

        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader
                        title="Cooperator Management"
                        tabs={tabs}
                        breadcrumbs={crumbs}
                        navBack={navBackUrl}
                        selectedTabIndex={0}
                    />
                </div>
                <div id="CooperatorManagement" className="page-content wrapper">
                    <CooperatorDetails
                        history={history}
                        gridData={employees}
                        cooperator={cooperator}
                        errorText={error}
                        setCooperator={this.props.setCooperator}
                        setEmployee={this.props.setEmployee}
                        saveCooperators={this.saveCooperators}
                        updateUserStatus={this.props.updateUserStatus}
                        deleteEmployees={this.props.deleteEmployees}
                        setInitialValue={this.props.setInitialValue}
                        updateUserStatusSuccessful={this.props.updateUserStatusSuccessful}
                        isShowingUsersLock={this.props.isShowingUsersLock}
                    />
                </div>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    const locationId = state.selectedLocationRole?.role?.location?.location_id;
    return {
        cooperator: state.manageCooperatorsSet.managedCooperator,
        employee: state.manageCooperatorsSet.cooperatorEmployee,
        employees: state.manageCooperators.cooperatorEmployees,
        loadingCooperatorEmployeesError:
            state.manageCooperators.loadingCooperatorEmployeesError,
        savingCooperatorEmployeesError:
            state.manageCooperators.savingCooperatorEmployeesError,
        savingManagedCooperatorsError:
            state.manageCooperators.savingManagedCooperatorsError,
        updateUserStatusSuccessful: state.userManagement.updateUserStatusSuccessful,
        updateUserStatusErrorMessage: state.userManagement.updateUserStatusErrorMessage,
        isShowingUsersLock: IS_BOUNDARY_WATER(locationId),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        setInitialValue: () => dispatch(setInitialValue()),
        updateUserStatus: (user, note) => dispatch(updateUserStatus(user, note)),
        setCooperator: (cooperator) => dispatch(setCooperator(cooperator)),
        setEmployee: (employee) => dispatch(setEmployee(employee)),
        saveCooperators: (cooperators, add, history) =>
            dispatch(saveCooperators(cooperators, add, history)),
        saveEmployees: (employees, add, history) =>
            dispatch(saveEmployees(employees, add, history)),
        deleteEmployees: (employees) => dispatch(deleteEmployees(employees)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CooperatorDetailsContainer);
