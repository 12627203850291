/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, ButtonGroup, DropdownBase, Heading, Icons, TYPES } from 'sarsaparilla';
import classNames from 'classnames';
import { flatMap, values, uniq } from 'lodash';
import { Logo } from 'site-kit';
import MfaEnrollmentWizardModal from './MfaEnrollmentWizardModal';
import {
    domainToTileMap,
    generateTilePath,
    launchBuckets,
} from '../utilities/launchTiles';
import navigation from '../utilities/navigation';
import { displayStringForRoleType } from '../utilities/roles';
import LocationPicker from './LocationPicker';

const propTypes = {
    history: TYPES.HISTORY,
    user: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        roles: PropTypes.array,
        mfa_data: PropTypes.shape({
            opt_out_at: PropTypes.string,
        }),
    }),
    selectedRole: PropTypes.shape({
        location: PropTypes.shape({}),
        role_type: PropTypes.string,
    }),
    selectedLocation: PropTypes.shape({}),
    logout: PropTypes.func.isRequired,
    navigateLogin: PropTypes.func,
    domainsForLocationId: PropTypes.shape({}),
    getDomainsForLocation: PropTypes.func,
};

function Trigger(props) {
    const { innerRef, isDisabled, isExpanded, name, ...rest } = props;

    let arrowIcon = <Icons.IconChevronDown />;
    if (isExpanded) {
        arrowIcon = <Icons.IconChevronUp />;
    }

    return (
        // ...rest is important because it contains the onClick handler and a handful of aria-* attributes
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Button
            className="ribbon-bucket-button"
            appearance="subtle"
            innerRef={innerRef}
            isDisabled={isDisabled}
            {...rest}
        >
            {name}
            <div> {arrowIcon} </div>
        </Button>
    );
}

Trigger.propTypes = {
    innerRef: PropTypes.any,
    isDisabled: PropTypes.bool,
    isExpanded: PropTypes.bool,
    name: PropTypes.string,
};

export default class HeaderRibbon extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.hoverTimer = null;
        this.state = {
            displayMenu: false,
            mfaIsOpen: false,
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    handleMfaClose() {
        this.setState({ mfaIsOpen: false, displayMenu: false });
    }

    onRibbonLeave() {
        this.hoverTimer = window.setTimeout(() => {
            this.hoverTimer = null;
            this.setState({
                displayMenu: false,
            });
        }, 350);
    }

    onRibbonEnter() {
        if (this.hoverTimer) {
            window.clearTimeout(this.hoverTimer);
            this.hoverTimer = null;
        }
    }

    userHoverUI = () => {
        const classes = classNames({
            'hover-user-view': true,
            invisible: !this.state.displayMenu,
        });

        return (
            <div className={classes}>
                <ul>
                    <li>
                        <Button
                            appearance="link"
                            id="profile-link"
                            onClick={() => {
                                navigation.push(
                                    '/internal/account/my-profile',
                                    this.props.history
                                );
                            }}
                        >
                            My Profile
                        </Button>
                    </li>
                    <li>
                        <Button
                            appearance="link"
                            id="change-password-link"
                            onClick={() => {
                                navigation.push(
                                    '/internal/account/change-password',
                                    this.props.history
                                );
                            }}
                        >
                            Change Password
                        </Button>
                    </li>
                    <li>
                        <Button
                            appearance="link"
                            id="logout-link"
                            onClick={() => {
                                this.props.logout();
                            }}
                        >
                            Log Out
                        </Button>
                    </li>
                </ul>
            </div>
        );
    };

    toggleMenu() {
        this.setState((prevState) => {
            return { ...prevState, displayMenu: !prevState.displayMenu };
        });
    }

    userName() {
        if (this.props.user) {
            return `${this.props.user.first_name} ${this.props.user.last_name}`;
        }

        return '';
    }

    renderHeaderMain() {
        return (
            <header className="header-ribbon-main">
                <button
                    type="button"
                    className="header-hub-home-link"
                    onClick={this.props.navigateLogin}
                    role="link"
                    aria-label={`${process.env.SITE_NAME} | The Hub Homepage`}
                >
                    <span className="header-hub-home-link-inner">
                        <span className="header-hub-logo">
                            <Logo isLight aria-label={`${process.env.SITE_NAME} Hub`} />
                        </span>

                        <span className="ribbon-logo-home-label">The Hub</span>
                    </span>
                </button>

                <ButtonGroup className="header-ribbon-help-links">
                    <Button
                        appearance="link"
                        iconBeforeElement={<Icons.IconLightBulb />}
                        href={process.env.IDEAS_PORTAL_URL}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Ideas Portal
                    </Button>

                    <Button
                        appearance="link"
                        iconBeforeElement={<Icons.IconReport />}
                        href={process.env.SN_SYSTEM_UPDATES_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        System Updates
                    </Button>

                    <Button
                        appearance="link"
                        iconBeforeElement={<Icons.IconHelp />}
                        href={process.env.SN_KB_INT_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Help Center
                    </Button>

                    <Button
                        onClick={() =>
                            window.open(
                                process.env.SN_INTERNAL_CHAT_URL,
                                'Chat Support',
                                'width=300,height=600'
                            )
                        }
                        appearance="link"
                        iconBeforeElement={<Icons.IconChat />}
                        className="header-ribbon-contact-chat-btn"
                    >
                        Chat with us
                    </Button>

                    <Button
                        appearance="link"
                        onClick={() => this.toggleMenu()}
                        iconBeforeElement={<Icons.IconAccountCircle />}
                        size="lg"
                        data-notranslate
                    >
                        {this.userName()}
                    </Button>
                </ButtonGroup>
                {this.userHoverUI()}
            </header>
        );
    }

    renderLinks(bucketName, domains, colCount) {
        const links = [];

        let colPos = 0;
        domains.forEach((domain, i) => {
            const link = domainToTileMap[domain];
            if (link && link.bucket === bucketName) {
                colPos += 1;

                const col = Math.ceil(colPos / colCount);
                const row = ((colPos - 1) % colCount) + 2;
                const style = {
                    gridColumn: col,
                    gridRow: row,
                    msGridColumn: col,
                    msGridRow: row,
                };
                if (this.props.selectedRole?.location) {
                    const path = generateTilePath(link, this.props.selectedRole.location);
                    links.push(
                        <a
                            href={path}
                            className="launch-link wrapper"
                            key={`Launch_Link_${i}`}
                            style={style}
                        >
                            <div className="rec-title">{link.text}</div>
                        </a>
                    );
                }
            }
        });

        if (links.length === 0) {
            return (
                <div className="rec-title empty">{`No links for ${bucketName} at this location.`}</div>
            );
        }

        return links;
    }

    renderHeaderDomains() {
        let domains = [];
        if (this.props.domainsForLocationId) {
            domains = uniq(flatMap(values(this.props.domainsForLocationId), 'domains'));
        }

        const buckets = Object.keys(launchBuckets);
        return buckets.map((name) => {
            const domCount = domains.filter(
                (d) => domainToTileMap[d] && domainToTileMap[d].bucket === name
            ).length;
            const colCount = Math.ceil(Math.sqrt(domCount));

            return (
                <div className="ribbon-bucket" key={`bucket_${name}`}>
                    <DropdownBase
                        id={`bucket_${name}`}
                        applyButtonLabel="Close"
                        triggerButtonElement={<Trigger name={name} />}
                    >
                        <Heading
                            headingLevel={4}
                            appearance="h5"
                            className="ribbon-bucket-expand-title"
                        >
                            {name}
                        </Heading>
                        <div className="ribbon-bucket-dropdown-content">
                            {this.renderLinks(name, domains, colCount)}
                        </div>
                    </DropdownBase>
                </div>
            );
        });
    }

    renderHeaderSub() {
        return (
            <nav className="header-ribbon-sub">
                <div className="primary-links-wrap">{this.renderHeaderDomains()}</div>
                <div className="location-info-wrap">
                    <div className="ribbon-location-select">
                        <LocationPicker
                            id="site-wrapper"
                            selectedLocation={this.props.selectedLocation}
                            launchButtonIconIsChevron
                            launchButtonAppearance="subtle"
                            launchButtonRoleText={`${displayStringForRoleType(
                                this.props.selectedRole
                                    ? this.props.selectedRole.role_type
                                    : 'N/A'
                            )}`}
                            launchButtonClassName="ribbon-location-display"
                            locationSelect={(location) =>
                                this.props.getDomainsForLocation(
                                    location,
                                    this.props.history
                                )
                            }
                        />
                    </div>
                </div>
            </nav>
        );
    }

    render() {
        return (
            <div
                className="header-ribbon"
                onMouseLeave={this.onRibbonLeave.bind(this)}
                onMouseEnter={this.onRibbonEnter.bind(this)}
            >
                {this.renderHeaderMain()}
                {this.renderHeaderSub()}
                <MfaEnrollmentWizardModal
                    canSkip
                    isOpen={this.state.mfaIsOpen}
                    onClose={this.handleMfaClose.bind(this)}
                />
            </div>
        );
    }
}
