/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import moment, { type Moment } from 'moment';
import { DateRange } from 'react-aria';
import { parseAbsoluteToLocal, toCalendarDate } from '@internationalized/date';

import {
    Checkbox,
    Icons,
    Spacer,
    Text,
    useFlags,
    DateRangePicker,
    localToday,
    DateRangePickerOnChange,
    calendarDateToMoment,
    FlexRow,
} from 'sarsaparilla';

interface UserEffectiveDatesProps {
    className?: string;
    editable?: boolean;
    showGuide?: boolean;
    effectiveDatesEnabled?: boolean;
    inStartDate?: Moment | null | string;
    inEndDate?: Moment | null | string;
    setDatesFn: (start: Moment | null, end: Moment | null) => void;
    setEnabledFn: (checked: boolean) => void;
}

export default function UserEffectiveDates({
    className = 'mb-2',
    editable,
    showGuide = false,
    effectiveDatesEnabled,
    inStartDate,
    inEndDate,
    setDatesFn,
    setEnabledFn,
}: UserEffectiveDatesProps) {
    const { enableIaEffectiveDates } = useFlags();

    const [dateRange, setDateRange] = React.useState<DateRange | null>(null);

    const areDatesStrings =
        typeof inStartDate === 'string' && typeof inEndDate === 'string';

    const managingInternalUsersGuideLink = `${process.env.SN_KB_INT_URL}?id=kb_article_view&sys_kb_id=8db38e73dbc86300c571740d0f9619d1#dates`;

    React.useEffect(() => {
        if (areDatesStrings) {
            setDateRange({
                start: toCalendarDate(parseAbsoluteToLocal(inStartDate)),
                end: toCalendarDate(parseAbsoluteToLocal(inEndDate)),
            });
        }
    }, [inStartDate, inEndDate, areDatesStrings]);

    if (!enableIaEffectiveDates) return null;

    const onDateRangeChange: DateRangePickerOnChange = (value, validation) => {
        setDateRange(value);

        if (!value || validation?.isInvalid === true) {
            setDatesFn(null, null);
            return;
        }

        const start = calendarDateToMoment(value.start)?.startOf('day');
        const end = calendarDateToMoment(value.end)?.endOf('day');

        if (start && end) {
            setDatesFn(start, end);
        }
    };

    if (!editable && effectiveDatesEnabled && areDatesStrings) {
        return (
            <div className={`effective-dates ${className}`}>
                <Text>
                    Activation Date:{' '}
                    <strong>{moment(inStartDate).format('MM/DD/YYYY')}</strong>
                </Text>
                <Text>
                    Through Expiration Date:{' '}
                    <strong>{moment(inEndDate).format('MM/DD/YYYY')}</strong>
                </Text>
            </div>
        );
    }

    return (
        <div className={`effective-dates ${className}`}>
            {editable && (
                <div>
                    <div className="effective-dates-control">
                        <Checkbox
                            isDisabled={false}
                            isChecked={effectiveDatesEnabled}
                            onChange={
                                setEnabledFn
                                    ? (e) => setEnabledFn(e.target.checked)
                                    : undefined
                            }
                            label="Set Start and End Dates for Access"
                            id="access-dates"
                        />
                        {showGuide && (
                            <>
                                <div className="how-it-works-spacer">|</div>
                                <div className="effective-dates-guide">
                                    <a
                                        href={managingInternalUsersGuideLink}
                                        className="how-it-works-icon"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        How does this work? <Icons.IconHelp size="sm" />
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                    <FlexRow>
                        {effectiveDatesEnabled && (
                            <div className="effective-dates-range-picker">
                                <Spacer size="sm" />
                                <DateRangePicker
                                    label="Start and End Dates"
                                    minValue={
                                        areDatesStrings
                                            ? toCalendarDate(
                                                  parseAbsoluteToLocal(inStartDate)
                                              )
                                            : localToday
                                    }
                                    maxValue={localToday.add({ years: 1 })}
                                    value={dateRange}
                                    onChange={onDateRangeChange}
                                />
                            </div>
                        )}
                    </FlexRow>
                </div>
            )}
        </div>
    );
}
