/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment, { isMoment } from 'moment';
import { get, isEqual } from 'lodash';
import { parseAbsolute, toCalendarDate } from '@internationalized/date';

import {
    Button,
    DatePicker,
    Select,
    TYPES,
    momentToCalendarDate,
    localToday,
    Alert,
} from 'sarsaparilla';

const stateOptions = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },

    { label: '(CAN) Alberta', value: 'AB' },
    { label: '(CAN) British Columbia', value: 'BC' },
    { label: '(CAN) Manitoba', value: 'MB' },
    { label: '(CAN) New Brunswick', value: 'NB' },
    { label: '(CAN) Newfoundland and Labrador', value: 'NL' },
    { label: '(CAN) Northwest Territories', value: 'NT' },
    { label: '(CAN) Nova Scotia', value: 'NS' },
    { label: '(CAN) Nunavut', value: 'NU' },
    { label: '(CAN) Ontario', value: 'ON' },
    { label: '(CAN) Prince Edward Island', value: 'PE' },
    { label: '(CAN) Quebec', value: 'QC' },
    { label: '(CAN) Saskatchewan', value: 'SK' },
    { label: '(CAN) Yukon', value: 'YT' },
];

const countryOptions = [
    { label: 'United States', value: 'USA' },
    { label: 'Canada', value: 'CAN' },
];

export default class EditCooperator extends React.Component {
    static propTypes = {
        history: TYPES.HISTORY,
        isManager: PropTypes.bool,
        isAddressValidated: PropTypes.bool,
        onValidateAddress: PropTypes.func,
        cooperator: PropTypes.any.isRequired,
        saveCooperators: PropTypes.func,
        errorText: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            cooperator: { ...this.props.cooperator },
        };
        this.renderInputBlock = this.renderInputBlock.bind(this);
        this.renderSelectBlock = this.renderSelectBlock.bind(this);
        this.renderSingleDateBlock = this.renderSingleDateBlock.bind(this);
        this.renderTextareaBlock = this.renderTextareaBlock.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.cooperator, prevProps.cooperator)) {
            this.setState((prevState) => ({
                cooperator: { ...prevState.cooperator, ...this.props.cooperator },
            }));
        }
    }

    validateAddress() {
        this.props.onValidateAddress(this.state.cooperator);
    }

    navCancel() {
        const url = this.state.cooperator.cooperator_id
            ? '/internal/account/cooperator-details'
            : '/internal/account/manage-cooperators';
        this.props.history.push(url);
    }

    navAdd() {
        this.props.saveCooperators([this.state.cooperator], true);
    }

    parseFieldToValueObject(field, value) {
        let obj = null;
        const splits = field.split('.').reverse();
        splits.forEach((key) => {
            if (!obj) {
                const parentPath = field
                    .split('.')
                    .slice(0, splits.length - 1)
                    .join('.');
                obj = { ...get(this.state.cooperator, parentPath), [key]: value };
            } else {
                obj = { [key]: obj };
            }
        });

        return obj;
    }

    renderInputBlock(label, field, required, isValid = true) {
        const id = `Mod_${field.replace('.', '_')}`;
        const css = classNames({
            'cooperator-mod-input-block': true,
            'rec-form-item-wrap': required,
            'cooperator-mod-input-address': field === 'address.address1' && !isValid,
            required,
        });

        return (
            <div className={css}>
                <label htmlFor={id}>{label}</label>
                <input
                    id={id}
                    value={get(this.state.cooperator, field) ?? ''}
                    onChange={(e) => {
                        const obj = this.parseFieldToValueObject(field, e.target.value);
                        this.setState((prevState) => ({
                            cooperator: { ...prevState.cooperator, ...obj },
                        }));
                    }}
                />
            </div>
        );
    }

    renderSelectBlock(label, field, options) {
        const id = `Mod_${field.replace('.', '_')}`;
        const css = classNames({
            'cooperator-mod-input-block': true,
            'cooperator-mod-select-block': true,
        });

        return (
            <div className={css}>
                {label === `Mod_country` || label === `Mod_address_state` ? (
                    <label htmlFor={id}>{label}</label>
                ) : (
                    ''
                )}
                <Select
                    id={id}
                    label={label}
                    placeholder="Change Location"
                    value={get(this.state.cooperator, field)}
                    options={options}
                    onChange={(e) => {
                        const obj = this.parseFieldToValueObject(field, e?.target?.value);
                        this.setState((prevState) => ({
                            cooperator: { ...prevState.cooperator, ...obj },
                        }));
                    }}
                />
            </div>
        );
    }

    renderTextareaBlock(label, field, desc) {
        const id = `Mod_${field.replace('.', '_')}`;
        const css = classNames({
            'cooperator-mod-textarea-block': true,
        });

        return (
            <div className={css}>
                <label htmlFor={id}>{label}</label>
                {desc && <div className="textarea-block-desc">{desc}</div>}
                <textarea
                    id={id}
                    value={get(this.state.cooperator, field)}
                    placeholder="Enter text here..."
                    onChange={(e) => {
                        const obj = this.parseFieldToValueObject(field, e.target.value);
                        this.setState((prevState) => ({
                            cooperator: { ...prevState.cooperator, ...obj },
                        }));
                    }}
                />
            </div>
        );
    }

    renderSingleDateBlock(label, field) {
        const id = `Mod_${field.replace('.', '_')}`;
        const css = classNames({
            'cooperator-mod-input-block': true,
            'cooperator-mod-singledate-block': true,
        });

        const rawDate = this.state.cooperator?.[field];

        // The date is stored as a full UTC date-time, but the BE ignores the time portion.
        const dateValue =
            typeof rawDate === 'string'
                ? toCalendarDate(parseAbsolute(rawDate, 'utc'))
                : isMoment(rawDate)
                  ? momentToCalendarDate(rawDate)
                  : null;

        return (
            <div className={css}>
                <DatePicker
                    label={label}
                    id={id}
                    defaultValue={dateValue}
                    minValue={localToday}
                    onChange={(value, validation) => {
                        if (value && !validation.isInvalid) {
                            this.setState((prevState) => ({
                                cooperator: {
                                    ...prevState.cooperator,
                                    [field]: moment.utc(value.toString(), 'YYYY-MM-DD'),
                                },
                            }));
                        }
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="cooperator-details-wrapper">
                <div className="cooperators-header">
                    <h2 className="cooperator-component-title h5">
                        {this.props.cooperator && this.props.cooperator.cooperator_id
                            ? 'Edit'
                            : 'Add'}{' '}
                        Cooperator
                    </h2>
                </div>

                {this.props.errorText && (
                    <Alert shouldFocusOnMount type="error" className="cooperators-error">
                        {this.props.errorText}
                    </Alert>
                )}

                <div className="cooperators-body">
                    <p>Contact information will be publicly available.</p>
                    <div className="cooperator-mod-inputs">
                        {this.renderInputBlock('Company Name', 'cooperator_name', true)}
                        {this.renderInputBlock(
                            'Cooperator District',
                            'cooperator_district'
                        )}
                        {this.props.isManager &&
                            this.renderInputBlock('Primary Contact', 'designated_agent')}
                        {this.props.isManager &&
                            this.renderInputBlock(
                                'Secondary Contact',
                                'secondary_contact'
                            )}
                        {this.props.isManager &&
                            this.renderSingleDateBlock(
                                'Expiration Date',
                                'expiration_date'
                            )}
                        {this.renderInputBlock('Email Address', 'email_address')}
                        {this.renderInputBlock('Web URL', 'web_url')}
                        {this.renderInputBlock(
                            'Address Line One',
                            'address.address1',
                            false,
                            this.props.isAddressValidated
                        )}
                        {this.renderInputBlock('Address Line Two', 'address.address2')}
                        {this.renderInputBlock('City', 'address.city')}
                        {this.renderSelectBlock(
                            'State/Province',
                            'address.state',
                            stateOptions
                        )}
                        {this.renderInputBlock('Zip/Postal Code', 'address.zip_code')}
                        {this.renderSelectBlock('Country', 'country', countryOptions)}
                        {this.renderInputBlock('Phone #', 'phone_number')}
                        <div className="cooperator-mod-input-block">
                            <Button
                                appearance="primary"
                                className="cooperator-mod-button-block"
                                onClick={this.validateAddress.bind(this)}
                            >
                                Validate Address
                            </Button>
                        </div>
                    </div>
                    <div className="cooperator-mod-textareas">
                        {this.renderTextareaBlock(
                            'Hours of Operations',
                            'hours_of_operation',
                            'Example:   "Open: May thru September - 9:00 AM to 9:00 PM Monday - Sat. 10:00AM to 6:00 PM on Sunday - Call for winter hours."'
                        )}
                        {this.renderTextareaBlock(
                            'Driving Directions',
                            'driving_directions',
                            'Example:   "I-35 North to 5th Ave W toward Lake Ave. Lake Ave becomes Canal Park Drive. From WI, South on Tower Ave, W-35/US-53 toward Belknap St/US-2."'
                        )}
                        {this.props.isManager &&
                            this.renderTextareaBlock('Comments', 'comments')}
                        {this.props.isManager &&
                            this.renderTextareaBlock(
                                'PCI Compliance Notes',
                                'pci_compliance_notes'
                            )}
                    </div>
                </div>
                <div className="cooperators-footer ia-footer-submission">
                    <div>
                        <label
                            htmlFor="updateCooperatorLock"
                            className="rec-label-toggle"
                        >
                            {this.state.cooperator.locked ? 'Locked' : 'Unlocked'}
                            <input
                                className="rec-input-hide"
                                id="updateCooperatorLock"
                                type="checkbox"
                                checked={this.state.cooperator.locked}
                                name="toggle_lock"
                                onChange={() => {
                                    this.setState((prevState) => ({
                                        cooperator: {
                                            ...prevState.cooperator,
                                            locked: !prevState.cooperator.locked,
                                        },
                                    }));
                                }}
                            />
                            <span className="rec-input-toggle input-toggle-align-with-label" />
                        </label>
                    </div>
                    <div>
                        <button
                            type="button"
                            className="cancel-cooperator rec-button-tertiary"
                            onClick={this.navCancel.bind(this)}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="submit-cooperator rec-button-primary"
                            onClick={this.navAdd.bind(this)}
                            disabled={!this.props.isAddressValidated}
                        >
                            {this.state.cooperator.cooperator_id ? 'Update' : 'Add'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

// cSpell:ignore singledate, textareas, Belknap
