/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Icons } from 'sarsaparilla';

export default function ManageCooperatorsTableActions({
    selectedFlatRows,
    onLock,
    onUnlock,
    onDelete,
}) {
    const selectedRows = selectedFlatRows.map(({ original }) => original);
    const allowLock = selectedRows.some((coop) => !coop.locked);
    const allowUnlock = selectedRows.some((coop) => coop.locked);
    const allowDelete = selectedRows.length > 0;

    return (
        <ButtonGroup isStretchedToFit>
            <Button
                iconBeforeElement={<Icons.IconLock />}
                isDisabled={!allowLock}
                onClick={() => onLock(selectedRows.filter((coop) => !coop.locked))}
                appearance="tertiary"
                id="lockCooperatorsButton"
            >
                Lock
            </Button>
            <Button
                iconBeforeElement={<Icons.IconLockOpen />}
                isDisabled={!allowUnlock}
                onClick={() => onUnlock(selectedRows.filter((coop) => coop.locked))}
                appearance="tertiary"
                id="unlockCooperatorsButton"
            >
                Unlock
            </Button>
            <Button
                iconBeforeElement={<Icons.IconDelete />}
                isDisabled={!allowDelete}
                onClick={() => onDelete(selectedRows)}
                appearance="tertiary-danger"
                id="deleteCooperatorsButton"
            >
                Delete
            </Button>
        </ButtonGroup>
    );
}

ManageCooperatorsTableActions.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onLock: PropTypes.func.isRequired,
    onUnlock: PropTypes.func.isRequired,
    selectedFlatRows: PropTypes.arrayOf(
        PropTypes.shape({
            original: PropTypes.object,
        })
    ).isRequired,
};
