/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

export const initialState = {
    managedConcessionaire: {},
    concessionairePermit: {},
};

const manageConcessionairesSet = (state = initialState, action) => {
    switch (action.type) {
        case types.RELOAD_MANAGED_CONCESSIONAIRES_SET:
            return initialState;

        case types.SET_MANAGED_CONCESSIONAIRE:
            return { ...state, managedConcessionaire: action.concessionaire };
        case types.SET_CONCESSIONAIRE_PERMIT:
            return { ...state, concessionairePermit: action.permit };

        case types.SAVING_MANAGED_CONCESSIONAIRES_FAILED:
            return { ...state, managedConcessionaire: {} };
        case types.LOGGED_OUT:
            return initialState;

        default:
            return state;
    }
};

export default manageConcessionairesSet;
