/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, useFlags, Alert } from 'sarsaparilla';
import { errorForEnablingPasswordReset } from '../../utilities/errorMessages';

const propTypes = {
    accountToSave: PropTypes.shape({
        locked: PropTypes.bool,
        is_email_confirmed: PropTypes.bool,
        user_id: PropTypes.string,
        enforce_effective_dates: PropTypes.bool,
        effective_start_at: PropTypes.string,
        effective_end_at: PropTypes.string,
    }),
    creating: PropTypes.bool,
    profile: PropTypes.any,
    errorEnablingPasswordReset: PropTypes.any,
    userIdEnabledPasswordReset: PropTypes.any,
    resetPassword: PropTypes.func.isRequired,
};

export default function ResetPassword({
    accountToSave,
    creating,
    profile,
    errorEnablingPasswordReset,
    userIdEnabledPasswordReset,
    resetPassword,
}) {
    const { enableIaEffectiveDates } = useFlags();

    if (
        !accountToSave ||
        accountToSave.locked ||
        creating ||
        profile ||
        !accountToSave.is_email_confirmed
    ) {
        return null;
    }

    let buttonDescription =
        'If a user is having trouble resetting a password and logging in, then use the ' +
        '"Reset Password" button to send a reset password email to the user and enable login.';

    const isError = errorEnablingPasswordReset !== null;
    const isSuccess =
        userIdEnabledPasswordReset &&
        userIdEnabledPasswordReset === accountToSave.user_id;

    if (isError) {
        buttonDescription = errorForEnablingPasswordReset(errorEnablingPasswordReset);
    } else if (isSuccess) {
        buttonDescription = 'Reset Password email successfully sent.';
    }

    const alertType = isError ? 'error' : isSuccess ? 'success' : undefined;

    const enforceEffectiveDates =
        enableIaEffectiveDates && accountToSave?.enforce_effective_dates;
    const accountInactive = moment().isBefore(accountToSave?.effective_start_at);
    const accountExpired = moment().isAfter(accountToSave?.effective_end_at);
    const isDisabled = enforceEffectiveDates && (accountInactive || accountExpired);

    return (
        <div className="update-user-options">
            <div className="update-user-options-controls">
                <Button
                    className="update-user-options-button"
                    appearance="tertiary"
                    onClick={() => resetPassword(accountToSave.user_id)}
                    disabled={isDisabled}
                >
                    Reset Password
                </Button>

                {alertType ? (
                    <Alert shouldFocusOnMount type={alertType}>
                        {buttonDescription}
                    </Alert>
                ) : (
                    <div className="update-user-options-message">{buttonDescription}</div>
                )}
            </div>
        </div>
    );
}

ResetPassword.propTypes = propTypes;
