/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

export const initialState = {
    managedCooperator: {},
    cooperatorEmployee: {},
};

const manageCooperatorsSet = (state = initialState, action) => {
    switch (action.type) {
        case types.RELOAD_MANAGED_COOPERATORS_SET:
            return initialState;

        case types.SET_MANAGED_COOPERATOR:
            return { ...state, managedCooperator: action.cooperator };
        case types.SET_COOPERATOR_EMPLOYEE:
            return { ...state, cooperatorEmployee: action.employee };

        case types.SAVING_MANAGED_COOPERATORS_FAILED:
            return { ...state, managedCooperator: {} };
        case types.LOGGED_OUT:
            return initialState;

        default:
            return state;
    }
};

export default manageCooperatorsSet;
