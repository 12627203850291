/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { concessionaireHierarchySelector } from '../reducers/concessionaireHierarchy';
import { initHierarchy } from '../actions/concessionaireHierarchy';
import { addTreeData } from '../utilities/locationPickerTree';

export default function useInitLocationPickerLocations({ useFullHierarchy = false }) {
    const dispatch = useDispatch();
    const reduxState = useSelector(concessionaireHierarchySelector);

    const roles = useSelector((state) => state.login?.user?.roles || []);

    const defaultState = useMemo(
        () => ({
            dataSource: roles.map((role) => addTreeData(role.location)),
            loaded: true,
            loading: false,
            error: null,
        }),
        [roles]
    );

    // state may be null in unit tests
    useEffect(() => {
        const neverCalled = reduxState && !reduxState.loaded && !reduxState.loading;
        if (useFullHierarchy && neverCalled) {
            dispatch(initHierarchy({ initDataSource: defaultState.dataSource }));
        }
    }, [useFullHierarchy]);
    if (useFullHierarchy) {
        return reduxState;
    }
    return defaultState;
}
