/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { Icons, Spinner, TextFieldStateless } from '@fp/sarsaparilla';
import { ExpandableLocationSelector } from './locationPicker/ExpandableLocationSelector';
import useLocationDataSource from '../hooks/useLocationDataSource';
import useIsLoggedInUserAConcessionaire from '../hooks/useIsLoggedInUserAConcessionaire';
import { getNext, getPrevious } from '../helpers/launchLocationSelect';

const propTypes = {
    id: PropTypes.string,
    collapsed: PropTypes.bool,
    selectedLocation: PropTypes.object,
    locationSelect: PropTypes.func,
    allowAllLocationSelect: PropTypes.bool,
    showReservableLocations: PropTypes.bool,
    visibleLocationTypes: PropTypes.arrayOf(PropTypes.string),
    selectableLocationTypes: PropTypes.arrayOf(PropTypes.string),
    disableConcessionaireHierarchy: PropTypes.bool,
    allowHierarchyFillSelection: PropTypes.bool,
};

export default function LaunchLocationSelect({
    id,
    collapsed,
    selectedLocation,
    locationSelect,
    allowAllLocationSelect,
    showReservableLocations,
    visibleLocationTypes,
    selectableLocationTypes,
    disableConcessionaireHierarchy = false,
    allowHierarchyFillSelection = false,
}) {
    const pickerMenuRef = React.useRef(null);
    const [expansionMap, setExpansionMap] = React.useState({});
    const [locationInputValue, setLocationInputValue] = React.useState('');
    const useFullHierarchy =
        useIsLoggedInUserAConcessionaire() && !disableConcessionaireHierarchy;
    const { dataSource, setSearchValue, fetchChildren, loading } = useLocationDataSource({
        useFullHierarchy,
    });

    function handleKeyDown(event) {
        const locationPickerMenu = pickerMenuRef.current;
        const focusedElement = document.activeElement;
        const isArrowKey = event.key === 'ArrowDown' || event.key === 'ArrowUp';

        if (locationPickerMenu && isArrowKey) {
            event.preventDefault();

            const options = locationPickerMenu.querySelectorAll(
                '.expandable-location-row button.location-picker-button'
            );

            if (!options) return;

            let activeIndex = -1;
            options.forEach((option, index) => {
                if (option === focusedElement) {
                    activeIndex = index;
                }
            });

            if (event.key === 'ArrowDown') {
                const nextOption = getNext(options, activeIndex);

                nextOption?.focus();
            }

            if (event.key === 'ArrowUp') {
                const previousOption = getPrevious(options, activeIndex);

                previousOption?.focus();
            }
        }
    }

    React.useEffect(() => {
        if (!collapsed) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [collapsed]);

    function onLocationExpandToggled(location, isExpanded) {
        if (isExpanded && !location.isHierarchyFill) {
            fetchChildren(location);
        }

        const updatedMap = { ...expansionMap, [location.id]: isExpanded };

        setExpansionMap(updatedMap);
    }

    const checkHierarchyLocationChange = (oldLoc, newLoc) =>
        !oldLoc ||
        (newLoc &&
            newLoc.location_id !== oldLoc.location_id &&
            newLoc.location_parent_id !== oldLoc.location_parent_id);

    const rebuildHierarchyForExpansionMap = (location) => {
        let depth = 1;
        const newMap = {};
        if (location) {
            location.location_path.forEach((parent) => {
                if (parent.location_id !== '1')
                    newMap[`${parent.location_id}_${parent.location_type}_${depth}`] =
                        true;
                depth += 1;
            });
            newMap[location.id] = true;
        }

        return newMap;
    };

    const onLocationSelect = (row, facility) => {
        //collapse locations on the list if the new selected location's parent
        // is different from the previous selected location's parent
        let newMap = null;
        if (checkHierarchyLocationChange(selectedLocation, facility))
            newMap = rebuildHierarchyForExpansionMap(facility);
        else if (facility && checkHierarchyLocationChange(selectedLocation, row))
            newMap = rebuildHierarchyForExpansionMap(facility);

        if (newMap) setExpansionMap(newMap);

        locationSelect(row, facility);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceInputValue = React.useCallback(
        debounce((value) => {
            setSearchValue(value);
        }, 800),
        []
    );

    function handleInputChange(e) {
        e.persist();
        setLocationInputValue(e.target.value);
        debounceInputValue.cancel();
        debounceInputValue(e.target.value);
    }

    const clearClick = (e) => {
        e.stopPropagation();
        setLocationInputValue('');
        setSearchValue('');
        setExpansionMap({});
    };

    return (
        <div
            key={'location-select-div'}
            className={classNames('launch-location-select-grid', { collapsed })}
            id="launch-location-select-grid"
        >
            <div className="launch-location-wrapper">
                <div
                    className={classNames('location-picker-search', {
                        'location-loading': loading,
                    })}
                >
                    {collapsed || (
                        <TextFieldStateless
                            id={id || 'locationPickerSearchField'}
                            className="location-picker-search-input"
                            value={locationInputValue}
                            placeholder="Search Locations..."
                            isLabelVisible={false}
                            hasClearButton
                            shouldFocusOnMount
                            handleClearButtonClick={clearClick}
                            onChange={handleInputChange}
                            iconElement={<Icons.IconSearch />}
                            autoComplete="off"
                            label="Location Search Input"
                        />
                    )}

                    <div className="rec-loader-wrap">
                        <Spinner size="xs" color="danger" />
                    </div>
                </div>

                <div className="location-picker-grid" ref={pickerMenuRef}>
                    <ExpandableLocationSelector
                        expansionMap={expansionMap}
                        onLocationSelection={onLocationSelect}
                        onLocationExpandChange={onLocationExpandToggled}
                        maxDepth={10}
                        allowAllLocationSelect={allowAllLocationSelect}
                        showReservableLocations={showReservableLocations}
                        visibleLocationTypes={visibleLocationTypes}
                        allowHierarchyFillSelection={allowHierarchyFillSelection}
                        selectableLocationTypes={selectableLocationTypes}
                        dataSource={dataSource}
                    />
                </div>
            </div>
        </div>
    );
}

LaunchLocationSelect.propTypes = propTypes;
