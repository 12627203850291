/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Text } from 'sarsaparilla';
import MfaQrCodePlaceholder from '../../img/mfa-qr-code-placeholder.png';

const propTypes = {
    hideStartOverButton: PropTypes.string,
    mfaManualEntry: PropTypes.string,
    qrCode: PropTypes.string,
    nextStep: PropTypes.func,
    startOver: PropTypes.func,
};

type MfaEnrollmentSetupScanProps = {
    hideStartOverButton?: boolean;
    mfaManualEntry: string;
    qrCode: string;
    nextStep: () => void;
    startOver: () => void;
};

function MfaEnrollmentSetupScan({
    hideStartOverButton,
    mfaManualEntry,
    qrCode,
    nextStep,
    startOver,
}: MfaEnrollmentSetupScanProps) {
    const imgSrc = qrCode ? `data:image/png;base64,${qrCode}` : MfaQrCodePlaceholder;
    return (
        <>
            <p className="mt-3 ml-8 mb-4 mr-8">
                <Heading headingLevel={4} appearance="h4">
                    Set Up Multi-Factor Authentication
                </Heading>
            </p>
            <p className="ml-3 mr-3">
                <Text size="sm">
                    <b>Step 2.</b> To activate, use your agency mandated multi-factor
                    authentication application from Step 1 to scan this QR code. You can
                    also enter the 2-Factor Authentication(2FA) key shown below into your
                    application manually.
                </Text>
            </p>
            <p className="ml-8 mr-8">
                <img src={imgSrc} alt="QR Code" width={128} height={128} />
            </p>
            <p className="ml-2 mr-2 mb-3">
                <Text size="sm">
                    <b>2FA Key (Manual entry)</b>
                </Text>
                <Text size="sm">{mfaManualEntry}</Text>
            </p>
            <p>
                <Button onClick={nextStep}>Next</Button>
                <Text size="sm" className="mt-2 mb-3">
                    Click Next to proceed to the last set up step.
                </Text>
            </p>
            <p>
                <Text size="sm">
                    <b>Warning:</b> You cannot see this screen again after you click Next.
                </Text>
            </p>
            {!hideStartOverButton && (
                <p>
                    <Text size="sm">
                        <a
                            href="/internal/account/login"
                            className="rec-announcement-link-btn"
                            onClick={startOver}
                            rel="noreferrer"
                        >
                            Start over
                        </a>{' '}
                        and return to login page
                    </Text>
                </p>
            )}
        </>
    );
}

MfaEnrollmentSetupScan.propTypes = propTypes;

export default MfaEnrollmentSetupScan;
