/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export const isDescendant = (parent: ParentNode, child: ChildNode): boolean => {
    let node = child.parentNode;
    while (node != null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
};

export const htmlToPlainText = (html: string): string => {
    if (html) {
        return html.replace(/<\/?[^>]+(>|$)/g, ' ');
    }
    return '';
};
