/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'sarsaparilla';

export default function Location({
    handleMouseOver,
    handleClick,
    selectable,
    locationName,
}) {
    const [shouldRenderInATooltip, setShouldRenderInATooltip] = React.useState(false);
    const buttonRef = React.useRef();

    React.useEffect(() => {
        const contentWrapper = buttonRef.current?.querySelector('.sarsa-button-content');

        if (contentWrapper) {
            const { offsetWidth, scrollWidth } = contentWrapper;

            if (scrollWidth > offsetWidth) {
                setShouldRenderInATooltip(true);
            }
        }
    }, []);

    if (shouldRenderInATooltip) {
        return (
            <Tooltip content={locationName}>
                <Button
                    shouldFitContainer
                    onMouseOver={handleMouseOver}
                    className="location-picker-button location-display-name"
                    onClick={handleClick}
                    disabled={!selectable}
                    screenReaderTextBefore="Select location"
                    appearance="link"
                >
                    {locationName}
                </Button>
            </Tooltip>
        );
    }

    return (
        <Button
            shouldFitContainer
            ref={buttonRef}
            onMouseOver={handleMouseOver}
            className="location-picker-button location-display-name"
            onClick={handleClick}
            disabled={!selectable}
            screenReaderTextBefore="Select location"
            appearance="link"
        >
            {locationName}
        </Button>
    );
}

Location.propTypes = {
    handleMouseOver: PropTypes.func,
    handleClick: PropTypes.func,
    selectable: PropTypes.bool,
    locationName: PropTypes.string,
};
