/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import configure from 'redux-idle-monitor';
import { IDLE_STATUSES } from './constants';
import { idleStatusDelay, idleStatusAction } from './actions';

// These are the default events that will trigger user active status but can be customized if provided.
const activeEvents = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mouseWheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
];

const opts = {
    appName: 'todo-app',
    IDLE_STATUSES,
    idleStatusDelay,
    idleStatusAction,
    activeEvents,
};

const { middleware, reducer, actions } = configure(opts);
export { middleware, reducer, actions };

let currentIsAuthorized;
export const storeSubscription = (store) => {
    const previousIsAuthorized = currentIsAuthorized;
    const state = store.getState();

    currentIsAuthorized = state.login ? state.login.loggedIn : false;

    if (currentIsAuthorized !== previousIsAuthorized) {
        store.dispatch((currentIsAuthorized ? actions.start : actions.stop)());
    }
};
