/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import {
    Heading,
    BlockTabs,
    Button,
    HelmetWrapperInternal,
    Icons,
    Panel,
    TYPES,
} from 'sarsaparilla';

class PageHeader extends React.PureComponent {
    static propTypes = {
        history: TYPES.HISTORY,
        title: PropTypes.string.isRequired,
        selectedTabIndex: PropTypes.number,
        navBack: PropTypes.string,
        breadcrumbs: PropTypes.array,
        tabs: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                ariaLabel: PropTypes.string,
                onClick: PropTypes.func,
                selectedSubTabIndex: PropTypes.number,
                subTabs: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        onClick: PropTypes.func,
                    })
                ),
            })
        ),
        children: PropTypes.any,
    };

    constructor(props) {
        super(props);

        this.onTabClick = this.onTabClick.bind(this);
        this.createTab = this.createTab.bind(this);
        this.createSubTab = this.createSubTab.bind(this);
    }

    onNavBackClick = () => {
        const url = this.props.navBack;

        if (url.length === 0) {
            this.props.history.goBack();
        } else {
            this.props.history.push(url);
        }
    };

    onTabClick(index) {
        const tab = this.props.tabs[index];
        if (tab.onClick) {
            tab.onClick(index);
        }
    }

    onSubTabClick(index) {
        const tab = this.props.tabs[this.props.selectedTabIndex].subTabs[index];
        if (tab.onClick) {
            tab.onClick(index);
        }
    }

    tabsUI() {
        const tabCheck =
            this.props.tabs && this.props.selectedTabIndex
                ? this.props.tabs[this.props.selectedTabIndex]
                : null;
        const classes = classNames({
            'rec-tab-list': true,
            'ia-rec-tab-list': true,
            'ia-rec-tab-list-with-subs': tabCheck && tabCheck.subTabs,
        });
        if (this.props.tabs && this.props.tabs.length > 0) {
            return (
                <div className={classes}>
                    <ul className="rec-tabs site-nav-tabs">
                        {this.props.tabs.map(this.createTab)}
                    </ul>
                </div>
            );
        }

        return null;
    }

    createTab(tab, index) {
        const key = `page-header-tab-${index}`;

        const isSelectedTab = index === this.props.selectedTabIndex;
        const classes = classNames({
            'rec-tab': true,
            'rec-tab-active': isSelectedTab,
        });

        return (
            <li key={key} className={classes}>
                <button
                    type="button"
                    className="rec-tab-link"
                    aria-label={tab.ariaLabel}
                    onClick={() => this.onTabClick(index)}
                >
                    {tab.title}
                </button>
            </li>
        );
    }

    subTabsUI() {
        if (this.props.tabs && this.props.tabs.length > this.props.selectedTabIndex) {
            const selectedTab = this.props.tabs[this.props.selectedTabIndex];

            if (selectedTab.subTabs) {
                return (
                    <div className="page-header-sub-tabs">
                        <BlockTabs
                            className="rec-content-switcher"
                            activeIndex={selectedTab.selectedSubTabIndex}
                            selectedIndexChanged={(i) => this.onSubTabClick(i)}
                        >
                            {selectedTab.subTabs.map(this.createSubTab)}
                        </BlockTabs>
                    </div>
                );
            }
        }

        return null;
    }

    createSubTab(subTab, index) {
        const key = `page-header-sub-tab-${index}`;

        const selectedTab = this.props.tabs[this.props.selectedTabIndex];
        const selectedSubTabIndex = selectedTab.selectedSubTabIndex;

        const classes = classNames({
            active: index === selectedSubTabIndex,
            'page-header-sub-tab': true,
        });

        return <Panel key={key} className={classes} label={subTab.title} />;
    }

    renderBreadcrumbs() {
        if (!this.props.breadcrumbs) {
            return null;
        }

        const crumbs = [];
        this.props.breadcrumbs.forEach((crumb, i) => {
            if (crumb.onClick) {
                crumbs.push(
                    <Button
                        size="xs"
                        appearance="link"
                        className="button-as-anchor"
                        onClick={crumb.onClick}
                        key={`crumb_${i}`}
                    >
                        {crumb.label}
                    </Button>
                );
            } else {
                crumbs.push(<span key={`crumb_${i}`}>{crumb.label}</span>);
            }

            if (i + 1 !== this.props.breadcrumbs.length) {
                crumbs.push(<span className="breadcrumb-spacer" key={`spacer_${i}`} />);
            }
        });

        return <div className="page-breadcrumbs">{crumbs}</div>;
    }

    renderHeaderTitle() {
        let headerToRender;
        if (this.props.navBack) {
            headerToRender = (
                <Button
                    appearance="link"
                    className="page-header-nav-back mr-2"
                    screenReaderTextBefore="Go Back to Previous Page"
                    onClick={this.onNavBackClick.bind(this)}
                    iconBeforeElement={<Icons.IconArrowBack />}
                />
            );
        }

        return (
            <div className="page-header-back-title-grid my-5">
                {headerToRender}
                <Heading headingLevel={1} appearance="h3">
                    {this.props.title}
                </Heading>
            </div>
        );
    }

    render() {
        return (
            <div className="page-header-wrapper">
                <HelmetWrapperInternal title={this.props.title} />
                <div className="page-header">
                    <div className="page-header-left">
                        {this.renderBreadcrumbs()}
                        {this.renderHeaderTitle()}
                        {this.tabsUI()}
                    </div>
                    {this.props.children}
                </div>
                {this.subTabsUI()}
            </div>
        );
    }
}

export default withRouter(PageHeader);
