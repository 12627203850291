/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

const FS: string = 'Forest Service';
const NPS: string = 'National Park Service';
const USACE: string = 'US Army Corps of Engineers';
const BLM: string = 'Bureau of Land Management';
const NARA: string = 'National Archives & Records Administration';
const FWS: string = 'Fish and Wildlife Service';
const BOR: string = 'Bureau of Reclamation';
const BEP: string = 'Bureau of Engraving and Printing';
const FHA: string = 'Federal Highway Administration (Scenic Byways)';
const NOAA: string = 'National Oceanic & Atmospheric Administration';
const SI: string = 'Smithsonian Institution';
const USGS: string = 'US Geological Survey';
const R1S: string = 'Recreation One Stop';
const PARTNER: string = 'Outside Partner';
const OTHER: string = 'Miscellaneous';

type AgencyDropdownOptions = {
    label: string;
    value: string;
};

const agencyDropdown: AgencyDropdownOptions[] = [
    {
        label: FS,
        value: FS,
    },
    {
        label: NPS,
        value: NPS,
    },
    {
        label: USACE,
        value: USACE,
    },
    {
        label: BLM,
        value: BLM,
    },
    {
        label: NARA,
        value: NARA,
    },
    {
        label: FWS,
        value: FWS,
    },
    {
        label: BOR,
        value: BOR,
    },
    {
        label: BEP,
        value: BEP,
    },
    {
        label: FHA,
        value: FHA,
    },
    {
        label: NOAA,
        value: NOAA,
    },
    {
        label: SI,
        value: SI,
    },
    {
        label: USGS,
        value: USGS,
    },
    {
        label: R1S,
        value: R1S,
    },
    {
        label: PARTNER,
        value: PARTNER,
    },
    {
        label: OTHER,
        value: OTHER,
    },
];

export default agencyDropdown;
