/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { WebStorage } from 'redux-persist/es/types';
import { PERSIST_KEY } from './constants/globals';

import contactReducer from './reducers/contact';
import userLoginReducer from './reducers/login';
import loadingReducer from './reducers/loading';
import updateUserReducer from './reducers/updateUser';
import fetchManagedLocationsReducer from './reducers/fetchManagedLocations';
import fetchManagedConcessionairesReducer from './reducers/fetchManagedConcessionaires';
import resetPasswordReducer from './reducers/resetPassword';
import fetchUsersReducer from './reducers/fetchUsers';
import userManagementReducer from './reducers/userManagement';
import notesReducer from './reducers/notes';
import confirmEmailReducer from './reducers/confirmEmail';
import changePasswordReducer from './reducers/changePassword';
import selectedLocationRoleReducer from './reducers/selectedLocationRole';
import alertsNotificationReducer from './reducers/alertsNotificationReducer';
import fetchChildLocationsReducer from './reducers/fetchChildLocations';
import manageConcessionairesReducer from './reducers/manageConcessionaires';
import manageConcessionairesSetReducer from './reducers/manageConcessionairesSet';
import manageCooperatorsReducer from './reducers/manageCooperators';
import manageCooperatorsSetReducer from './reducers/manageCooperatorsSet';
import fetchEntrancesForLocationReducer from './reducers/fetchEntrancesForLocation';
import facilityInfoReducer from './reducers/facilityInfo';
import attestationReducer from './reducers/attestation';
import * as idleMonitor from './utilities/redux-idle-monitor';
import concessionaireHierarchy from './reducers/concessionaireHierarchy';
import machineApiReducer from './reducers/machineApi';
import mfaReducer from './reducers/mfa';
import toastReducer from './reducers/toastReducer';

type Config = {
    key: string;
    storage: WebStorage;
};

type InternalAccountReducers = {
    alertsNotification: Reducer;
    attestation: Reducer;
    contact: Reducer;
    concessionaireHierarchy: Reducer;
    loading: Reducer;
    login: Reducer;
    resetPassword: Reducer;
    updateUser: Reducer;
    fetchUsers: Reducer;
    notes: Reducer;
    confirmEmail: Reducer;
    changePassword: Reducer;
    userManagement: Reducer;
    selectedLocationRole: Reducer;
    fetchManagedLocations: Reducer;
    fetchChildLocations: Reducer;
    fetchManagedConcessionaires: Reducer;
    manageConcessionaires: Reducer;
    manageConcessionairesSet: Reducer;
    manageCooperators: Reducer;
    manageCooperatorsSet: Reducer;
    facilityInfo: Reducer;
    idle: Reducer;
    fetchEntrancesForLocation: Reducer;
    machineApi: Reducer;
    mfa: Reducer;
    toast: Reducer;
};
const getPersistConfig = (namespace: string): Config => {
    const config: Config = {
        key: `${PERSIST_KEY}:${namespace}`,
        storage,
    };

    return config;
};

export const whitelistKeys: string = ''; // put an empty string here because this variable is imported by a lot of files

export default function reducers(): InternalAccountReducers {
    return {
        alertsNotification: alertsNotificationReducer,
        attestation: attestationReducer,
        contact: contactReducer,
        concessionaireHierarchy,
        loading: loadingReducer,
        login: persistReducer(getPersistConfig('login'), userLoginReducer),
        resetPassword: resetPasswordReducer,
        updateUser: persistReducer(getPersistConfig('updateUser'), updateUserReducer),
        fetchUsers: fetchUsersReducer,
        notes: notesReducer,
        confirmEmail: confirmEmailReducer,
        changePassword: changePasswordReducer,
        userManagement: persistReducer(
            getPersistConfig('userManagement'),
            userManagementReducer
        ),
        selectedLocationRole: persistReducer(
            getPersistConfig('selectedLocationRole'),
            selectedLocationRoleReducer
        ),
        fetchManagedLocations: fetchManagedLocationsReducer,
        fetchChildLocations: fetchChildLocationsReducer,
        fetchManagedConcessionaires: fetchManagedConcessionairesReducer,
        manageConcessionaires: manageConcessionairesReducer,
        manageConcessionairesSet: persistReducer(
            getPersistConfig('manageConcessionairesSet'),
            manageConcessionairesSetReducer
        ),
        manageCooperators: manageCooperatorsReducer,
        manageCooperatorsSet: persistReducer(
            getPersistConfig('manageCooperatorsSet'),
            manageCooperatorsSetReducer
        ),
        facilityInfo: persistReducer(
            getPersistConfig('facilityInfo'),
            facilityInfoReducer
        ),

        idle: idleMonitor.reducer,
        fetchEntrancesForLocation: fetchEntrancesForLocationReducer,
        machineApi: persistReducer(getPersistConfig('machineApi'), machineApiReducer),
        mfa: persistReducer(getPersistConfig('mfa'), mfaReducer),
        toast: toastReducer,
    };
}
