/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export function getNext(options, currentIndex) {
    // Starting at the index after the current one, find the next non-disabled option
    for (let i = currentIndex + 1; i < options.length; i += 1) {
        if (!options[i].disabled) {
            return options[i];
        }
    }

    // We didn't find it, so we start from the beginning and find the first non-disabled option
    for (let i = 0; i < currentIndex; i += 1) {
        if (!options[i].disabled) {
            return options[i];
        }
    }

    // We didn't find anything, so return the current item
    return options[currentIndex];
}

export function getPrevious(options, currentIndex) {
    // Starting at the index before the current one, find the previous non-disabled option
    for (let i = currentIndex - 1; i >= 0; i -= 1) {
        if (!options[i].disabled) {
            return options[i];
        }
    }

    // We didn't find it, so we start from the end and keep looking
    for (let i = options.length - 1; i > currentIndex; i -= 1) {
        if (!options[i].disabled) {
            return options[i];
        }
    }

    // We didn't find anything, so return the current item
    return options[currentIndex];
}
