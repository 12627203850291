/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios from 'axios';
import { InternalSecurityUtils, makeAuthorizedRequest } from 'ui-internal-account';
import { getLocalTimeZone } from '@internationalized/date';
import * as globals from '../constants/globals';

export const createRangerAppToken = (request) => async (dispatch) => {
    const url = `${globals.API_URL}/ranger-app/token`;
    const payload = {
        timezone: getLocalTimeZone(),
        roles: Object.fromEntries(request),
    };
    return makeAuthorizedRequest(url, 'POST', dispatch, payload);
};

export const fetchRangerAppTokens = (request) => async (dispatch) => {
    const { location_id, type } = request;

    const url = `${globals.API_URL}/ranger-app/token`;

    if (type === 'LOCATION') {
        return makeAuthorizedRequest(
            `${url}/location/${location_id}`,
            'GET',
            dispatch,
            {}
        );
    }
    if (type === 'CREATOR') {
        return makeAuthorizedRequest(url, 'GET', dispatch, {});
    }
    return makeAuthorizedRequest(url, 'GET', dispatch, {});
};

export const expireRangerAppToken = (id) => async (dispatch) => {
    const url = `${globals.API_URL}/ranger-app/token/${id}/expire`;
    return makeAuthorizedRequest(url, 'PATCH', dispatch, {});
};

export const setRangerAppTokenUsername = (request) => async (dispatch) => {
    const { token_id, device_nickname } = request;
    const url = `${globals.API_URL}/ranger-app/token/${token_id}`;
    return makeAuthorizedRequest(url, 'PATCH', dispatch, {
        device_nickname,
    });
};

export const getLocationNames = (payload) => async (dispatch) => {
    const url = `${globals.API_INVENTORY_URL}/location/names`;
    return makeAuthorizedRequest(`${url}`, 'POST', dispatch, {
        location_ids: payload,
    });
};

export const setQrCodeTokenRangerApp = async (token_id, qrCode, qrCodeID) => {
    const url = `${globals.API_URL}/ranger-app/token/${token_id}/qrcode`;
    await axios.patch(
        url,
        {
            code: qrCode,
            qr_code_id: qrCodeID,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: InternalSecurityUtils.getAuthHeader(),
            },
        }
    );
};

export const generateQrCodeTokenRangerApp = async (token_id) => {
    const url = `${process.env.API}/qr-code/internal/qr-codes`;
    const { data } = await axios.post(
        url,
        { token_id },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: InternalSecurityUtils.getAuthHeader(),
            },
        }
    );
    return data;
};

export const generateQrCodeImageRangerApp = async (qr_code_id, code) => {
    const url = `${process.env.API}/qr-code/internal/qr-codes/${encodeURIComponent(qr_code_id)}/png?download=false&code=${encodeURIComponent(code)}`;
    const { data } = await axios.get(url, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            Authorization: InternalSecurityUtils.getAuthHeader(),
        },
    });
    return data;
};
