/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Icons, Button } from 'sarsaparilla';

export function Expander({
    expanded,
    visible,
    onChange,
    screenReaderText,
    isLoading,
    setFocused,
}) {
    const size = 'lg';

    if (!visible) {
        return <div className="spacer-for-no-expander" />;
    }

    let icon = <Icons.IconChevronRight size={size} />;

    if (expanded) {
        icon = <Icons.IconChevronDown size={size} data-testid="chevronDown" />;
    }

    return (
        <Button
            size="xs"
            className="expander-button"
            appearance="subtle"
            isLoading={isLoading}
            onClick={onChange}
            iconBeforeElement={icon}
            screenReaderTextBefore={screenReaderText}
            onFocus={() => {
                setFocused(true);
            }}
            onBlur={() => {
                setFocused(false);
            }}
            onMouseEnter={() => {
                setFocused(true);
            }}
            onMouseLeave={() => {
                setFocused(false);
            }}
        />
    );
}

Expander.propTypes = {
    expanded: PropTypes.bool,
    visible: PropTypes.bool,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    setFocused: PropTypes.func,
    screenReaderText: PropTypes.string,
};
