/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export default `Transport(s) at the location: Gen5
Hardware at the location: HT2000 with Hr4700G appliance
Is Location Hard Down (Y/N):
Is the problem Intermittent (Y/N):
Power confirmed for HT2000 Modem, LED lit (Y/N):
Power confirmed for HR4700G Router, LED lit (Y/N):
Check cable connections (Y/N):
Confirmed PC is configured for DHCP (Y/N):
Heavy Rain or storms in the area (Y/N):
When did problem start (Date/Time):
Is the POS functional (Y/N):
Dispatch Pre-Approved (Y/N): Yes
Primary POC for this issue (Name/Phone):
Secondary POC for this issue (Name/Phone):
BAH (SNOW) Case ID:
Description of problem:
If a speed test can be performed, state the results:
`;
