/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    ButtonGroup,
    Checkbox,
    Icons,
    Spacer,
    TextFieldStateless,
    TYPES,
} from 'sarsaparilla';
import { errorForConcessionaires } from '../../utilities/errorMessages';
import { getAgencyNamesFromLocations } from '../../utilities/locations';
import ActionErrorDisplay from '../ActionErrorDisplay';
import TextHighlight from '../tableUtil/TextHighlight';
import ColumnFilterTable from '../tableUtil/ColumnFilterTable';
import AllAgenciesDropdown from './AllAgenciesDropdown';

export default function ManageConcessionaires({
    history,
    error,
    dataGrid,
    setConcessionaire,
}) {
    function navAddConcessionaire() {
        setConcessionaire({});
        history.push('/internal/account/concessionaire-edit');
    }

    function navEditConcessionaire(conc) {
        setConcessionaire(conc);
        history.push('/internal/account/concessionaire-edit');
    }

    function navConcessionaireDetails(conc) {
        setConcessionaire(conc);
        history.push('/internal/account/concessionaire-details');
    }

    function getConcessionaireNameForGridDisplay(conc) {
        const result = conc.concessionaire_name || '';
        return result.replaceAll(conc.concessionaire_id, ' ');
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                id: 'id',
                accessor: (row) => row.concessionaire_id.toString(),
                Cell: ({ row, value, state: { globalFilter } }) => (
                    <Button
                        onClick={() => navConcessionaireDetails(row.original)}
                        appearance="link"
                    >
                        <TextHighlight searchTerm={globalFilter || ''} body={value} />
                    </Button>
                ),
                disableFilters: true,
                width: 50,
                maxWidth: 60,
                minWidth: 50,
            },
            {
                Header: 'Concessionaire Name',
                id: 'concessionaire_name',
                disableFilters: true,
                accessor: getConcessionaireNameForGridDisplay,
                Cell: ({ row, value, state: { globalFilter } }) => (
                    <Button
                        onClick={() => navConcessionaireDetails(row.original)}
                        appearance="link"
                    >
                        <TextHighlight searchTerm={globalFilter || ''} body={value} />
                    </Button>
                ),
                width: 90,
                minWidth: 90,
            },
            {
                Header: 'Agency',
                id: 'agency',
                accessor: (row) => getAgencyNamesFromLocations(row.permits).join(', '),
                Cell: ({ value, state: { globalFilter } }) => (
                    <TextHighlight body={value} searchTerm={globalFilter} />
                ),
                disableFilters: true,
                width: 50,
                maxWidth: 160,
                minWidth: 50,
            },
            {
                Header: '',
                id: 'locations',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ row, state: { globalFilter } }) => (
                    <AllAgenciesDropdown
                        permits={row.original.activePermits}
                        id={`locations-dropdown-${row.id}`}
                        searchText={globalFilter}
                    />
                ),
                accessor: (row) =>
                    row.activePermits
                        ?.map((permit) => permit.location_name)
                        .filter((val) => val.length > 0)
                        .join(', '),
                width: 50,
                minWidth: 50,
            },
            {
                Header: 'Active Permits',
                id: 'active_permits',
                accessor: (row) => row.activePermits?.length,
                filter: (rows, id, filterValue) =>
                    rows.filter((row) => !!filterValue || row.values[id] > 0),
                Filter: ({ column: { filterValue, setFilter, id } }) => (
                    <Checkbox
                        label="Show Concessionaires with Expired Permits"
                        isChecked={!!filterValue}
                        onChange={() => setFilter(!filterValue)}
                        id={`filter-${id}`}
                    />
                ),
                disableGlobalFilter: true,
                width: 50,
                maxWidth: 70,
                minWidth: 50,
            },
            {
                Header: 'Expiring Permits (60 Days)',
                id: 'expiring_permits',
                accessor: (row) => row.expiringPermits?.length,
                Cell: ({ value }) => (
                    <div
                        className={
                            value > 0 ? 'expired-column ia-urgent' : 'expired-column'
                        }
                    >
                        {value}
                    </div>
                ),
                disableFilters: true,
                disableGlobalFilter: true,
                width: 50,
                maxWidth: 100,
                minWidth: 50,
            },
            {
                Header: 'Actions',
                id: 'actions',
                disableSortBy: true,
                disableFilters: true,
                disableGlobalFilter: true,
                Cell: ({ row }) => (
                    <ButtonGroup>
                        <Button
                            onClick={() => navConcessionaireDetails(row.original)}
                            appearance="link"
                        >
                            Details
                        </Button>
                        <Button
                            onClick={() => navEditConcessionaire(row.original)}
                            appearance="link"
                        >
                            Edit
                        </Button>
                    </ButtonGroup>
                ),
                width: 50,
                maxWidth: 90,
                minWidth: 50,
            },
        ],
        []
    );

    const initalFilterState = [{ id: 'active_permits', value: false }];

    const filterUI = React.useCallback(
        ({ setGlobalFilter, globalFilter }) => (
            <TextFieldStateless
                label="Search for..."
                isLabelVisible={false}
                placeholder="Search for..."
                onChange={({ target: { value } }) => setGlobalFilter(value)}
                handleClearButtonClick={() => setGlobalFilter('')}
                hasClearButton
                value={globalFilter || ''}
                iconElement={<Icons.IconSearch />}
                id="filter-text-search"
            />
        ),
        []
    );

    return (
        <div className="manage-concessionaires-wrapper">
            <ActionErrorDisplay
                error={error}
                errorStringMapping={errorForConcessionaires}
            />

            <ButtonGroup align="right">
                <Button
                    iconBeforeElement={<Icons.IconAddCircle />}
                    onClick={navAddConcessionaire}
                >
                    Add New Concessionaire
                </Button>
            </ButtonGroup>

            <Spacer size="sm" />

            <ColumnFilterTable
                className="manage-concessionaires-table"
                data={dataGrid || []}
                columns={columns}
                noDataText={`No concessionaires found.`}
                filtersComponent={filterUI}
                initialFilterState={initalFilterState}
                defaultSortId="concessionaire_name"
            />
        </div>
    );
}

ManageConcessionaires.propTypes = {
    history: TYPES.HISTORY,
    error: PropTypes.any,
    dataGrid: PropTypes.array,
    setConcessionaire: PropTypes.func,
};
