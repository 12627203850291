/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { startCase } from 'lodash';
import { AGENCY_LOCATIONS } from 'site-kit';
import { domainToTileMap, decodeLocTypeUrlKeyMap } from './launchTiles';
import * as lc from './locationConstants';
import { AllLeafTypesArr } from './locationConstants';

// these constants are referenced from all over the project, so instead of fully moving them, I'm keeping these wrapper references here.
// Where possible, please refer to the original constant in `locationConstants` instead.
export const R1S_LOCATION_ID = lc.R1S_LOCATION_ID;
export const ROOT = lc.ROOT;
export const REGION = lc.REGION;
export const REGIONAL_DISTRICT = lc.REGIONAL_DISTRICT;
export const ASSET = lc.ASSET;
export const ORGANIZATION = lc.ORGANIZATION;
export const DEFAULT_ADDRESS_TYPE = lc.DEFAULT_ADDRESS_TYPE;
export const AGENCY = lc.AGENCY;
export const CAMPSITE = lc.CAMPSITE;
export const TOUR = lc.TOUR;
export const REC_AREA = lc.REC_AREA;
export const REC_AREA_DISTRICT = lc.REC_AREA_DISTRICT;
export const ACTIVITY_PASS = lc.ACTIVITY_PASS;
export const TREE_PERMIT = lc.TREE_PERMIT;
export const VENUE_RESERVATIONS = lc.VENUE_RESERVATIONS;
export const VENUE = lc.VENUE;
export const AllHighLvlLocationsArr = lc.AllHighLvlLocationsArr;

export const displayStringForLocationType = (locationType) => {
    let displayString = locationType;

    if (!displayString || displayString.trim() === '') {
        // TODO need confirmation on this from UI
        displayString = 'N/A';
    }

    return displayString;
};

export const locationIsUnder = (childLocation, parentLocation) => {
    if (
        parentLocation.location_id === R1S_LOCATION_ID &&
        childLocation.location_id !== R1S_LOCATION_ID
    ) {
        return true;
    }

    if (
        parentLocation.location_type === childLocation.location_type &&
        parentLocation.location_id === childLocation.location_id
    ) {
        return false;
    }

    for (const e of childLocation.location_path) {
        if (
            e.location_id === parentLocation.location_id &&
            e.location_type === parentLocation.location_type
        ) {
            return true;
        }
    }

    return false;
};

export const computeLocationStateCode = (location) => {
    let stateCode = 'N/A';

    if (location && location.location_state_code && location.location_state_code !== '') {
        stateCode = location.location_state_code;
    } else if (
        location &&
        location.location_type_category === ASSET &&
        location.location_attributes
    ) {
        const addresses = location.location_attributes.asset_addresses;
        if (addresses && addresses.length > 0) {
            let defaultAddress = addresses[0];
            for (let i = 0; i < addresses.length; i++) {
                if (addresses[i].asset_address_type === DEFAULT_ADDRESS_TYPE) {
                    defaultAddress = addresses[i];
                    break;
                }
            }

            stateCode = defaultAddress.asset_address_state_code;
        }
    }

    return stateCode;
};

export const locationsAreEqual = (locOne, locTwo) => {
    return (
        locOne.location_id === locTwo.location_id &&
        locOne.location_type === locTwo.location_type
    );
};

export const isFacilityNode = (location) => {
    let ret = false;
    if (location && location.location_type) {
        ret = AllLeafTypesArr.includes(location.location_type);
    }

    return ret;
};

const descriptionIsName = (location) => {
    let ret = false;

    if (location) {
        if (location.location_name && location.location_description) {
            const name = location.location_name.toLowerCase().trim();
            const description = location.location_description.toLowerCase().trim();
            ret = name === description;
        }
    }

    return ret;
};

const decodeHtmlEntity = (str) => {
    return str.replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
    });
};

export const getLocationDisplayName = (location, truncate = true) => {
    let displayName = 'Unknown';
    const parts = [];
    if (location && location.location_type) {
        if (location.location_type === AGENCY) {
            return location.location_name;
        }
        if (location.location_type === ROOT && location.location_description) {
            return startCase(location.location_description);
        }
        if (
            location.location_description &&
            (isFacilityNode(location) || descriptionIsName(location))
        ) {
            displayName = location.location_description;
        } else {
            if (location.location_description) {
                parts.push(location.location_description);
            }
            if (location.location_name) {
                parts.push(location.location_name);
            }

            if (parts.length > 0) {
                displayName = parts.join(': ');
            }
        }
    }

    if (truncate && displayName.length > 80) {
        displayName = `${displayName.substring(0, 80)}...`;
    }

    return decodeHtmlEntity(displayName);
};

export const isReservableLocation = (location) => {
    return location.location_type === CAMPSITE || location.location_type === TOUR;
};

export const compileAddress = (address) => {
    const parts = [];
    if (address) {
        if (address.address1) {
            parts.push(address.address1);
        }
        if (address.address2) {
            parts.push(address.address2);
        }
        if (address.city) {
            parts.push(address.city);
        }
        if (address.state) {
            parts.push(address.state);
        }
        if (address.zip_code) {
            parts.push(address.zip_code);
        }
    }
    return parts.join(', ');
};

export const parseUrlForLocation = (url = window.location.pathname) => {
    const urlParams = {};

    const domainToTileMapArray = Object.values(domainToTileMap);

    for (let i = 0; i < domainToTileMapArray.length; i++) {
        let path = domainToTileMapArray[i].path;
        Object.keys(decodeLocTypeUrlKeyMap).forEach((key) => {
            path = path.replace(key, `\${location_type}`);
        });

        const paths = path.split('/');
        const regex = new RegExp(path.replace(/\${([^}]+)}/g, '(.+)'));
        const matches = url.match(regex);

        if (matches) {
            matches.slice(1, matches.length).forEach((value) => {
                const urls = url.split('/');
                const urlInd = urls.indexOf(value);
                if (urlInd >= 0 && paths.length === urls.length) {
                    const key = paths[urlInd].replace(/[${}]/g, '');
                    urlParams[key] = decodeLocTypeUrlKeyMap[value] || value;
                }
            });

            if (urlParams.location_id) {
                return urlParams;
            }
        }
    }

    return null;
};

export const isLocTopLevelForUser = (userRoles, checkLoc) => {
    let topLevel = false;
    if (userRoles && checkLoc) {
        const rolesAbsent = userRoles.filter((role) => {
            const pathMatches = checkLoc.location_path.slice(0, -1).filter((loc) => {
                return (
                    loc.location_id === role.location.location_id &&
                    loc.location_type === role.location.location_type
                );
            });
            return pathMatches.length > 0;
        });

        if (rolesAbsent.length === 0) {
            topLevel = true;
        }
    }

    return topLevel;
};

export const AgencyTabs = AGENCY_LOCATIONS.map((loc) => {
    return {
        id: loc.location_id,
        type: AGENCY,
        title: loc.location_name.trim().split(' ')[0].toUpperCase(),
    };
});

export const getAgencyNamesFromLocations = (locations) => {
    if (locations && locations.length) {
        const list = locations
            .map((location) => {
                if (location.location_path) {
                    const agencies = location.location_path.filter(
                        (loc) => loc.location_type === 'Agency'
                    );

                    if (agencies.length) {
                        const agencyLocs = AGENCY_LOCATIONS.filter(
                            (al) => al.location_id === agencies[0].location_id
                        );

                        if (agencyLocs.length) {
                            return agencyLocs[0].location_name;
                        }
                    }
                }

                return null;
            })
            .filter((str) => str)
            .sort();

        return [...new Set(list)];
    }

    return [];
};
