/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';

const initialState = {
    alerts: [],
    closedSiteWideIDs: {},
};

const alertsNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ALERTS_FETCHED:
            return { ...state, alerts: action.alerts };

        case types.FETCHING_ALERTS_FAILED:
            return { ...state, alerts: [] };

        case types.CLOSE_SITE_WIDE_ALERT:
            return {
                ...state,
                closedSiteWideIDs: {
                    ...state.closedSiteWideIDs,
                    [action.alert_id]: true,
                },
            };

        default:
            return state;
    }
};

export default alertsNotificationReducer;
