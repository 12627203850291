/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Heading } from 'sarsaparilla';
import { getLocationDisplayName, REC_AREA, AGENCY } from '../utilities/locations';

const propTypes = {
    prependText: PropTypes.string,
    useNewHeader: PropTypes.bool,
    selectedRole: PropTypes.object,
    locationPath: PropTypes.array,
};

export class FacilityInfoTitle extends React.Component {
    static defaultProps = { prependText: '', useNewHeader: false };

    static propTypes = propTypes;

    isValidPath(path) {
        let valid = Array.isArray(path) && path.length;

        if (valid) {
            valid =
                valid &&
                path.slice(0, -1).filter((loc) => loc.location_type === AGENCY).length;
            valid =
                valid &&
                path.slice(0, -1).filter((loc) => loc.location_type === REC_AREA).length;
        }

        return valid;
    }

    renderTitle = (title) => {
        if (this.props.useNewHeader) {
            return (
                <Heading headingLevel={2} appearance="h4">
                    {title}
                </Heading>
            );
        }

        return <b>{title}</b>;
    };

    render() {
        const path = this.props.locationPath;
        const location = this.props.selectedRole
            ? this.props.selectedRole.location
            : null;
        const prepend = this.props.prependText.length ? `${this.props.prependText} ` : '';

        if (this.isValidPath(path)) {
            const agency = path.filter((loc) => loc.location_type === AGENCY)[0];
            const recarea = path.filter((loc) => loc.location_type === REC_AREA)[0];

            let recareaStr = recarea?.location_attributes?.description;
            if (recarea.location_state_code) {
                recareaStr = `${recareaStr}, ${recarea.location_state_code.toUpperCase()}`;
            }
            const description = agency?.location_attributes?.description;

            return (
                <div className="ia-facinfo-hierarchy-title">
                    <div>
                        {this.renderTitle(
                            `${prepend}${getLocationDisplayName(path[path.length - 1])}`
                        )}
                    </div>
                    <div id={'path-div'}>
                        {recareaStr && <span>{recareaStr}</span>}

                        {description && <span>{description}</span>}
                    </div>
                </div>
            );
        }

        if (location) {
            return (
                <div className="ia-facinfo-hierarchy-title">
                    <div>
                        {this.renderTitle(
                            `${prepend}${getLocationDisplayName(location)}`
                        )}
                    </div>
                </div>
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        selectedRole: state.selectedLocationRole.role,
        locationPath: state.facilityInfo.info
            ? state.facilityInfo.info.location_path
            : [],
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FacilityInfoTitle);
