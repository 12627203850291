/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';

import EditPermit from '../components/concessionaire/EditPermit';

import PageHeader from '../components/PageHeader';
import SiteWrapper from './SiteWrapper';
import { savePermit } from '../actions/manageConcessionaires';

export class EditPermitContainer extends React.Component {
    static propTypes = {
        history: TYPES.HISTORY,
        concessionaire: PropTypes.any,
        permit: PropTypes.any,
        savingManagedConcessionairesError: PropTypes.any,
        savingConcessionairePermitsError: PropTypes.any,
        savePermit: PropTypes.func,
    };

    savePermit = (permit, add) => {
        const perm = permit || this.props.permit;
        this.props.savePermit(perm, add, this.props.history);
    };

    render() {
        const { history, concessionaire, permit } = this.props;

        let error = '';
        let navBackUrl = null;
        const crumbs = [];
        const tabs = [
            {
                title: 'Concessionaires',
            },
        ];

        navBackUrl = '/internal/account/manage-concessionaires';
        crumbs.push({
            label: 'Concessionaires',
            onClick: () => history.push('/internal/account/manage-concessionaires'),
        });

        if (this.props.concessionaire && this.props.concessionaire.concessionaire_id) {
            navBackUrl = '/internal/account/concessionaire-details';
            crumbs.push({
                label: 'Concessionaire Details',
                onClick: () => history.push('/internal/account/concessionaire-details'),
            });
        }

        if (
            this.props.concessionaire &&
            this.props.concessionaire.concessionaire_id &&
            this.props.permit
        ) {
            crumbs.push({
                label: this.props.permit.permit_id ? 'Edit Permit' : 'Add Permit',
            });
            error = this.props.savingConcessionairePermitsError;
        } else if (this.props.concessionaire) {
            crumbs.push({
                label: this.props.concessionaire.concessionaire_id
                    ? 'Edit Concessionaire'
                    : 'Add Concessionaire',
            });
            error = this.props.savingManagedConcessionairesError;
        }

        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader
                        title="Concessionaire Management"
                        tabs={tabs}
                        breadcrumbs={crumbs}
                        navBack={navBackUrl}
                        selectedTabIndex={0}
                    />
                </div>
                <div id="ConcessionaireManagement" className="page-content wrapper">
                    <EditPermit
                        history={history}
                        concessionaire={concessionaire}
                        permit={permit}
                        error={error}
                        savePermit={this.savePermit}
                    />
                </div>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        concessionaire: state.manageConcessionairesSet.managedConcessionaire,
        permit: state.manageConcessionairesSet.concessionairePermit,
        savingManagedConcessionairesError:
            state.manageConcessionaires.savingManagedConcessionairesError,
        savingConcessionairePermitsError:
            state.manageConcessionaires.savingConcessionairePermitsError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        savePermit: (permit, add, history) => dispatch(savePermit(permit, add, history)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPermitContainer);
